import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { ClientT } from '@brainysoft/lk-components';
import { StaticRow } from '../../../utils/StaticRow';
import dayjs from 'dayjs';

interface IProps {
  client: ClientT;
}

export const ProfileFormFioStatic: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { client } = props;

  return (
    <div className={classes.static}>
      <StaticRow label={'ФИО'} value={`${client.lastName} ${client.firstName} ${client.patronymic || ''}`} />
      <StaticRow label={'Телефон'} value={client.mobilePhone} />
      <StaticRow label={'Дополнительный телефон'} value={client.additionalPhone} />
      <StaticRow label={'Email'} value={client.email} />
      <StaticRow label={'Дата рождения'} value={dayjs(client.birthDate).format('DD.MM.YYYY г.')} />
      <StaticRow label={'Место рождения'} value={client.birthPlace} />
    </div>
  );
};
