import * as React from 'react';

import { cacheService as Storage, DictionaryType, IProcessStepProps, ProcessType } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { Col, Row } from 'antd';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { DatePicker } from '../../../packages/Formik/Components/DatePicker';
import { Message } from '../../../components/Message';
import { processService } from '@brainysoft/lk-components';
import { dataSourcesService } from '@brainysoft/lk-components';
import { useTranslation } from '../../../utils/useTranslation';
import { DadataInputAntd } from '../../../packages/Formik/Components/DadataInputAntd';
import { get, has, set } from 'lodash';
import { ExternalAuthGosuslugi } from '../../../components/ExternalAuth/ExternalAuthGosuslugi';
import { useDictionary } from '../../../queries/useDictionary';
import useDebouncedCallback from '../../../utils/useDebouncedCallback';
import { passportIssueDisabledDates, passportIssueDateValidator } from '../../../utils/passportDatesVerification';

export const RegisterFormPassport: React.FC<IProcessStepProps> = (props) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    props.setForwardButtonText(t('buttons.forward'));
    props.setStepsVisible(true);
    props.setForceIsForwardVisible(true);
    props.setSubmitDisabled(false);
  }, []);

  const [managerVisible, setManagerVisible] = React.useState(!!props?.initialValues?.passport?.subdivisionCode);
  const [showDataAlert, setShowDataAlert] = React.useState(false);
  const countriesQuery = useDictionary(DictionaryType.COUNTRIES);
  const countries = countriesQuery.data ?? [];
  const russiaId = countries.reduce((acc, val) => {
    if (val.name.toLowerCase() === 'россия') acc = val.id;
    return acc;
  }, null);

  const hasExternalAuth = !!props.initialValues?.externalAuthProcessUuid;

  const searchInn = async (formik) => {
    const data = {
      objects: {
        client: {
          naturalperson: 'true',
          firstName: formik.values?.firstName,
          lastName: formik.values?.lastName,
          patronymic: formik.values?.patronymic,
          birthDate: formik.values?.birthDate,
          passport: {
            seria: formik.values?.passport?.seria,
            no: formik.values?.passport?.no,
            issueDate: formik.values?.passport?.issueDate,
          },
          rnn: null,
        },
      },
      processUuid: props.processUuid,
    };
    const result = await dataSourcesService.requestInn(data);
    if (result?.resultCode !== 'FOUND') {
      setShowDataAlert(true);
    }
    if (result?.iNN) {
      formik.setFieldValue('rnn', result.iNN);
      formik.setFieldValue('fns', { resultCode: result.resultCode, rnn: result.iNN });
    }
  };

  const searchInnDebounce = useDebouncedCallback(searchInn, 2000);

  const onUpdate = (formik, changed) => {
    //если данные с госуслуг, запрашиваем инн и заполняем композитное поле passportSeriaNo
    if (formik?.values?.passportSeriaNo === undefined) {
      if (!!formik?.values?.passport?.seria && !!formik?.values?.passport?.no) {
        formik.setFieldValue('passportSeriaNo', formik.values.passport.seria + ' ' + formik.values.passport.no);
        if (
          formik.values?.firstName &&
          formik.values?.lastName &&
          formik.values?.patronymic &&
          formik.values.passport?.issueDate &&
          formik.values.passport?.seria?.length === 4 &&
          formik.values.passport?.no?.length === 6 &&
          !formik.values.rnn
        ) {
          searchInnDebounce(formik);
        }
      } else formik.setFieldValue('passportSeriaNo', null);
    }

    if (formik?.values?.passport?.subdivisionCode) setManagerVisible(true);

    // eslint-disable-next-line no-prototype-builtins
    if (has(changed, 'passport.subdivisionCode')) {
      const subdivisionCode = get(formik, 'values.passport.subdivisionCode');
      const passportManager = get(formik, 'values.passport.manager');
      const suggestion = get(formik, 'values._suggestion_passport.subdivisionCode');

      //значение и подсказка эквивалентны => значение выбрано пользователем
      if (suggestion && suggestion?.data?.code === subdivisionCode && suggestion?.value !== passportManager) {
        formik.setFieldValue('passport.manager', suggestion?.value);
      }
    }

    // eslint-disable-next-line no-prototype-builtins
    if (has(changed, 'passport.manager')) {
      const subdivisionCode = get(formik, 'values.passport.subdivisionCode');
      const passportManager = get(formik, 'values.passport.manager');
      const suggestion = get(formik, 'values._suggestion_passport.manager');

      //значение и подсказка эквивалентны => значение выбрано пользователем
      if (suggestion && suggestion?.value === passportManager && suggestion?.data?.code !== subdivisionCode) {
        formik.setFieldValue('passport.subdivisionCode', suggestion?.data?.code);
      }
    }

    if (changed?.passportSeriaNo?.length === 10) {
      formik.setFieldValue('passport.seria', changed.passportSeriaNo.slice(0, 4));
      formik.setFieldValue('passport.no', changed.passportSeriaNo.slice(4, 10));
    }

    if (changed?.passport?.issueDate || changed?.passport?.seria || changed?.passport?.no) {
      if (
        formik.values.passport?.issueDate &&
        formik.values.passport?.seria?.length === 4 &&
        formik.values.passport?.no?.length === 6 &&
        !formik.values.rnn
      ) {
        searchInnDebounce(formik);
      } else if (searchInnDebounce.isPending()) searchInnDebounce.clear();
    }

    if (formik.values?.rnn) {
      setShowDataAlert(false);
    }
  };

  const onSubmit = async ({ values, submit }) => {
    values.birthCountryId = russiaId;
    if (values.fns?.rnn) {
      if (values.rnn != values.fns?.rnn) delete values.fns;
    }

    if (values?.passportSeriaNo?.length === 10) {
      set(values, 'changed.passport.seria', values.passportSeriaNo.slice(0, 4));
      set(values, 'changed.passport.no', values.passportSeriaNo.slice(4, 10));
    }
    await submit(values);
  };

  const returnUrl = window.location.origin + '/registration/external-auth-result';

  const preprocess = (formik) => {
    return async () => {
      const uuid = (await Storage.getItem(`process:${ProcessType.REGISTRATION}:uuid`)) as string | undefined;
      const session = (await Storage.getItem(`process:${ProcessType.REGISTRATION}:session`)) as string | undefined;

      return await processService.update(ProcessType.REGISTRATION, uuid, session, formik.values);
    };
  };

  if (!props.initialValues.birthPlace) props.initialValues.birthPlace = null;
  if (!props.initialValues.passport) props.initialValues.passport = {};
  if (!props.initialValues.passport.subdivisionCode) props.initialValues.passport.subdivisionCode = null;
  if (!props.initialValues.passport.seria) props.initialValues.passport.seria = null;
  if (!props.initialValues.passport.no) props.initialValues.passport.no = null;
  if (!props.initialValues.passport.issueDate) props.initialValues.passport.issueDate = null;
  if (!props.initialValues.rnn) props.initialValues.rnn = null;

  return (
    <ProcessStepForm
      {...props}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      render={(formik) => {
        return (
          <React.Fragment>
            {!hasExternalAuth && (
              <ExternalAuthGosuslugi returnUrl={returnUrl} preprocess={preprocess(formik)} className={'mb1'} />
            )}
            <Row gutter={16}>
              <Col xs={24} sm={24}>
                <Message type={'info'}>
                  Заполните данные точно, как указано в вашем паспорте!
                  <br />
                  При расхождении в одной цифре или букве мы не сможем одобрить ваш займ.
                </Message>
              </Col>
              <Col xs={24} sm={12}>
                <div className='fields'>
                  <MaskedInput
                    name='passportSeriaNo'
                    label='Серия и номер паспорта'
                    placeholder='Серия и номер Вашего паспорта'
                    mask='passportSeriaNo'
                    required={true}
                    disabled={hasExternalAuth}
                  />
                </div>
              </Col>

              <Col xs={24} sm={12}>
                <div className='field'>
                  <DatePicker
                    name='passport.issueDate'
                    label='Дата выдачи'
                    placeholder='ДД.ММ.ГГГГ'
                    outgoingFormat='YYYY-MM-DD'
                    required={true}
                    disabled={hasExternalAuth}
                    disabledDate={(current) => passportIssueDisabledDates(current, props.initialValues?.birthDate)}
                    validate={(value) => passportIssueDateValidator(value, props.initialValues?.birthDate)}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={8}>
                <div className='field'>
                  <DadataInputAntd
                    type='fms_unit'
                    name='passport.subdivisionCode'
                    label='Код подразделения как в паспорте'
                    placeholder='Код подразделения'
                    required={true}
                    mapDataField='code'
                    mask={'passportSubdivisionCode'}
                    disabled={hasExternalAuth}
                  />
                </div>
              </Col>
              <Col xs={24} sm={16}>
                <div className='field'>
                  <DadataInputAntd
                    type='fms_unit'
                    name='passport.manager'
                    label='Кем выдан как в паспорте'
                    placeholder='Кем выдан паспорт'
                    required={true}
                    mask='cyrillicStrictWithChars'
                    hidden={!managerVisible}
                    disabled={hasExternalAuth}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24}>
                <div className='field'>
                  <MaskedInput
                    name='birthPlace'
                    label='Место рождения как в паспорте'
                    placeholder='Место рождения как в паспорте'
                    mask='birthPlaceStrict'
                    required={true}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <div className='field'>
                  <MaskedInput name='rnn' label='Номер ИНН' placeholder='Введите Ваш ИНН' mask='inn' required={true} />
                  {showDataAlert && (
                    <Message type={'error'}>
                      Мы не нашли ваш номер ИНН. Возможно, вы допустили опечатку в ФИО, дате рождения или паспортных
                      данных. Пожалуйста, вернитесь и проверьте введенные ранее данные. Если персональные данные введены
                      корректно, но ИНН не найден автоматически, введите его самостоятельно.
                    </Message>
                  )}
                </div>
              </Col>
            </Row>
          </React.Fragment>
        );
      }}
    />
  );
};
