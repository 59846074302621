import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Divider } from 'antd';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import { StatefulProcessActions } from '../../../components/StatefulProcess/StatefulProcessActions';

export const UploadPassportIntro: React.FC<IStatefulProcessRenderer> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');

  const { state, goForward, isMutating } = props;

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <h2 className={classes.header}>Осталось совсем немного!</h2>
        <p className={classes.descriptor}>
          Чтобы мы могли убедиться, что вы - это вы, загрузите пожалуйста фото двух страниц вашего паспорта вместе с
          банковской картой. Фото должно быть цветным и четким, карта и паспорт не должны перекрывать друг друга. Все
          данные должны быть читаемы.
        </p>
      </div>
      <Divider />
      <StatefulProcessActions
        showForward={state.transitions?.forward}
        showBack={state.transitions?.back}
        onClickForward={() => goForward(state.data)}
        textForward={'Загрузить фото'}
        isMutating={isMutating}
      />
    </React.Fragment>
  );
};
