import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { processService } from '@brainysoft/lk-components';

export const usePlocessFiles: (
  codes: string[],
  processName: string,
  processUuid: string,
  processSession: string
) => UseQueryResult<Record<string, File | null>> = (
  codes: string[],
  processName: string,
  processUuid: string,
  processSession: string
) => {
  return useQuery(['processFiles'], () => {
    const files = {};
    codes.map(async (e) => {
      const meta = await processService.getProcessFileMeta(processName, processUuid, processSession, e);
      if (meta.clientOriginalName && meta.clientMimeType && meta.keepUpToDate) {
        const file = await processService.loadProcessFile(processName, processUuid, processSession, e);
        files[e] = new File([file], meta.clientOriginalName, {
          type: meta.clientMimeType,
          lastModified: meta.keepUpToDate * 1000,
        });
      }
    });
    return Promise.resolve(files) as Promise<Record<string, File | null>>;
  });
};
