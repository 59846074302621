import { RegisterFormFio } from './RegisterFormFio';
import { RegistrationFormAddress } from './RegisterFormAddress';
import { RegisterFormWorkplace } from './RegisterFormWorkplace';
import { RegisterFormIncome } from './RegisterFormIncome';
import { RegisterFormPassport } from './RegisterFormPassport';
import { RegisterFormPhotoPassport } from './RegisterFormPhotoPassport';
import { RegisterFormLicenses } from './RegisterFormLicenses';
import { RegistrationFormComplete } from './RegisterFormComplete';
import { RegisterFormSimilar } from './RegisterFormSimilar';
import { RegisterFormDataSent } from './RegisterFormDataSent';

export const RENDERER_MAP = {
  general: RegisterFormFio,
  similarFio: RegisterFormSimilar,
  similarPassportOrInn: RegisterFormSimilar,
  dataSent: RegisterFormDataSent,
  address: RegistrationFormAddress,
  workplace: RegisterFormWorkplace,
  income: RegisterFormIncome,
  passport: RegisterFormPassport,
  photoPassport: RegisterFormPhotoPassport,
  licenses: RegisterFormLicenses,
  end: RegistrationFormComplete,
};

export const MAP_PROCESS_TO_STEPS = {
  fio: ['general', 'phoneValidation'],
  additional: ['passport', 'address', 'workplace', 'income'],
  passport: ['photoSelfie', 'photoPassport', 'licenses'],
};

export default { RENDERER_MAP, MAP_PROCESS_TO_STEPS };
