import * as React from 'react';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

interface IHistory {
  history: string[];
}
const HistoryContext = React.createContext<IHistory>({ history: [] });

export const HistoryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [history, setHistory] = React.useState<string[]>([]);
  const { asPath } = useRouter();

  React.useEffect(() => {
    sessionStorage.setItem('history', '');
  }, []);

  React.useEffect(() => {
    return () => {
      setHistory((prev) => {
        const history = [...prev, asPath];
        sessionStorage.setItem('history', JSON.stringify(history));
        return history;
      });
    };
  }, [asPath]);

  return <HistoryContext.Provider value={{ history }}>{children}</HistoryContext.Provider>;
};

export function useHistory(): IHistory {
  const context = React.useContext(HistoryContext);
  return context;
}
