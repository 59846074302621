import * as React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { get } from 'lodash';
import { IProcessStepProps, SettingsType, ProcessType } from '@brainysoft/lk-components';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Slider } from '../../../packages/Formik/Components/Slider';
import { DEFAULT_AMOUNT_STEP, DEFAULT_PERIOD_STEP } from '../../../const';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { CreditProducts } from '../../../components/CreditProducts';
import { refreshCreditProducts, useCreditProducts } from '../../../queries/useCreditProduct';
import { useQueryClient } from 'react-query';
import { CreditProductT, PrintFormContextType, PrintFormType } from '@brainysoft/lk-components';
import { useSetting } from '../../../utils/useSetting';
import { LoanAppAmountThresholdWarning } from '../../../customComponents/monedo/Widgets/LoanAppAmountThresholdWarning';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { PrintFormDocument } from '../../../components/PrintFormDocument/PrintFormDocument';
import { useTranslation } from '../../../utils/useTranslation';

export const LoanAppAmount: React.FC<IProcessStepProps> = (props) => {
  const classes = useThemeStyles('Process');
  const creditProductsQuery = useCreditProducts();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    props.setSubmitDisabled(true);
    props.setIsFormLoading(true);
    refreshCreditProducts(queryClient);
  }, []);

  return (
    <React.Fragment>
      {creditProductsQuery.isFetching && (
        <Skeleton className={classes.skeleton} title={false} active={true} paragraph={{ rows: 5, width: '80%' }} />
      )}
      {!creditProductsQuery.isFetching && <LoanAppAmountForm {...props} />}
    </React.Fragment>
  );
};

const LoanAppAmountForm: React.FC<IProcessStepProps> = (props) => {
  const classes = useThemeStyles('LoanAppCreatePage');
  const classesCB = useThemeStyles('RegistrationPage');

  const { t } = useTranslation();

  const { initialValues, ...rest } = props;

  const warningThreshold = useSetting(SettingsType.SELFIE_REQUEST_AMOUNT_THRESHOLD);

  const creditProductsQuery = useCreditProducts();
  const creditProducts = creditProductsQuery.data ?? [];

  const initialCreditProductId = initialValues?.creditProductId;

  const found = creditProducts.find((item) => item.id === initialCreditProductId);
  const creditProductId = found ? initialCreditProductId : get(creditProducts, '0.id');

  const creditProduct: CreditProductT | Record<string, never> = get(
    creditProducts.filter((item) => item.id == creditProductId),
    0,
    {}
  );

  React.useEffect(() => {
    props.setIsFormLoading(false);
    props.setSubmitDisabled(true);
  }, []);

  const [selectedCreditProduct, setSelectedCreditProduct] = React.useState(creditProduct);
  const [periodLabel, setPeriodLabel] = React.useState('Срок займа');
  const [overThreshold, setOverThreshold] = React.useState(
    warningThreshold && creditProduct.defaultAmount > warningThreshold
  );

  const amountStep = selectedCreditProduct.defaultStepAmount ?? DEFAULT_AMOUNT_STEP;
  const periodStep = selectedCreditProduct.defaultStepPeriod ?? DEFAULT_PERIOD_STEP;

  const initialAmount =
    creditProduct.defaultAmount ??
    Math.floor((creditProduct.maxLoanAmount - creditProduct.minLoanAmount) / 2000) * 1000 + creditProduct.minLoanAmount;
  const initialPeriod =
    creditProduct.defaultPeriod ??
    Math.floor((creditProduct.maxPeriod - creditProduct.minPeriod) / 2) + creditProduct.minPeriod;

  initialValues['amount'] = initialValues['amount'] || initialAmount;
  initialValues['period'] = initialValues['period'] || initialPeriod;

  const onUpdate = (formik) => {
    const creditProductId = get(formik, 'values.creditProductId');
    const amount = get(formik, 'values.amount');
    const period = get(formik, 'values.period');
    const creditHistoryApproved = get(formik, 'values.creditHistoryApproved');
    if (creditHistoryApproved === null || creditHistoryApproved === undefined)
      formik.setFieldValue('creditHistoryApproved', true);
    props.setSubmitDisabled(!creditProductId || !creditHistoryApproved);
    const creditProduct = get(
      creditProducts.filter((item) => item.id == creditProductId),
      0
    );
    if (creditProduct) {
      setSelectedCreditProduct(creditProduct);
      const label = creditProduct.periodInTranches
        ? `Количество выплат (общий срок займа: ${period * creditProduct.trancheDuration} дней)`
        : 'Срок займа, дней';
      setPeriodLabel(label);
      setOverThreshold(warningThreshold && amount > warningThreshold);
    }
  };

  const onSubmit = async ({ values, submit }) => {
    if (selectedCreditProduct.periodInTranches) values.period = values.period * selectedCreditProduct.trancheDuration;
    await submit(values);
  };

  return (
    <ProcessStepForm
      {...rest}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      initialValues={{
        ...initialValues,
        period: creditProduct.periodInTranches
          ? initialValues.period / creditProduct.trancheDuration
          : initialValues.period,
      }}
      render={(formik) => {
        return (
          <React.Fragment>
            <div className={classes.formWrapper}>
              <h2 className={classes.header}>Выберите сумму и срок займа:</h2>
              <Row gutter={16} align='top'>
                <Col xs={24} sm={12}>
                  <div className='field'>
                    <Slider
                      label='Сумма займа, руб.'
                      name='amount'
                      defaultValue={initialValues['amount']}
                      min={selectedCreditProduct.minLoanAmount}
                      max={selectedCreditProduct.maxLoanAmount}
                      step={amountStep}
                      className={overThreshold ? 'orange' : ''}
                    />
                  </div>
                  {overThreshold && <LoanAppAmountThresholdWarning threshold={warningThreshold} />}
                </Col>
                <Col xs={24} sm={12}>
                  <div className='field'>
                    <Slider
                      label={periodLabel}
                      name='period'
                      defaultValue={initialValues['period']}
                      min={selectedCreditProduct.minPeriod}
                      max={selectedCreditProduct.maxPeriod}
                      step={periodStep}
                    />
                  </div>
                </Col>
              </Row>

              <CreditProducts
                active={get(formik, 'values.creditProductId')}
                creditProducts={creditProducts}
                onChange={(id) => {
                  formik.setFieldValue('creditProductId', id);
                }}
              />

              <div className={classesCB.checkboxWrapper}>
                <Checkbox name='creditHistoryApproved' />
                <div className={classesCB.checkboxLabel}>
                  <span>
                    {
                      <PrintFormDocument
                        contextType={PrintFormContextType.PROCESS}
                        printFormType={PrintFormType.CONSENT_CREDIT_HISTORY}
                        name={t(`printForms:name.${PrintFormType.CONSENT_CREDIT_HISTORY}`)}
                        type={'link'}
                        params={{
                          processType: ProcessType.LOAN_APP,
                          uuid: props.processUuid,
                          session: props.processSession,
                        }}
                      />
                    }
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};
