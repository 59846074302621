import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { ClientT } from '@brainysoft/lk-components';
import { DictionaryType } from '@brainysoft/lk-components';
import { MatrialStatusTypeToOptions } from '@brainysoft/lk-components';
import { StaticRow } from '../../../utils/StaticRow';
import { get } from 'lodash';
import { Divider } from 'antd';
import { useDictionary } from '../../../queries/useDictionary';
import { getStaticDictValue } from '../../../utils/getStaticDictValue';

interface IProps {
  client: ClientT;
}

export const ProfileFormAdditionalStatic: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { client } = props;

  const educationQuery = useDictionary(DictionaryType.EDUCATION);
  const employmentTypeQuery = useDictionary(DictionaryType.EMPLOYMENT_TYPE);
  const activityTypeQuery = useDictionary(DictionaryType.ACTIVITY_TYPE);

  const education = educationQuery.data ?? [];
  const employmentType = employmentTypeQuery.data ?? [];
  const activityType = activityTypeQuery.data ?? [];

  const maritalStatus = MatrialStatusTypeToOptions();

  return (
    <div className={classes.static}>
      <StaticRow label={'Образование'} value={getStaticDictValue(client.educationId, education)} />
      <StaticRow
        label={'Тип занятости'}
        value={getStaticDictValue(get(client, 'workPlaceData.employmentTypeId'), employmentType)}
      />
      <StaticRow
        label={'Сфера занятости'}
        value={getStaticDictValue(get(client, 'workPlaceData.activityTypeId'), activityType)}
      />
      <StaticRow label={'Название места работы'} value={get(client, 'workPlaceData.workPlace')} />
      <StaticRow label={'Семейное положение'} value={getStaticDictValue(client.maritalStatusId, maritalStatus)} />
      <StaticRow label={'Количество детей на содержании'} value={client.dependents ? String(client.dependents) : 0} />
      <Divider />
      <StaticRow label={'Расходы на жилье в месяц'} value={client.averageMonthlyCost} />
      <StaticRow
        label={'Ежемесячные оплаты по кредитам и займам'}
        value={client.loanPaymentExpense ? String(client.loanPaymentExpense) : 0}
      />
    </div>
  );
};
