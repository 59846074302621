import * as React from 'react';
import { useInterval } from './useInterval';
import { cacheService as Storage, messagesService, VerificationEntityType } from '@brainysoft/lk-components';
import { SMS_CHECK_INTERVAL } from '../const';
import { throttle } from 'lodash';
import * as config from '@brainysoft/lk-custom/config';
import { useVerificationResultUrl } from './useVerificationResultUrl';
import dayjs from 'dayjs';

type UseEmailVerificationChallengeType = {
  getChallenge: (email: string) => void;
  isDisabled: boolean;
  isSending: boolean;
  isSent: boolean;
  timeout?: number;
  uuid?: string;
};

export const useEmailVerificationChallenge = (
  entity: VerificationEntityType,
  entityId: string | number
): UseEmailVerificationChallengeType => {
  const [isSending, setIsSending] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);
  const [uuid, setUuid] = React.useState<string | undefined>();

  const [emailInitInterval, setEmailInitInterval] = React.useState<number | null>();
  const [timeout, setTimeout] = React.useState<number | undefined>();

  useInterval(() => {
    let cancel = false;
    const checkCooldown = async () => {
      const cooldown = (await Storage.getItem(`challenge:email:cooldown`)) as string;
      if (dayjs().isSameOrBefore(dayjs(Number(cooldown)))) {
        if (!cancel) {
          setTimeout(dayjs().diff(dayjs(Number(cooldown))));
        }
      } else {
        if (!cancel) {
          // закончился таймаут
          setEmailInitInterval(null);
          setTimeout(undefined);
        }
      }
    };
    checkCooldown();
    return () => {
      setEmailInitInterval(null);
      cancel = true;
    };
  }, emailInitInterval);

  const getChallenge = React.useCallback(
    throttle((email) => _getChallenge(email), 2000, { trailing: false }),
    []
  );

  const verificationUrl = useVerificationResultUrl();

  const _getChallenge: (email: string) => void = async (email) => {
    setIsSending(true);
    let smsDelay = dayjs().add(config['EMAIL_SEND_DELAY'], 'ms');

    try {
      const res = await messagesService.emailVerificationInit(email, {
        entities: [{ type: entity, id: entityId }],
        verificationUrl,
      });

      if (res?.uuid) {
        setUuid(res?.uuid);
        setIsSent(true);
        setIsSending(false);
        smsDelay = dayjs().add(config['EMAIL_SEND_DELAY'], 'ms');
      } else {
        console.log('error no challengeId', res);
      }
    } catch (error) {
      console.log('error', error);
    }

    await Storage.setItem(`challenge:email:cooldown`, smsDelay.valueOf());
    setEmailInitInterval(SMS_CHECK_INTERVAL);
    setIsSending(false);
  };

  return {
    getChallenge: getChallenge,
    isDisabled: emailInitInterval !== null || isSending,
    isSending,
    isSent,
    timeout,
    uuid,
  };
};

export const parseTimeout = (timeout) => {
  let minutes, seconds;
  if (!isNaN(Number(timeout))) {
    minutes = !isNaN(Number(timeout)) ? Math.floor(Math.abs(Number(timeout)) / 60000) : undefined;
    seconds = !isNaN(Number(timeout)) ? Math.floor(Math.abs(Number(timeout)) / 1000) - minutes * 60 : undefined;
  }

  return {
    minutes: minutes,
    seconds: seconds,
  };
};
