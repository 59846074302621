import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Divider } from 'antd';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import { useTranslation } from '../../../utils/useTranslation';
import { StatefulProcessActions } from '../../../components/StatefulProcess/StatefulProcessActions';
import { useIndividualOffers } from '../../../queries/useLead';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { IndividualOffer } from '../../../components/IndividualOffer';
import { Message } from '../../../components/Message';
import { SettingsType, PrintFormType, PrintFormContextType } from '@brainysoft/lk-components';
import { useSettings } from '../../../queries/useSettings';
import { PrintFormDocument } from '../../../components/PrintFormDocument/PrintFormDocument';

const MotivationLabel: React.FC<{ tag: string }> = React.memo(({ tag }) => {
  const { tAsArray } = useTranslation();
  return (
    <>
      {tAsArray(`motivationLabel:${tag}`).map((text, index) => (
        <p key={index}>{text}</p>
      ))}
    </>
  );
});

const Disclaimer: React.FC = React.memo(() => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();
  return <p className={classes.descriptor}>{t('signOffer:disclaimer')}</p>;
});

const IS_OFFER_REQUIRED = true;

export const SignOfferIntro: React.FC<IStatefulProcessRenderer> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');
  const pfClasses = useThemeStyles('PrintForm');
  const { t } = useTranslation();

  const { credit, state, goForward, isMutating } = props;

  const [indexOffer, setIndexOffer] = React.useState<number | null>(null);

  const { isLoading, data } = useIndividualOffers(
    credit.entities.leadId,
    credit.entities.loanApplicationId,
    IS_OFFER_REQUIRED
  );

  const { data: settings } = useSettings();

  const threshold = settings ? settings[SettingsType.SELFIE_REQUEST_AMOUNT_THRESHOLD] : null;
  const isRiskPdnPreset =
    settings && settings[SettingsType.RISK_PDN_NOTIFICATION_PRESET]
      ? settings[SettingsType.RISK_PDN_NOTIFICATION_PRESET]
      : true;

  const needRiskPdnNotification = () => {
    if (!data?.offers?.length) return false;
    if (indexOffer === null && data?.offers?.length > 0) return data.offers.some((e) => e.high_pdn === true);
    const offer = data.offers.find((e) => e.position == indexOffer);
    return offer?.high_pdn;
  };

  React.useEffect(() => {
    let indexOffer = state.data?.newOffer?.index !== undefined ? state.data.newOffer.index : null;
    if (indexOffer === null && data?.offers?.length > 0) {
      indexOffer = data.offers.reduce((result, el) => {
        if (result == null || el.position < result) return el.position;
        return result;
      }, null);
    }
    setIndexOffer(indexOffer);
  }, [data]);

  const onClickForward = async () => {
    const offer = data.offers.find((e) => e.position == indexOffer);
    const newData = { ...state.data };
    if (offer && offer.amount && offer.term) {
      newData.newOffer = {
        amount: offer.amount,
        term: offer.term,
        amount_of_insurance: offer.amount_of_insurance,
        coverage_amount: offer.coverage_amount,
        index: indexOffer,
      };
    }

    newData.printForms = [];
    newData.lead_extraFields = [{ risk_pdn_notification: offer.high_pdn }];
    if (offer.high_pdn) {
      newData.printForms = [PrintFormType.RISK_PDN_NOTIFICATION];
    }

    await goForward(newData);
  };

  if (isLoading) return <LoadingSpinner />;

  const isIndividualOffers = !!data?.offers?.length;

  if (isIndividualOffers)
    data.offers.forEach((offer) => {
      offer.additionsInfo = offer.amount > threshold ? 'Понадобится селфи с паспортом' : '';
    });

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <h2
          className={classes.header}
          dangerouslySetInnerHTML={{
            __html: t(isIndividualOffers ? 'signOffer:individualOffersLabel' : 'signOffer:congrats'),
          }}
        />
        {!isIndividualOffers &&
          (data?.motivation_label ? <MotivationLabel tag={data?.motivation_label} /> : <Disclaimer />)}
        {!isIndividualOffers && !isLoading && (
          <div className={'mt2'}>
            <Message type={'info'}>Подождите, загружаются данные</Message>
            <LoadingSpinner size={8} margin={4} />
          </div>
        )}

        {isIndividualOffers && (
          <IndividualOffer offers={data.offers} credit={credit} active={indexOffer} onChange={setIndexOffer} />
        )}

        {['am_down-dur_init-pdl', 'am_up-dur_init-pdl', 'am_down-dur_up-pdl', 'am_up-dur_down-pdl'].some(
          (el) => el === data?.motivation_label
        ) && (
          <div>
            <h3>Что такое ПДН?</h3>
            <p>
              ПДН рассчитывается как отношение среднемесячных платежей заемщика по всем кредитам и займам,
              <br />
              в том числе по вновь выдаваемому кредиту (займу), к его среднемесячному доходу по формуле:
              <br />
              ПДН=среднемесячные платежи по всем кредитам (займам)/среднемесячный доход.
            </p>
          </div>
        )}
      </div>
      <Divider />

      <StatefulProcessActions
        isForwardDisabled={data?.offers?.length && indexOffer == null}
        showForward={true}
        showBack={state.transitions?.back}
        onClickForward={onClickForward}
        textForward={t('signOffer:forward')}
        isMutating={isMutating}
      />
      <div style={{ height: isRiskPdnPreset ? window.innerHeight - 300 - 140 * data.offers.length + 'px' : '0' }}></div>
      {needRiskPdnNotification() && (
        <div className='risk-consent-container' style={{ paddingTop: '32px' }}>
          <div className={pfClasses.checkboxWrapper} style={{ justifyContent: 'center' }}>
            <div className={pfClasses.checkboxLabel} style={{ paddingLeft: '1rem' }}>
              <span>
                <PrintFormDocument
                  name={'Уведомление о риске неисполнения обязательств по договору и возможных штрафах'}
                  type={'link'}
                  params={{
                    uuid: credit.uuid,
                    variables: {},
                  }}
                  hideLoading={true}
                  contextType={PrintFormContextType.CREDIT}
                  printFormType={PrintFormType.RISK_PDN_NOTIFICATION}
                  className={'mb0'}
                />
              </span>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
