import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { IProcessStepProps, addonsService } from '@brainysoft/lk-components';
import { Button, Col, Row } from 'antd';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
// import { DadataInputAntd } from '../../packages/Formik/Components/DadataInputAntd';
import { Icon } from '../../../components/Icon';
// eslint-disable-next-line no-unused-vars
import { FormikProps } from 'formik';
import { Input } from '../../../packages/Formik/Components/Input';
import { DadataInputAntd } from '../../../packages/Formik/Components/DadataInputAntd';
import { get } from 'lodash';
import { ProfileFormAddressStatic } from '../../default/profile/ProfileFormAddressStatic';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { Collapse, Panel } from '../../../components/Collapse';
import { Divider } from 'antd';
import { setParsedValues } from '../../../utils/dadataAddressParser';

interface IProps extends IProcessStepProps {
  formik: FormikProps<any>;
  onUpdateRef: any;
  onSubmitRef: any;
}

export const ProfileFormAddress: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { initialValues, onUpdateRef } = props;

  const [isEditable, setIsEditable] = React.useState(true);
  const [realAddressVisible, setRealAddressVisible] = React.useState(true);

  React.useEffect(() => {
    setRealAddressVisible(initialValues?.registrationAddress?.fiasId !== initialValues?.address?.fiasId);
  }, []);

  onUpdateRef.current = async (formik, changed) => {
    const { values } = formik;
    const sameRealAddress = values?.sameRealAddress;
    const changedNames = Object.keys(changed ?? {});

    if (sameRealAddress == null) {
      setRealAddressVisible(true);
      formik.setFieldValue('sameRealAddress', false);
    } else {
      setRealAddressVisible(!sameRealAddress);
    }

    if (changedNames.includes('_suggestion_registrationAddress') || changedNames.includes('_suggestion_address')) {
      const registrationAddressSuggestion = values['_suggestion_registrationAddress']?.fullAddressText?.data;
      const addressSuggestion = values['_suggestion_address']?.fullAddressText?.data;

      if (registrationAddressSuggestion) {
        setParsedValues(formik, 'registrationAddress', registrationAddressSuggestion);
        if (sameRealAddress) {
          formik.setFieldValue('address.fullAddressText', values.registrationAddress.fullAddressText);
          setParsedValues(formik, 'address', registrationAddressSuggestion);
          if (!formik.values?.meanIncome && registrationAddressSuggestion.kladr_id) {
            const regionId = registrationAddressSuggestion.kladr_id.slice(0, 2);
            const meanIncomeResp = await addonsService.getMeanIncome(regionId);
            if (meanIncomeResp?.meanIncome > 0) formik.setFieldValue('meanIncome', meanIncomeResp.meanIncome);
          }
        }
      }
      if (sameRealAddress === false && addressSuggestion) {
        setParsedValues(formik, 'address', addressSuggestion);
        if (!formik.values?.meanIncome && addressSuggestion.kladr_id) {
          const regionId = addressSuggestion.kladr_id.slice(0, 2);
          const meanIncomeResp = await addonsService.getMeanIncome(regionId);
          if (meanIncomeResp?.meanIncome > 0) formik.setFieldValue('meanIncome', meanIncomeResp.meanIncome);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {!isEditable && <ProfileFormAddressStatic client={initialValues} />}

      <div style={{ display: isEditable ? 'block' : 'none' }}>
        <React.Fragment>
          <Input name='registrationAddress.fiasId' hidden={true} />
          <Input name='registrationAddressFiasLvl' hidden={true} />
          <Input name='address.fiasId' hidden={true} />
          <Input name='addressFiasLvl' hidden={true} />

          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <div className='field'>
                <DadataInputAntd
                  type='address'
                  name='registrationAddress.fullAddressText'
                  label='Адрес регистрации, с указанием дома и квартиры, как в паспорте'
                  placeholder='Введите город, улицу, дом, корпус, квартиру'
                  required={true}
                  initialValue={get(props, 'initialValues.registrationAddress.fullAddressText')}
                  forceSuggestion={true}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <div className='field'>
                <MaskedInput
                  name='registrationAddress.postalCode'
                  label='Почтовый индекс'
                  // placeholder="Введите почтовый индекс"
                  pattern='000000'
                  mask='pattern'
                  required={true}
                  disabled={true}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <div className='field'>
                <MaskedInput
                  name='registrationAddress.regionName'
                  label='Регион'
                  // placeholder="Введите регион"
                  mask='cyrillic'
                  disabled={true}
                />
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className='field'>
                <MaskedInput
                  name='registrationAddress.localityName'
                  label='Населенный пункт'
                  // placeholder="Введите населенный пункт"
                  mask='cyrillic'
                  disabled={true}
                />
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className='field'>
                <MaskedInput
                  name='registrationAddress.streetName'
                  label='Улица'
                  // placeholder="Введите улицу"
                  mask='cyrillic'
                  disabled={true}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={6}>
              <div className='field'>
                <Input name='registrationAddress.houseNo' label='Дом' disabled={true} />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className='field'>
                <Input name='registrationAddress.blockNo' label='Корпус' disabled={true} />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className='field'>
                <Input name='registrationAddress.buildingNo' label='Строение' disabled={true} />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className='field'>
                <Input name='registrationAddress.apartmentNo' label='Квартира' disabled={true} />
              </div>
            </Col>
          </Row>

          <div className='fields'>
            <Checkbox
              className='simple headerless paddingless mt2'
              name='sameRealAddress'
              label='Адрес проживания совпадает с адресом регистрации'
            />
          </div>
          {realAddressVisible && (
            <Collapse
              className={`simple headerless paddingless ${classes.collapse}`}
              bordered={false}
              activeKey={realAddressVisible ? 1 : undefined}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Panel showArrow={false} header='' forceRender={true} key='1'>
                <Divider />
                <Row gutter={16}>
                  <Col xs={24} sm={24}>
                    <div className='field'>
                      <DadataInputAntd
                        type='address'
                        name='address.fullAddressText'
                        label='Фактический адрес проживания, с указанием дома и квартиры'
                        placeholder='Введите адрес проживания'
                        initialValue={get(props, 'initialValues.address.fullAddressText')}
                        required={!!realAddressVisible}
                        forceSuggestion={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <div className='field'>
                      <MaskedInput
                        name='address.postalCode'
                        label='Почтовый индекс'
                        pattern='000000'
                        mask='pattern'
                        required={true}
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <div className='field'>
                      <MaskedInput name='address.regionName' label='Регион' mask='cyrillic' disabled={true} />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className='field'>
                      <MaskedInput
                        name='address.localityName'
                        label='Населенный пункт'
                        mask='cyrillic'
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className='field'>
                      <MaskedInput name='address.streetName' label='Улица' mask='cyrillic' disabled={true} />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12} sm={6}>
                    <div className='field'>
                      <Input name='address.houseNo' label='Дом' disabled={true} />
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className='field'>
                      <Input name='address.blockNo' label='Корпус' disabled={true} />
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className='field'>
                      <Input name='address.buildingNo' label='Строение' disabled={true} />
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className='field'>
                      <Input name='address.apartmentNo' label='Квартира' disabled={true} />
                    </div>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          )}
        </React.Fragment>
      </div>

      {!isEditable && (
        <Button
          type={'link'}
          onClick={() => {
            setIsEditable(true);
          }}
          className={classes.changeButton}
        >
          <Icon type={'edit'} /> Изменить
        </Button>
      )}
    </React.Fragment>
  );
};
