import * as React from 'react';
import { IProcessStepProps, ProcessFormsType } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { Divider } from 'antd';
import { processService } from '@brainysoft/lk-components';
import { notify } from '../../../utils/notify';
import { useTranslation } from '../../../utils/useTranslation';
import { MIN_FIAS_LVL } from '../../../config';
import { has } from 'lodash';
import { useClient } from '../../../queries/useClient';
import { useProcessForms } from '../../../utils/useProcessForms';

export const ProfileForm: React.FC<IProcessStepProps> = (props) => {
  const { t } = useTranslation();
  const clientQuery = useClient();
  const client = clientQuery.data ?? { id: undefined };
  const onUpdateRefFio = React.useRef<any>();
  const onUpdateRefPassport = React.useRef<any>();
  const onUpdateRefAddress = React.useRef<any>();
  const onUpdateRefAdditional = React.useRef<any>();
  const onSubmitRefAddress = React.useRef<any>();

  const { initialValues, ...stepProps } = props;

  const [emailWarning, setEmailWarning] = React.useState<boolean | undefined>();
  const [email, setEmail] = React.useState<string | undefined>();

  const components = useProcessForms(ProcessFormsType.PROFILE);

  const ProfileFormFio = components.renderersMap['ProfileFormFio'] ?? (() => <></>);
  const ProfileFormPassport = components.renderersMap['ProfileFormPassport'] ?? (() => <></>);
  const ProfileFormAddress = components.renderersMap['ProfileFormAddress'] ?? (() => <></>);
  const ProfileFormAdditional = components.renderersMap['ProfileFormAdditional'] ?? (() => <></>);

  // в системе не хранится поле fiasLvl. считаем что если есть fiasId, то он валидированный и устанавливаем fiasLvl = 8
  const registrationAddressFiasLvl = initialValues.registrationAddress?.fiasId ? MIN_FIAS_LVL : undefined;
  const addressFiasLvl = initialValues.address?.fiasId ? MIN_FIAS_LVL : undefined;

  React.useEffect(() => {
    props.setSubmitDisabled(false);
  }, []);

  const fioFields = ['lastName', 'firstName', 'patronymic', 'sexId'];
  const passportFields = ['passportSeriaNo', '_suggestion_passport', 'rnn'];
  const addressFields = [
    'registrationAddress.fullAddressText',
    'address.fullAddressText',
    'sameRealAddress',
    '_suggestion_registrationAddress',
    '_suggestion_address',
  ];
  const additionalFields = ['workPlaceData.employmentTypeId', 'loansNumber'];

  const onUpdate = (formik, changed) => {
    if (!formik?.values?.passportSeriaNo)
      formik.setFieldValue('passportSeriaNo', formik?.values?.passport?.seria + formik?.values?.passport?.no);
    if (formik.values.rnn && !formik.touched.rnn) formik.setFieldTouched('rnn', true);
    const fioChanged = fioFields.filter((field) => has(changed, field)).length;
    const passportChanged = passportFields.filter((field) => has(changed, field)).length;
    const addressChanged = addressFields.filter((field) => has(changed, field)).length;
    const additionalChanged = additionalFields.filter((field) => has(changed, field)).length;

    if (typeof onUpdateRefFio.current === 'function' && fioChanged) onUpdateRefFio.current(formik, changed);
    if (typeof onUpdateRefPassport.current === 'function' && passportChanged)
      onUpdateRefPassport.current(formik, changed);
    if (typeof onUpdateRefAddress.current === 'function' && addressChanged) onUpdateRefAddress.current(formik, changed);
    if (typeof onUpdateRefAdditional.current === 'function' && additionalChanged)
      onUpdateRefAdditional.current(formik, changed);
  };

  const onSubmit = async ({ values, actions, submit }) => {
    if (values?.email !== email) {
      setEmail(values?.email);
      setEmailWarning(false);
    }

    if (!values?.emailApprovalUuid) {
      if (!emailWarning) {
        setEmail(values?.email);
        setEmailWarning(true);
        const element = document.querySelector(`input[name='email']`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        actions.setFieldError('email', 'error_email_verification_request_not_sent');
        return;
      }
    }

    if (!values?.emailApproval) {
      if (!emailWarning) {
        setEmail(values?.email);
        setEmailWarning(true);
        const element = document.querySelector(`input[name='email']`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        actions.setFieldError('email', 'error_email_not_confirmed');
        return;
      }
    }

    if (values?.sameRealAddress) {
      values.address = values.registrationAddress;
      values.addressFiasLvl = values.registrationAddressFiasLvl;
    }
    const registrationAddressFiasLvl = values?.registrationAddressFiasLvl;
    const addressFiasLvl = values?.addressFiasLvl;

    if (!registrationAddressFiasLvl || registrationAddressFiasLvl < MIN_FIAS_LVL) {
      actions.setFieldError('registrationAddress.fullAddressText', 'address_is_too_short');
      return;
    }

    if (!addressFiasLvl || addressFiasLvl < MIN_FIAS_LVL) {
      actions.setFieldError('address.fullAddressText', 'address_is_too_short');
      return;
    }

    if (values.additionalPhone) {
      if (values.additionalPhone === values.mobilePhone || values.additionalPhone.length !== 11)
        values.additionalPhone = undefined;
    }

    try {
      const passportOk = await processService.checkData(
        {
          clientId: client.id,
          'passport.seria': values.passport?.seria,
          'passport.no': values.passport?.no,
          snils: values.snils,
          rnn: values.rnn,
        },
        props.processUuid
      );

      if (passportOk.result) {
        await submit(values);
      } else {
        notify.error(t('errors:ERROR__CHECK_DATA'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ProcessStepForm
      {...stepProps}
      initialValues={{
        ...initialValues,
        registrationAddressFiasLvl,
        addressFiasLvl,
      }}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      render={(formik) => {
        return (
          <React.Fragment>
            <h4>ФИО и контактные данные</h4>
            <ProfileFormFio {...props} formik={formik} onUpdateRef={onUpdateRefFio} />
            <Divider />
            <h4>Паспортные данные и ИНН</h4>
            <ProfileFormPassport {...props} formik={formik} onUpdateRef={onUpdateRefPassport} />
            <Divider />
            <h4>Адрес</h4>
            <ProfileFormAddress
              {...props}
              formik={formik}
              onUpdateRef={onUpdateRefAddress}
              onSubmitRef={onSubmitRefAddress}
            />
            <Divider />
            <h4>Дополнительная информация</h4>
            <ProfileFormAdditional {...props} formik={formik} onUpdateRef={onUpdateRefAdditional} />
            <Divider />
          </React.Fragment>
        );
      }}
    />
  );
};
