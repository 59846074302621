import * as React from 'react';
import { get } from 'lodash';
import { IProcessStepProps } from '@brainysoft/lk-components';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { SettingsType } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { MoneyMethod } from '../../../components/MoneyMethod';
import { useTranslation } from '../../../utils/useTranslation';
import { useSetting } from '../../../utils/useSetting';

export const LoanAppMoneyMethod: React.FC<IProcessStepProps> = (props) => {
  const { t } = useTranslation();
  const classes = useThemeStyles('LoanAppCreatePage');

  const [selectedMoneyMethod, setSelectedMoneyMethod] = React.useState(undefined);

  const moneyMethods = useSetting(SettingsType.GETTING_MONEY_METHOD) ?? [];

  React.useEffect(() => {
    props.setSubmitDisabled(true);
    setSelectedMoneyMethod(get(props, 'initialValues.gettingMoneyMethod'));
  }, []);

  const moneyMethodTypes = moneyMethods.reduce((prev, next) => {
    if (prev.indexOf(next['type']) == -1) prev.push(next['type']);
    return prev;
  }, []);

  const selectMethod = (method, formik) => {
    setSelectedMoneyMethod(method);
    formik.setFieldValue('moneyMethod', method);
    formik.setFieldTouched('moneyMethod', true);
    if (moneyMethods.filter((item) => item.type == method).length == 1) {
      formik.setFieldValue(
        'gettingMoneyMethodId',
        get(
          moneyMethods.filter((item) => item.type == method),
          0,
          {}
        ).id
      );
      formik.setFieldTouched('gettingMoneyMethodId', true);
    }
    props.setSubmitDisabled(false);
  };

  return (
    <ProcessStepForm
      {...props}
      render={(formik) => {
        return (
          <React.Fragment>
            <div className={classes.formWrapper}>
              <h2 className={classes.header}>Выберите способ получения денег</h2>
              <div className={classes.moneyMethodWrapper}>
                {moneyMethodTypes.map((method, index) => {
                  return (
                    <MoneyMethod
                      key={index}
                      name={t(`moneyMethods:${method}`)}
                      className={selectedMoneyMethod == method ? 'selected' : ''}
                      onClick={() => {
                        selectMethod(method, formik);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};
