import * as React from 'react';
import { Col, Row } from 'antd';
import { IProcessStepProps } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Dragger } from '../../../packages/Formik/Components/Dragger';
import { get } from 'lodash';
import { processService, cacheService as Storage, ProcessType } from '@brainysoft/lk-components';
import { notify } from '../../../utils/notify';
import { PhotoType } from '@brainysoft/lk-components';
import { ReactNode } from 'react';
import { merge } from 'lodash';
import { useGoogleAnalytics } from 'apps/lk-next/src/integrations/useGoogleAnalytics';
import { useYandexMetrika } from 'apps/lk-next/src/integrations/useYandexMetrika';

export type FileSettingsT = {
  name: string;
  label?: string;
  placeholder?: string;
  placeholderImgUrl?: string;
  code: PhotoType;
  required?: boolean;
};

interface IRegisterFormPhoto extends IProcessStepProps {
  filesSettings: FileSettingsT[];
  title: string;
  descriptor?: string | ReactNode;
  layout?: 'row' | 'column';
  files?: Record<string, File | null>;
  setFormik?: (any) => void;
}

export const RegisterFormPhotoUploader: React.FC<IRegisterFormPhoto> = (props) => {
  const classes = useThemeStyles('RegistrationPage');
  const { files = {}, filesSettings, title, descriptor, layout, ...formProps } = props;

  React.useEffect(() => {
    props.setSubmitDisabled(file1Settings.required || file2Settings.required);
  }, []);

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const settings: any[] = [];

  const file1Settings: any = filesSettings[0] ?? {};
  const file2Settings: any = filesSettings[1] ?? {};

  let initialValues = {};

  for (let i = 0; i < filesSettings.length; i++) {
    settings.push(filesSettings[i] ?? {});

    const iv = filesSettings[i]?.name ? { [filesSettings[i]?.name]: undefined } : {};
    initialValues = merge(initialValues, iv);
  }

  const onUpdate = (formik) => {
    if (typeof props.setFormik === 'function') {
      props.setFormik(formik);
    }
    const requiredFiles = settings.filter((item) => {
      return item?.required;
    });
    if (requiredFiles.length) {
      const allRequiredFilesLoaded = requiredFiles.reduce((sum, item) => {
        const file = get(formik.values, item.name);
        return sum && !!file;
      }, true);

      props.setSubmitDisabled(!allRequiredFilesLoaded);
    }
  };

  const onSubmit = async ({ values, submit }) => {
    const _values = { ...values };
    const files: any[] = [];
    let allRequiredFilesAttached = true;

    for (const item of settings) {
      const file = new FormData();
      const fileValue = get(values, `__file_${item.name}`);
      file.append('file', fileValue?.originFileObj);
      file.append('code', item.code);
      files.push(file);
      if (item?.required) {
        allRequiredFilesAttached = allRequiredFilesAttached && !!fileValue;
      }
    }

    if (!allRequiredFilesAttached) {
      console.error('No valid file for required field');
      return;
    }

    const filesDownloaded: boolean[] = [];

    for (const file of files) {
      const res = await processService.saveProcessFile(
        formProps.processName,
        formProps.processUuid,
        formProps.processSession,
        file
      );
      _values['files'] = {
        ..._values['files'],
        [file.get('code')]: res && res[0] ? res[0] : null,
      };
      filesDownloaded.push(res && res[0] === 'File stored to repo');
    }

    if (!filesDownloaded.reduce((sum, res) => sum && res, true)) {
      console.error('No valid file for required field');
      notify.error('Не удалось загрузить файлы');
      return;
    }

    for (const item of settings) {
      delete _values[`__file_${item.name}`];
      delete _values[item.name];
    }

    ga.send('photo_upload');
    ym.send('photo_upload');

    await submit(_values);
    return;
  };

  const descriptorRender = React.isValidElement(descriptor) ? descriptor : <p>{descriptor}</p>;

  let span = settings.length ? (settings.length !== 1 ? 24 / settings.length : 12) : 12;

  span = layout === 'row' ? 24 : span;

  const sectionSpan = layout === 'row' ? 12 : 24;
  const rowGutter = layout === 'row' ? 48 : 24;

  return (
    <ProcessStepForm
      {...formProps}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formik) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          //сбрасываем статусы что фото загруженны
          settings.forEach((item) => {
            formik.setFieldValue(item.name, undefined);
            formik.setFieldValue(`files.${item.code}__fileId`, undefined);
          });
        }, []);
        return (
          <Row gutter={rowGutter} className={classes.registrationFormPassport}>
            <Col xs={{ span: 24 }} lg={{ span: sectionSpan }} className={classes.photoInfo}>
              <h3>{title}</h3>
              {descriptorRender}
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: sectionSpan }}>
              <Row gutter={24}>
                {settings.map((item, index) => {
                  return (
                    <Col xs={{ span: sectionSpan }} lg={{ span: span }} key={index} className={classes.uploaderColumn}>
                      <Dragger
                        name={item?.name}
                        file={files[item.code]}
                        label={item?.label}
                        placeholder={item?.placeholder}
                        placeholderImgUrl={item?.placeholderImgUrl}
                        required={item?.required}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>

            {/*<Col xs={{ span: 24 }}>*/}
            {/*    <Message type={'info'}>*/}
            {/*        Если фото не загружается, проверьте, что оно размером{' '}*/}
            {/*        <span style={{ whiteSpace: 'nowrap' }}>менее {MAX_DOWNLOAD_FILE_SIZE} МВ</span> и в формате .jpg или .png*/}
            {/*    </Message>*/}
            {/*</Col>*/}
          </Row>
        );
      }}
    />
  );
};
