import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { IProcessStepProps, ResultType } from '@brainysoft/lk-components';
import { ResultIcon } from '../../../components/ResultIcon';
import { Button } from '../../../components/Button';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

export const RegisterFormDataSent: FC<IProcessStepProps> = (props) => {
  const classes = useThemeStyles('RegistrationPage');
  const router = useRouter();

  const { setStepsVisible } = props;

  const onClick = () => {
    void router.push('/auth');
  };

  useEffect(() => {
    setStepsVisible(false);
  }, []);

  return (
    <div className={classes.success}>
      <h2 className='mb2'>Ваши данные отправлены в МФО.</h2>
      <ResultIcon result={ResultType.SUCCESS} size={80} />
      <p>
        Наши специалисты проверят их и, если все в порядке, через какое-то время Вы сможете войти в личный кабинет по
        номеру телефона, который вы указали в форме регистрации.
      </p>
      <div className={'flex-center'}>
        <Button className='green mt2' size={'large'} shape={'round'} type={'primary'} onClick={onClick}>
          Вход в личный кабинет
        </Button>
      </div>
    </div>
  );
};
