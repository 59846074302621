import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import { PhotoType } from '@brainysoft/lk-components';
import { InteractionFilesUploader } from '../../default/uploaders/InteractionFilesUploader';

export const SignOfferSelfie: React.FC<IStatefulProcessRenderer> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');

  const fileSettings = [
    {
      name: 'selfie',
      label: 'Фото селфи с паспортом',
      placeholder: 'Загрузите фото селфи с паспортом',
      code: PhotoType.SELFIE,
      placeholderImgUrl: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/selfie_square.jpg',
      required: true,
    },
  ];

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <InteractionFilesUploader
          filesSettings={fileSettings}
          title={'Загрузите фото вашего паспорта'}
          descriptor={
            <React.Fragment>
              <p>Чтобы убедиться, что вы - это вы, нам нужно ваше фото с раскрытым паспортом, как на примерах ниже.</p>

              <p>ОБЯЗАТЕЛЬНЫЕ ТРЕБОВАНИЯ К ФОТО ПАСПОРТА:</p>
              <ul className={'mb1'}>
                <li>страницы должны помещаться на фото целиком, без обрезов</li>
                <li>фото должны быть цветными</li>
                <li>вся информация должна читаться, на тексте не должно быть бликов</li>
                <li>не закрывайте текст пальцами или иными предметами</li>
                <li>
                  на селфи с паспортом должно быть видно ваше лицо и паспорт в открытом виде. Текст паспорта должен
                  читаться.
                </li>
                <li>фото должны быть размером менее 5 мб в формате .jpg или .png.</li>
              </ul>
            </React.Fragment>
          }
          {...props}
        />
      </div>
    </React.Fragment>
  );
};
