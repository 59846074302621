import { ProfileForm } from '../profile/ProfileForm';
import { RegisterFormPhotoPassport } from '../registration/RegisterFormPhotoPassport';
import { ProfileFormLicenses } from '../profile/ProfileFormLicenses';
import { LoanAppPurpose } from '../../default/loanApp/LoanAppPurpose';
import { LoanAppAmount } from './LoanAppAmount';
import { LoanAppMoneyMethod } from '../../default/loanApp/LoanAppMoneyMethod';
import { SmevIdentificationResult } from '../../default/loanApp/SmevIdentificationResult';
import { LoanAppCard } from './LoanAppCard';
import { LoanAppComplete } from '../../default/loanApp/LoanAppComplete';
import { adSpireCallback } from '../../../integrations/AdSpire';
import { RegisterFormPhotoSelfie } from '../registration/RegisterFormPhotoSelfie';
import { LoanAppBindNewCard } from './LoanAppBindNewCard';
import { RegistrationFormAddress } from '../registration/RegisterFormAddress';
import { RegisterFormWorkplace } from '../registration/RegisterFormWorkplace';
import { RegisterFormIncome } from '../registration/RegisterFormIncome';

const RENDERER_MAP = {
  address: RegistrationFormAddress,
  workplace: RegisterFormWorkplace,
  income: RegisterFormIncome,
  photoPassportNewClient: RegisterFormPhotoPassport,
  profile: ProfileForm,
  photoPassport: RegisterFormPhotoPassport,
  photoSelfie: RegisterFormPhotoSelfie,
  licenses: ProfileFormLicenses,
  purpose: LoanAppPurpose,
  amount: LoanAppAmount,
  moneyMethod: LoanAppMoneyMethod,
  smevIdentificationResult: SmevIdentificationResult,
  card: LoanAppCard,
  bindNewCard: LoanAppBindNewCard,
  end: LoanAppComplete,
};

const MAP_PROCESS_TO_STEPS = {
  profile: [
    'profile',
    'photoPassport',
    'photoSelfie',
    'licenses',
    'address',
    'workplace',
    'income',
    'photoPassportNewClient',
  ],
  amount: ['purpose', 'amount', 'moneyMethod'],
  additional: ['card', 'bindNewCard'],
};

const STEP_POSTPROCESS_CALLBACK_MAP = {
  card: adSpireCallback,
};

export default { RENDERER_MAP, MAP_PROCESS_TO_STEPS, STEP_POSTPROCESS_CALLBACK_MAP };
