import * as React from 'react';
import { useTranslation } from '../../utils/useTranslation';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { Currency } from '../Currency';
import { CreditT } from '@brainysoft/lk-components';

interface IOfferItem {
  amount?: number;
  term?: number;
  position?: number;
  interest?: number;
  additionsInfo?: string;
  high_pdn?: boolean;
}

interface IIndividualOfferProps {
  offers: IOfferItem[];
  credit: CreditT;
  active: number | null;
  onChange?: (i: number) => void;
  disable_pdn?: boolean;
}

export const IndividualOffer: React.FC<IIndividualOfferProps> = ({
  offers,
  credit,
  active,
  onChange,
  disable_pdn = false,
}) => {
  const classes = useThemeStyles('IndividualOffer');

  const { t } = useTranslation();

  const onClickHandler = (e: number) => {
    if (typeof onChange === 'function') {
      if (disable_pdn && list[e]?.high_pdn == true) return;
      onChange(e);
    }
  };

  const list = offers;

  const getClasses = (e, i) => {
    const classes = ['individual-offer-block'];
    if (active !== null) {
      classes.push(active == i ? 'active' : 'unactive');
    }
    //console.log();
    if (disable_pdn && e.high_pdn === true) classes.push('disabled');
    return classes.join(' ').trim();
  };

  return (
    <div className={classes.individualOfferSection}>
      {list.map((e, i) => (
        <div
          key={e.position}
          className={getClasses(e, i)}
          onClick={() => {
            onClickHandler(e.position);
          }}
        >
          <div className={'individual-offer-amount'}>
            <div className={'individual-offer-label'}>Сумма займа</div>
            <div className={'individual-offer-value'}>
              <Currency showDecimals={false} amount={e.amount} className='individual-offer-value_bold' />
            </div>
          </div>
          <div className={'individual-offer-term'}>
            <div className={'individual-offer-label'}>Срок займа</div>
            <div className={'individual-offer-value individual-offer-value_bold'}>{e.term} дней</div>
          </div>
          {e.additionsInfo && (
            <div className={'individual-offer-addition'}>
              <div className={'individual-offer-value'}>{e.additionsInfo}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
