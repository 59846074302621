import * as React from 'react';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { Button, Divider, Spin } from 'antd';
import { useSubscriptions } from '../../../utils/useSubscriptions';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { InterfaceModules } from '@brainysoft/lk-components';
import { useClient } from '../../../queries/useClient';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useInterfaceModuleSetting } from '../../../utils/useInterfaceModuleSetting';
import { ProfileFormFioStatic } from '../../default/profile/ProfileFormFioStatic';
import { ProfileFormPassportStatic } from './ProfileFormPassportStatic';
import { ProfileFormAddressStatic } from '../../default/profile/ProfileFormAddressStatic';
import { ProfileFormAdditionalStatic } from './ProfileFormAdditionalStatic';

export const ClientProfile: React.FC = () => {
  const classes = useThemeStyles('ProfilePage');
  const layoutClasses = useThemeStyles('Layout');
  const clientQuery = useClient();
  const client = clientQuery.data;
  const { subscriptions, isLoading, updateSubscriptions } = useSubscriptions();

  const mailingSettings = useInterfaceModuleSetting(InterfaceModules.MAILING_SETTINGS);

  const onSubmit = async (values) => {
    await updateSubscriptions(values);
  };

  const mailingWidgetVisible = mailingSettings?.active ?? true;

  return (
    <React.Fragment>
      <div className={layoutClasses.pageWrap}>
        <p className={layoutClasses.pageTitle}>Профиль пользователя</p>
        <div className={classes.profileWrapper}>
          {!clientQuery.isLoading && (
            <React.Fragment>
              <Divider />
              <h4>ФИО и контактные данные</h4>
              <ProfileFormFioStatic client={client} />
              <Divider />
              <h4>Паспортные данные</h4>
              <ProfileFormPassportStatic client={client} />
              <Divider />
              <h4>Адрес</h4>
              <ProfileFormAddressStatic client={client} />
              <Divider />
              <h4>Дополнительная информация</h4>
              <ProfileFormAdditionalStatic client={client} />
            </React.Fragment>
          )}

          {clientQuery.isLoading && <LoadingSpinner size={8} margin={4} />}

          {mailingWidgetVisible && (
            <React.Fragment>
              <Divider />

              <h4>Оповещения:</h4>
              <p>Хочу получать последние новости и специальные предложения</p>

              <Spin spinning={isLoading} indicator={<LoadingSpinner size={8} margin={4} />}>
                <Formik enableReinitialize={true} initialValues={subscriptions} onSubmit={onSubmit}>
                  {(formik: FormikProps<any>) => {
                    return (
                      <FormikForm formik={formik} onSubmit={formik.handleSubmit}>
                        <div className='fields'>
                          <Checkbox name={'marketing.sms'} label={'Участвует в СМС-рассылке'} />
                        </div>
                        <div className='fields'>
                          <Checkbox name={'marketing.email'} label={'Участвует в Email-рассылке'} />
                        </div>
                        <Button htmlType={'submit'} type={'primary'}>
                          Изменить
                        </Button>
                      </FormikForm>
                    );
                  }}
                </Formik>
              </Spin>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
