import { get } from 'lodash';
import { processService, ProcessType } from '@brainysoft/lk-components';
import { notify } from './notify';
import { FileSettingsT } from '../processForms/monedo/registration/RegisterFormPhotoUploader';
import { useState } from 'react';

type UseUploadProcessFormFilesProps<T = Record<string, any>> = (props: {
  fileSettings: FileSettingsT[];
  processName: ProcessType;
  processUuid: string;
  processSession: string;
}) => {
  isLoading: boolean;
  uploadFormFiles: (
    values: T,
    callbacks: {
      onSuccess?: () => Promise<void>;
      onError?: () => Promise<void>;
    }
  ) => Promise<void>;
};

export const useUploadFormFiles: UseUploadProcessFormFilesProps = ({
  processName,
  processUuid,
  processSession,
  fileSettings,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const uploadFormFiles = async (values, { onSuccess, onError }) => {
    setIsLoading(true);

    const files: any[] = [];
    let allRequiredFilesAttached = true;

    for (const item of fileSettings) {
      const file = new FormData();
      const fileValue = get(values, `__file_${item.name}`);
      file.append('file', fileValue?.originFileObj);
      file.append('code', item.code);
      files.push(file);
      if (item?.required) {
        allRequiredFilesAttached = allRequiredFilesAttached && !!fileValue;
      }
    }

    if (!allRequiredFilesAttached) {
      console.error('No valid file for required field');
      if (typeof onError === 'function') await onError();
      return;
    }

    const promises = [] as Promise<any>[];

    for (const file of files) {
      promises.push(processService.saveProcessFile(processName, processUuid, processSession, file));
    }

    const res = await Promise.all(promises);

    const filesHaveBeenDownloaded = res.reduce((sum, res) => sum && res && res[0] === 'File stored to repo', true);

    if (filesHaveBeenDownloaded) {
      if (typeof onSuccess === 'function') await onSuccess();
    } else {
      console.error('No valid file for required field');
      notify.error('Не удалось загрузить файлы');

      if (typeof onError === 'function') await onError();
    }

    return;
  };

  return { isLoading, uploadFormFiles };
};
