import * as React from 'react';
import { useFormikContext } from 'formik';

const ExcludedErrors = ['error_email_verification_request_not_sent', 'error_email_not_confirmed'];

export const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  React.useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    console.log('errors', errors, fieldErrorNames);

    if (fieldErrorNames.length <= 0) return;

    //не прокручиваем к excluded errors
    const firstErrorFieldName = Object.keys(errors)[0];
    if (ExcludedErrors.includes(errors[firstErrorFieldName])) return;

    const element = document.querySelector(`[name='${fieldErrorNames[0]}']`);
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount]);

  return null;
};

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};
