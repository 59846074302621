import { ProfileFormFio } from './ProfileFormFio';
import { ProfileFormPassport } from './ProfileFormPassport';
import { ProfileFormAddress } from './ProfileFormAddress';
import { ProfileFormAdditional } from './ProfileFormAdditional';
import { ProfileFormFioStatic } from '../../default/profile/ProfileFormFioStatic';
import { ProfileFormPassportStatic } from '../../default/profile/ProfileFormPassportStatic';
import { ProfileFormAddressStatic } from '../../default/profile/ProfileFormAddressStatic';
import { ProfileFormAdditionalStatic } from './ProfileFormAdditionalStatic';
import { ClientProfile } from './ClientProfile';

const RENDERER_MAP = {
  ClientProfile: ClientProfile,
  ProfileFormFio: ProfileFormFio,
  ProfileFormFioStatic: ProfileFormFioStatic,
  ProfileFormPassport: ProfileFormPassport,
  ProfileFormPassportStatic: ProfileFormPassportStatic,
  ProfileFormAddress: ProfileFormAddress,
  ProfileFormAddressStatic: ProfileFormAddressStatic,
  ProfileFormAdditional: ProfileFormAdditional,
  ProfileFormAdditionalStatic: ProfileFormAdditionalStatic,
};

export default { RENDERER_MAP };
