import * as React from 'react';
import { IProcessStepProps, PhotoType, tokenService } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { OtpChallengeType } from '@brainysoft/lk-components';
import { Message } from '../../../components/Message';
import { ChallengeApproval } from '../../../packages/Formik/Components/ChallengeApproval';
import { useRouter } from 'next/router';
import { useGoogleAnalytics } from '../../../integrations/useGoogleAnalytics';
import { useYandexMetrika } from '../../../integrations/useYandexMetrika';
import { Col, Divider, Row } from 'antd';
import { Button } from '../../../components/Button';
import { Dragger } from '../../../packages/Formik/Components/Dragger';
import { get } from 'lodash';
import { FileSettingsT } from './RegisterFormPhotoUploader';
import { useUploadFormFiles } from '../../../utils/useUploadFiles';

const fileSettings: FileSettingsT[] = [
  {
    name: PhotoType.SELFIE_RECOVERY,
    // label: 'Фото селфи с паспортом',
    placeholder: 'Загрузите фото селфи с паспортом',
    code: PhotoType.SELFIE_RECOVERY,
    placeholderImgUrl: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/selfie_square.jpg',
    required: false,
  },
  {
    name: PhotoType.SELFIE_SNILS_RECOVERY,
    // label: 'Фото селфи со СНИЛС',
    placeholder: 'Загрузите фото селфи со СНИЛС',
    code: PhotoType.SELFIE_SNILS_RECOVERY,
    placeholderImgUrl:
      'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/selfie_snils_square.jpg',
    required: false,
  },
];

export const RegisterFormSimilar: React.FC<IProcessStepProps> = (props) => {
  const classes = useThemeStyles('RegistrationPage');
  const router = useRouter();
  const { initialValues, isForwardLoading, ...rest } = props;

  const [photoSubmitDisabled, setPhotoSubmitDisabled] = React.useState(false);
  const [sendProcessData, setSendProcessData] = React.useState(false);

  const isSimilarFio = props.step?.name === 'similarFio';

  React.useEffect(() => {
    props.setForwardButtonText('Продолжить регистрацию');
    props.setStepsVisible(false);

    // если одинаковый снилс или инн - не даем возможности продолжить регистрацию
    if (!isSimilarFio) props.setForceIsForwardVisible(false);

    setPhotoSubmitDisabled(true);
  }, []);

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const onApprove = async (result) => {
    tokenService.setTokenToStorage(result.access_token);
    ga.send('login');
    ym.send('login');
    await router.push('/');
  };

  const maskedPhone = initialValues?.similarPhone?.masked;

  const onUpdate = (formik) => {
    const allFilesLoaded = fileSettings.reduce((sum, item) => {
      const file = get(formik.values, item.name);
      return sum && !!file;
    }, true);

    setPhotoSubmitDisabled(!allFilesLoaded);
  };

  const { uploadFormFiles, isLoading } = useUploadFormFiles({
    fileSettings: fileSettings,
    processName: props.processName,
    processUuid: props.processUuid,
    processSession: props.processSession,
  });

  const sendPhoto = async (formik) => {
    setSendProcessData(true);
    formik.submitForm();
  };

  const onSubmit = async ({ values, submit }) => {
    values['sendProcessData'] = sendProcessData;
    if (sendProcessData) {
      await uploadFormFiles(values, {
        onSuccess: async () => {
          for (const item of fileSettings) {
            delete values[`__file_${item.name}`];
            delete values[item.name];
          }
          await submit(values);
        },
      });
    } else {
      for (const item of fileSettings) {
        delete values[`__file_${item.name}`];
        delete values[item.name];
      }
      await submit(values);
    }
    return;
  };

  return (
    <ProcessStepForm
      {...rest}
      initialValues={initialValues}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      render={(formik) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          //сбрасываем статусы что фото загруженны
          fileSettings.forEach((item) => {
            formik.setFieldValue(item.name, undefined);
            formik.setFieldValue(`files.${item.code}__fileId`, undefined);
          });
        }, []);
        return (
          <React.Fragment>
            <Message type={'info'}>
              <h3 className={'mb0'}>Если номер телефона {maskedPhone} ваш, нажмите получить код через SMS.</h3>
              {/*<div>*/}
              {/*  Если нам показалось, просто продолжите заполнение анкеты. Если номер телефона {maskedPhone} ваш, нажмите*/}
              {/*  получить код через SMS. Если данный номер телефона больше недоступен, свяжитесь с нами{' '}*/}
              {/*  <a href={'tel:84952259063'}>+7(495)225-90-63</a> или напишите на{' '}*/}
              {/*  <a href={'mailto:info@kredito24.ru'}>info@kredito24.ru</a>, и мы поможем восстановить доступ.*/}
              {/*</div>*/}
              {/*<div>Для подтверждения Вашего номера телефона получите по СМС секретный код и введите его.</div>*/}
            </Message>
            <div className={classes.codeApprovalWrapper}>
              {/*используем maskedPhone, т.к. ChallengeApproval требует телефон для полноценного функционирования*/}
              {/*реально будет использован телефон из процесса для данного типа челленджа*/}
              {/*для этого передаем uuid процесса в challengePayload*/}
              <ChallengeApproval
                formik={formik}
                idField={'similarPhoneId'}
                approveField={'similarPhoneApproved'}
                otpChallengeType={OtpChallengeType.SIMILAR_DATA_AUTH}
                mobilePhone={maskedPhone}
                horizontal={true}
                onApprove={onApprove}
                isPhoneCodeRequired={false}
                challengePayload={{
                  uuid: props.processUuid,
                }}
              />
            </div>
            <Divider />
            <Message type={'info'}>
              <h3>Если номер {maskedPhone} больше недоступен, мы поможем восстановить доступ к личному кабинету.</h3>
              <div>
                Самый быстрый способ восстановить доступ к личному кабинету - прикрепить свои селфи с паспортом и селфи
                с СНИЛС ниже.
              </div>
            </Message>
            <div className={classes.codeApprovalWrapper}>
              <Row gutter={24} className={classes.registrationFormPassport}>
                <Col xs={24} lg={24} className={classes.photoInfo}>
                  <h3>Загрузите селфи c паспортом и СНИЛС</h3>
                </Col>

                {fileSettings.map((item, index) => {
                  return (
                    <Col xs={24} lg={8} key={index} className={classes.uploaderColumn}>
                      <Dragger
                        name={item?.name}
                        label={item?.label}
                        placeholder={item?.placeholder}
                        placeholderImgUrl={item?.placeholderImgUrl}
                        required={item?.required}
                        compact={true}
                      />
                    </Col>
                  );
                })}
              </Row>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type={'primary'}
                  shape={'round'}
                  size={'large'}
                  disabled={photoSubmitDisabled || isForwardLoading || isLoading}
                  onClick={() => sendPhoto(formik)}
                >
                  Отправить фото
                </Button>
              </div>
            </div>
            <Divider />
            Также вы можете связаться с нами по телефону <a href={'tel:84952259063'}>+7(495)225-90-63</a> или по{' '}
            <a href={'mailto:info@kredito24.ru'}>info@kredito24.ru</a>.
            <Divider />
            Если номер 7******XXXX никогда вам не принадлежал, возможно вы допустили опечатку в данных, указанных на
            предыдущих шагах? Вернитесь обратно и проверьте введенные данные.
            {isSimilarFio && (
              <React.Fragment>
                <Divider />
                <Message type={'neutral'}>
                  Если нам показалось, просто продолжите заполнение анкеты, нажав кнопку <b>Продолжить регистрацию</b>.
                </Message>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};
