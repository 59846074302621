import { SignOfferIntro } from './SignOfferIntro';
import { SignOfferResult } from './SignOfferResult';
import { SignOfferSign } from './SignOfferSign';
import { SignOfferSelfie } from './SignOfferSelfie';

const RENDERER_MAP = {
  intro: SignOfferIntro,
  signDocuments: SignOfferSign,
  end: SignOfferResult,
  selfie: SignOfferSelfie,
};

export default { RENDERER_MAP };
