import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { IProcessStepProps } from '@brainysoft/lk-components';
import { Col, Row } from 'antd';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { Select } from '../../../packages/Formik/Components/Select';
import { DictionaryType, DisplayModeType } from '@brainysoft/lk-components';
import { useDictionary } from '../../../queries/useDictionary';
import { AppContext } from '@brainysoft/lk-components';

export const LoanAppPurpose: React.FC<IProcessStepProps> = (props) => {
  const classes = useThemeStyles('LoanAppCreatePage');

  React.useEffect(() => {
    props.setSubmitDisabled(false);
  }, []);

  const { state } = AppContext.useContainer();
  const mode = state.displayMode;

  const creditPurposeQuery = useDictionary(DictionaryType.CREDIT_PURPOSE);

  const creditPurpose = creditPurposeQuery.data ?? [];

  return (
    <ProcessStepForm
      {...props}
      render={() => {
        return (
          <React.Fragment>
            <div className={classes.formWrapper}>
              <h2 className={classes.header}>Укажите цель займа:</h2>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} offset={mode == DisplayModeType.MOBILE ? 0 : 6}>
                  <div className='field'>
                    <Select
                      name='creditPurposeId'
                      label='Цель займа'
                      loading={creditPurposeQuery.isLoading}
                      placeholder='Выберите цель займа'
                      options={creditPurpose.map((item) => {
                        return {
                          value: item.id,
                          option: item.name,
                        };
                      })}
                      required={true}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};
