import * as React from 'react';
import { ProcessFormsType } from '@brainysoft/lk-components';
import { Processes } from '../processForms/active';

type UseProcessFormsType = (process: ProcessFormsType) => {
  renderersMap: Record<string, React.FC<any>>;
  stepsMap: Record<string, string[]> | undefined;
  callbackMap: Record<string, (data: any) => void> | undefined;
};

export const useProcessForms: UseProcessFormsType = (process) => {
  const [tenantForm, setTenantForm] = React.useState<{
    RENDERER_MAP: Record<string, React.FC<any>>;
    MAP_PROCESS_TO_STEPS?: Record<string, string[]> | undefined;
    STEP_POSTPROCESS_CALLBACK_MAP?: Record<string, (data: any) => void> | undefined;
  }>({
    RENDERER_MAP: {},
    MAP_PROCESS_TO_STEPS: undefined,
    STEP_POSTPROCESS_CALLBACK_MAP: undefined,
  });

  React.useEffect(() => {
    const data = Processes[process];
    setTenantForm(data);
  }, []);

  return {
    renderersMap: tenantForm.RENDERER_MAP,
    stepsMap: tenantForm.MAP_PROCESS_TO_STEPS,
    callbackMap: tenantForm.STEP_POSTPROCESS_CALLBACK_MAP,
  };
};
