import * as React from 'react';
import { IProcessStepProps, DictionaryType, dataSourcesService } from '@brainysoft/lk-components';
import { Col, Divider, Row } from 'antd';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useDictionary } from '../../../queries/useDictionary';
import { DatePicker } from '../../../packages/Formik/Components/DatePicker';
import { Icon } from '../../../components/Icon';
import { FormikProps } from 'formik';
import { get, has } from 'lodash';
import { DadataInputAntd } from '../../../packages/Formik/Components/DadataInputAntd';
import { Message } from '../../../components/Message';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { Button } from '../../../components/Button';
import { useTimer } from '../../../utils/useTimer';
import { ProfileFormPassportStatic } from './ProfileFormPassportStatic';
import { passportIssueDisabledDates, passportIssueDateValidator } from '../../../utils/passportDatesVerification';

interface IProps extends IProcessStepProps {
  formik: FormikProps<any>;
  onUpdateRef?: any;
  onSubmitRef?: any;
}

export const ProfileFormPassport: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { initialValues, onUpdateRef, formik } = props;

  const countriesQuery = useDictionary(DictionaryType.COUNTRIES);
  const countries = countriesQuery.data ?? [];
  const russiaId = countries.reduce((acc, val) => {
    if (val.name.toLowerCase() === 'россия') acc = val.id;
    return acc;
  }, null);

  // initialValues.lead_extraFields['contract'] = {assignmentConsent: initialValues.lead_extraFields['contract.assignmentConsent']}

  const showConsents = initialValues?.isRepeatedLead;

  const [isEditable, setIsEditable] = React.useState(true);
  const [managerVisible, setManagerVisible] = React.useState(!!initialValues?.passport?.subdivisionCode);

  const [isInnLoading, setIsInnLoading] = React.useState(false);
  const [showDataAlert, setShowDataAlert] = React.useState(!initialValues?.isRepeatedLead && !initialValues?.rnn);
  const [isDataRequestButtonVisible, setIsDataRequestButtonVisible] = React.useState(!initialValues?.rnn);
  const [isDataRequestButtonDisabled, setIsDataRequestButtonDisabled] = React.useState(false);

  const { isExpired, setTimer } = useTimer('data-source:fns');

  React.useEffect(() => {
    if (isExpired) {
      setIsDataRequestButtonDisabled(false);
    }
  }, [isExpired]);

  onUpdateRef.current = (formik, changed) => {
    if (formik?.values?.passport?.subdivisionCode) setManagerVisible(true);
    if (formik?.values?.birthCountryId === null && russiaId > 0)
      formik.setFieldValue('values.birthCountryId', russiaId);
    // eslint-disable-next-line no-prototype-builtins
    if (has(changed, '_suggestion_passport')) {
      const subdivisionCode = get(formik, 'values.passport.subdivisionCode');
      const passportManager = get(formik, 'values.passport.manager');

      if (changed._suggestion_passport.subdivisionCode) {
        const suggestion = get(formik, 'values._suggestion_passport.subdivisionCode');
        if (suggestion && suggestion?.data?.code === subdivisionCode && suggestion?.value !== passportManager) {
          formik.setFieldValue('passport.manager', suggestion?.value);
        }
      }

      if (changed._suggestion_passport.manager) {
        const suggestion = get(formik, 'values._suggestion_passport.manager');
        if (suggestion && suggestion?.value === passportManager && suggestion?.data?.code !== subdivisionCode) {
          formik.setFieldValue('passport.subdivisionCode', suggestion?.data?.code);
        }
      }
    }

    if (changed?.passportSeriaNo?.length === 10) {
      formik.setFieldValue('passport.seria', changed.passportSeriaNo.slice(0, 4));
      formik.setFieldValue('passport.no', changed.passportSeriaNo.slice(4, 10));
    }

    if (Object.keys(changed).includes('rnn')) {
      setIsDataRequestButtonVisible(true);
    }
  };

  const handleFindInnRequest = async () => {
    setIsInnLoading(true);
    const data = {
      objects: {
        client: {
          naturalperson: 'true',
          firstName: formik.values?.firstName,
          lastName: formik.values?.lastName,
          patronymic: formik.values?.patronymic,
          birthDate: formik.values?.birthDate,
          passport: {
            seria: formik.values?.passport?.seria,
            no: formik.values?.passport?.no,
            issueDate: formik.values?.passport?.issueDate,
          },
          rnn: null,
        },
      },
    };
    const res = await dataSourcesService.requestInn(data);

    if (res.resultCode !== 'FOUND') {
      setShowDataAlert(true);
      formik.setFieldTouched('rnn', true);
      formik.setFieldError('rnn', 'required');
    }

    if (res.iNN) {
      formik.setFieldValue('rnn', res.iNN);
      formik.setFieldValue('fns', { resultCode: res.resultCode, rnn: res.iNN });
    }

    void setTimer(65);
    setIsDataRequestButtonDisabled(true);
    setIsInnLoading(false);
  };

  return (
    <React.Fragment>
      {!isEditable && <ProfileFormPassportStatic client={initialValues} />}

      <div style={{ display: isEditable ? 'block' : 'none' }}>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <div className='fields'>
              <MaskedInput
                name='passportSeriaNo'
                label='Серия и номер паспорта'
                placeholder='Серия и номер Вашего паспорта'
                mask='passportSeriaNo'
                required={true}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='field'>
              <DatePicker
                name='passport.issueDate'
                label='Дата выдачи'
                placeholder='Дата выдачи в формате ДД.ММ.ГГГГ'
                outgoingFormat='YYYY-MM-DD'
                required={true}
                disabledDate={(current) => passportIssueDisabledDates(current, formik.values.birthDate)}
                validate={(value) => passportIssueDateValidator(value, formik.values.birthDate)}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Message type={'info'}>
              Информация о подразделении (код и название подразделения) заполняется точно в соответствии с паспортом!
            </Message>
          </Col>
          <Col xs={24} sm={8}>
            <div className='field'>
              <DadataInputAntd
                type='fms_unit'
                name='passport.subdivisionCode'
                label='Код подразделения как в паспорте'
                placeholder='Код подразделения'
                required={true}
                initialValue={get(props, 'initialValues.passport.subdivisionCode')}
                mapDataField='code'
                mask={'passportSubdivisionCode'}
              />
            </div>
          </Col>
          <Col xs={24} sm={16}>
            <div className='field'>
              <DadataInputAntd
                type='fms_unit'
                name='passport.manager'
                label='Кем выдан как в паспорте'
                placeholder='Кем выдан паспорт'
                required={true}
                hidden={!managerVisible}
                mask='cyrillicStrict'
                initialValue={get(props, 'initialValues.passport.manager')}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24}>
            <div className='field'>
              <MaskedInput
                name='birthPlace'
                label='Место рождения как в паспорте'
                placeholder='Место рождения как в паспорте'
                mask='birthPlaceStrict'
                required={true}
              />
            </div>
          </Col>
        </Row>

        {showDataAlert && (
          <Row>
            <Col xs={24} sm={24}>
              <Message type={'error'}>
                Номер ИНН не найден. Проверьте введённые персональные и паспортные данные и исправьте ошибку. Просим
                также указать номер ИНН вручную.
              </Message>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <div className='field'>
              <MaskedInput
                name='rnn'
                label='Номер ИНН'
                placeholder='Введите Ваш ИНН'
                mask='innUnique'
                required={true}
              />
            </div>
            {isDataRequestButtonVisible && (
              <Button
                loading={isInnLoading}
                disabled={isDataRequestButtonDisabled}
                size={'small'}
                onClick={handleFindInnRequest}
              >
                Найти ИНН в базе ФНС
              </Button>
            )}
          </Col>
        </Row>

        {showConsents && (
          <React.Fragment>
            <Divider />

            <Row gutter={16}>
              <Col xs={24} sm={24}>
                <div className='field'>
                  <div className={classes.checkboxWrapper}>
                    <Checkbox name='lead_extraFields.contract.assignmentConsent' />
                    <div className={classes.checkboxLabel}>
                      Предоставляю согласие на уступку кредитором третьим лицам прав (требований) по договору займа.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24}>
                <div className='field'>
                  <div className={classes.checkboxWrapper}>
                    <Checkbox name='lead_extraFields.contract.autoChargeOff' />
                    <div className={classes.checkboxLabel}>
                      Предоставляю заранее данный акцепт на списание денежных средств по Договору потребительского займа
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>

      {!isEditable && (
        <Button
          type={'link'}
          onClick={() => {
            setIsEditable(true);
          }}
          className={classes.changeButton}
        >
          <Icon type={'edit'} /> Изменить
        </Button>
      )}
    </React.Fragment>
  );
};
