import dayjs, { Dayjs } from 'dayjs';
export const disabledFutureDates = (current: Dayjs): boolean => {
  // Can not select days after today
  return current && current >= dayjs().startOf('day');
};

export const disabledPastDates = (current: Dayjs): boolean => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};

export const disabledInvalidAgeDate = (current) => {
  const now = dayjs();
  const tooYoung = now.diff(current, 'years') < 18;
  const tooOld = now.diff(current, 'years') > 100;
  return tooYoung || tooOld;
};

export const validateAgeDate = (value) => {
  const selected = dayjs(value);
  const now = dayjs();
  const tooYoung = now.diff(selected, 'years') < 18;
  const tooOld = now.diff(selected, 'years') > 100;
  return tooYoung || tooOld ? 'wrongDate' : undefined;
};
