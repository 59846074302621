import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';

interface CardActiveProps {
  owner?: string;
  cardMonth?: string;
  cardYear?: string;
  cardNumber?: string;
  emitent?: string;
  paySystem?: string;
  className?: string;
  onClick?: any;
}

export const CardActive: React.FC<CardActiveProps> = (props) => {
  const classes = useThemeStyles('Card');
  const onClick = props.onClick && typeof props.onClick === 'function' ? props.onClick : () => {};

  return (
    <div className={`${classes.card} ${props.className ? props.className : ''}`} onClick={onClick}>
      <div className={classes.paySystem}>{props.paySystem}</div>
      <div className={classes.cardNumber}>{props.cardNumber}</div>
      <div className={classes.cardValidWrapper}>
        <span className={classes.cardMonth}>{props.cardMonth}</span>
        <span className={classes.cardYear}>{props.cardYear}</span>
      </div>
    </div>
  );
};
