import React from 'react';
import { CardTokenStatusT, CardTokenStatusType, paymentsService } from '@brainysoft/lk-components';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

export const useCardTokenStatus: (statusId?: string, enabled?: boolean) => UseQueryResult<CardTokenStatusT> = (
  statusId,
  enabled = true
) => {
  const [refetchInterval, setRefetchInterval] = React.useState<number | false>(false);

  return useQuery(
    ['cardTokenStatus', statusId],
    () => {
      if (!statusId)
        return new Promise((resolve) => {
          resolve({} as CardTokenStatusT);
        });
      return paymentsService.cardTokenStatus(statusId);
    },
    {
      enabled: enabled,
      refetchInterval: refetchInterval,
      onSuccess: (data) => {
        if (
          !data?.error &&
          data?.status !== CardTokenStatusType.ERROR &&
          data?.status !== CardTokenStatusType.SUCCESS
        ) {
          const interval = refetchInterval && !isNaN(Number(refetchInterval)) ? Number(refetchInterval) * 2 : 3000;
          setRefetchInterval(interval);
        } else {
          setRefetchInterval(false);
        }
      },
    }
  );
};
