import { useQueries, useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { QueryClient } from 'react-query/core';
import { ONE_HOUR_STALE } from '../const';
import { DictionaryType } from '@brainysoft/lk-components';
import { dictionaryService } from '@brainysoft/lk-components';

export const useDictionaries: (names: string[]) => UseQueryResult<any>[] = (names) => {
  return useQueries(
    names.map((name) => {
      return {
        queryKey: ['dictionary', name],
        queryFn: () => {
          return dictionaryService.getOne(name) as Promise<any[]>;
        },
        staleTime: ONE_HOUR_STALE,
      };
    })
  );
};

export const useDictionary: (name: DictionaryType) => UseQueryResult<any[]> = (name) => {
  return useQuery(
    ['dictionary', name],
    () => {
      return dictionaryService.getOne(name) as Promise<any[]>;
    },
    {
      staleTime: ONE_HOUR_STALE,
    }
  );
};

export const refreshDictionary: (name: DictionaryType, queryClient: QueryClient) => void = (name, queryClient) => {
  queryClient.invalidateQueries(['dictionary', name]);
};
