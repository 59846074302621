import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { IProcessStepProps, PrintFormContextType } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { get } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { PrintFormDocument } from '../../../components/PrintFormDocument/PrintFormDocument';
import { PrintFormType } from '@brainysoft/lk-components';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useTranslation } from '../../../utils/useTranslation';
import { ChallengeApproval } from '../../../packages/Formik/Components/ChallengeApproval';
import { OtpChallengeType } from '@brainysoft/lk-components';
import { ProcessType } from '@brainysoft/lk-components';
import { useSubscriptions } from '../../../utils/useSubscriptions';
import { useGoogleAnalytics } from '../../../integrations/useGoogleAnalytics';
import { useYandexMetrika } from '../../../integrations/useYandexMetrika';
import { Collapse, Panel } from '../../../components/Collapse';
import RightOutlined from '@ant-design/icons/RightOutlined';
import dayjs from 'dayjs';

export const ProfileFormLicenses: React.FC<IProcessStepProps> = (props) => {
  const [isCollapseVisible, setIsCollapseVisible] = React.useState(false);

  const classes = useThemeStyles('RegistrationPage');
  const consentsClasses = useThemeStyles('Consents');
  const { t } = useTranslation();

  const { initialValues, ...rest } = props;

  delete initialValues?.documentsSignId;

  const data = useSubscriptions();
  const { subscriptions, isLoading } = data;

  React.useEffect(() => {
    props.setSubmitDisabled(true);
  }, []);

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const onUpdate = async (formik, changed) => {
    if (changed === undefined) {
      formik.setFieldValue('lead_extraFields.contract.assignmentConsent', true);
      formik.setFieldValue('lead_extraFields.contract.autoChargeOff', true);
    }

    if (typeof changed?.ddoConsentApproved === 'boolean')
      formik.setFieldValue('consentApproved', changed.ddoConsentApproved);

    const dataProcessingApproved = get(formik, 'values.dataProcessingApproved');
    const ddoConsentApproved = get(formik, 'values.ddoConsentApproved');
    const consentApproved = get(formik, 'values.consentApproved');
    const documentsSignId = get(formik, 'values.documentsSignId');
    const acceptSubscribes = get(formik, 'values.acceptSubscribes');
    const phoneCode = get(formik, 'values.phoneCode');
    props.setSubmitDisabled(
      !(
        dataProcessingApproved &&
        consentApproved &&
        ddoConsentApproved &&
        documentsSignId &&
        (needSubscribe() ? acceptSubscribes : true)
      )
    );
    if (
      dataProcessingApproved &&
      consentApproved &&
      ddoConsentApproved &&
      documentsSignId &&
      phoneCode &&
      (needSubscribe() ? acceptSubscribes : true)
    ) {
      const values = formik.values;
      const submit = formik.submitForm;
      onSubmit({ values, submit });
    }
  };

  const onSubmit = async ({ values, submit }) => {
    ga.send('consent_sign_repeat');
    ym.send('consent_sign_repeat');
    await submit({
      ...values,
      client_extraFields: { ...values.client_extraFields, ddoConsent: values.ddoConsentApproved },
      personalDataConsentDate: dayjs(),
      bureauConsentDate: dayjs(),
      acceptSubscribesDate: dayjs(),
    });
  };

  const needSubscribe = () => {
    return (
      !isLoading &&
      (!subscriptions.marketing.email ||
        !subscriptions.marketing.sms ||
        !subscriptions.operational.email ||
        !subscriptions.operational.sms)
    );
  };

  if (
    get(initialValues, 'dataProcessingApproved') === undefined ||
    get(initialValues, 'dataProcessingApproved') === null
  )
    initialValues['dataProcessingApproved'] = true;
  if (get(initialValues, 'consentApproved') === undefined || get(initialValues, 'consentApproved') === null)
    initialValues['consentApproved'] = true;

  if (get(initialValues, 'ddoConsentApproved') === undefined || get(initialValues, 'ddoConsentApproved') === null)
    initialValues['ddoConsentApproved'] = true;

  return (
    <ProcessStepForm
      {...rest}
      initialValues={initialValues}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      render={(formik) => {
        return (
          <React.Fragment>
            <h3>Подпишите документы и подтвердите мобильный телефон</h3>
            <p className={classes.textTemplate1}>
              Введя код вы подтверждаете, что ознакомились и предоставляете следующие согласия
            </p>
            <div className={classes.checkboxWrapper}>
              <Checkbox name='dataProcessingApproved' />
              <div className={classes.checkboxLabel}>
                <span>
                  {
                    <PrintFormDocument
                      contextType={PrintFormContextType.PROCESS}
                      printFormType={PrintFormType.DATA_PROCESSING}
                      name={t(`printForms:name.${PrintFormType.DATA_PROCESSING}`)}
                      type={'link'}
                      params={{
                        processType: ProcessType.LOAN_APP,
                        uuid: props.processUuid,
                        session: props.processSession,
                      }}
                    />
                  }
                </span>
              </div>
            </div>

            <div className={classes.checkboxWrapper}>
              <Checkbox name='ddoConsentApproved' />
              <div className={classes.checkboxLabel}>
                <span>
                  <PrintFormDocument
                    contextType={PrintFormContextType.PROCESS}
                    printFormType={PrintFormType.CONSENT_DDO}
                    name={t(`printForms:name.${PrintFormType.CONSENT_DDO}`)}
                    type={'link'}
                    params={{
                      processType: ProcessType.LOAN_APP,
                      uuid: props.processUuid,
                      session: props.processSession,
                    }}
                  />
                  &nbsp;и&nbsp;
                  <PrintFormDocument
                    contextType={PrintFormContextType.PROCESS}
                    printFormType={PrintFormType.CONSENT}
                    name={t(`printForms:name.${PrintFormType.CONSENT}`)}
                    type={'link'}
                    params={{
                      processType: ProcessType.LOAN_APP,
                      uuid: props.processUuid,
                      session: props.processSession,
                    }}
                  />
                </span>
              </div>
            </div>

            {needSubscribe() && (
              <div className={classes.checkboxWrapper}>
                <Checkbox name='acceptSubscribes' />
                <div className={classes.checkboxLabel}>
                  <span>
                    Я принимаю{' '}
                    <a
                      href={'https://kredito24.ru/netcat_files/docs/Согласие%20на%20информационные%20рассылки.pdf'}
                      target={'_blank'}
                      rel='noreferrer noopener'
                    >
                      условия передачи информации
                    </a>
                  </span>
                </div>
              </div>
            )}

            <div className={classes.codeApprovalWrapper}>
              <ChallengeApproval
                formik={formik}
                idField={'documentsSignId'}
                approveField={'documentsApproved'}
                otpChallengeType={OtpChallengeType.DOCUMENTS_SIGN_UPDATE}
                mobilePhone={props.initialValues?.mobilePhone}
                horizontal={true}
              />
            </div>
            {/*<div className={classes.disclaimer}>{t('processes:registration.pfDisclaimer')}</div>*/}
            {!(
              get(props.initialValues, 'lead_extraFields.contract.assignmentConsent') &&
              get(props.initialValues, 'lead_extraFields.contract.autoChargeOff')
            ) && (
              <>
                <div className={classes.acceptAllWrapper}>
                  <div className={classes.checkboxWrapper}>
                    <div className={classes.textTemplate1} onClick={() => setIsCollapseVisible(!isCollapseVisible)}>
                      Документы для заявки:
                    </div>
                  </div>
                  <span
                    className={`${classes.acceptAllArrow} ${isCollapseVisible ? 'active' : ''}`}
                    onClick={() => setIsCollapseVisible(!isCollapseVisible)}
                  >
                    <RightOutlined />
                  </span>
                </div>

                <Collapse
                  className={`simple borderless paddingless ${classes.generalApproveWrapper} horizontal`}
                  bordered={false}
                  activeKey={isCollapseVisible ? 1 : undefined}
                >
                  <Panel showArrow={false} header='' forceRender={true} key='1'>
                    <div className={consentsClasses.consentsWrapper}>
                      <div className={classes.checkboxWrapper}>
                        <Checkbox name='lead_extraFields.contract.assignmentConsent' />
                        <div className={classes.checkboxLabel}>
                          <span>
                            Предоставляю заранее данный акцепт на списание денежных средств по Договору потребительского
                            займа
                          </span>
                        </div>
                      </div>

                      <div className={classes.checkboxWrapper}>
                        <Checkbox name='lead_extraFields.contract.autoChargeOff' />
                        <div className={classes.checkboxLabel}>
                          <span>
                            Предоставляю согласие на уступку кредитором третьим лицам прав (требований) по договору
                            займа
                          </span>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};
