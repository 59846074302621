import { messagesService, VerificationEntityType, VerificationResponseType } from '@brainysoft/lk-components';
import { QueryClient, useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

export type UseCheckEmailT = (
  email: string,
  entity: VerificationEntityType,
  entityId: string | number,
  onSettled: (data: VerificationResponseType) => Promise<void>,
  enabled?: boolean,
  refetchInterval?: number | false
) => UseQueryResult<VerificationResponseType>;

export const useCheckEmail: UseCheckEmailT = (
  email,
  entity,
  entityId,
  onSettled,
  enabled = true,
  refetchInterval = false
) => {
  return useQuery(
    ['checkEmail', email, entity, entityId],
    () => {
      return messagesService.emailVerificationCheck(email, entity, entityId);
    },
    {
      enabled: Boolean(enabled && entity && entityId),
      refetchInterval,
      onSettled,
    }
  );
};

export const resetCheckEmail: (queryClient: QueryClient, entity, entityId) => void = (
  queryClient,
  entity,
  entityId
) => {
  queryClient.resetQueries('checkEmail', entity, entityId);
};
