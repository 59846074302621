import { FC, useState } from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import {
  cacheService as Storage,
  CheckPhoneResponse,
  ExternalAuthProviders,
  OtpChallengeType,
  otpService,
} from '@brainysoft/lk-components';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { ChallengeApproval } from '../../../packages/Formik/Components/ChallengeApproval';
import { Divider } from 'antd';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { ExternalAuthGosuslugi } from '../../../components/ExternalAuth/ExternalAuthGosuslugi';
import { useRouter } from 'next/router';
import { useCheckExternalAuthProvider } from '../../../utils/useExternalAuthProvider';
import { useTranslation } from '../../../utils/useTranslation';
import { mobilePhonePrefixed } from '../../../packages/Formik/Components/MaskedInput/masks';
import { Button } from '../../../components/Button';
import { useGoogleAnalytics } from '../../../integrations/useGoogleAnalytics';
import { useYandexMetrika } from '../../../integrations/useYandexMetrika';
import { useInvisibleCaptcha } from '../../../utils/useInvisibleCaptcha';

interface IAuthForm {
  externalAuth?: boolean;
  onApprove: (result: any) => void;
  onRegistrationClick: () => void;
}

export const AuthForm: FC<IAuthForm> = (props) => {
  const classes = useThemeStyles('AuthPage');
  const router = useRouter();
  const { t, tAsArray } = useTranslation();
  const esia = useCheckExternalAuthProvider(ExternalAuthProviders.ESIA);

  const [proofButtonDisabled, setProofButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [validPhone, setValidPhone] = useState<string | undefined>();
  const [phoneExists, setPhoneExists] = useState(false);

  const { interceptor } = useInvisibleCaptcha();

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const onApprove = async (result) => {
    setProofButtonDisabled(true);
    await props.onApprove(result);
  };

  const checkPhone = async () => {
    setIsLoading(true);
    setPhoneExists(false);

    // const res = await otpService.checkPhone(validPhone);
    const res = await interceptor<CheckPhoneResponse>(otpService.checkPhone.bind(otpService))(validPhone);

    if (res && (res as CheckPhoneResponse)?.status === false) {
      await Storage.setItem('mobilePhone', validPhone);
      await router.push('/auth/check-phone-result');
    } else if (res && (res as CheckPhoneResponse)?.status === true) {
      setPhoneExists(true);
    }
    setIsLoading(false);
  };

  const onUpdate = (formik) => {
    const mobilePhone = formik.values.mobilePhone;
    if (mobilePhone && !mobilePhonePrefixed.maskValidator(mobilePhone)) {
      setValidPhone(mobilePhone);
      setContinueButtonDisabled(false);
    } else {
      setValidPhone(undefined);
      setContinueButtonDisabled(true);
      setPhoneExists(false);
    }
  };

  const returnUrl = window.location.origin + '/auth/external-auth-result';

  const phoneLabel = esia ? 'или введите ваш номер телефона:' : 'Введите ваш номер телефона:';

  const title = esia
    ? tAsArray('auth:headerExternalAuth').map((text, index) => <div key={index}>{text}</div>)
    : tAsArray('auth:header').map((text, index) => <div key={index}>{text}</div>);

  return (
    <>
      <h1 className={classes.pageTitle}>{title}</h1>

      {!!esia && (
        <>
          <ExternalAuthGosuslugi
            returnUrl={returnUrl}
            preprocess={async () => {
              ga.send('esia_registration');
              ym.send('esia');
            }}
          />

          <Divider />
        </>
      )}

      <Formik initialValues={{ mobilePhone: undefined, code: undefined }} onSubmit={() => {}}>
        {(formik: FormikProps<any>) => {
          return (
            <FormikForm
              formik={formik}
              onUpdate={onUpdate}
              onSubmit={async (e) => {
                e.preventDefault();
                // await checkPhone();
              }}
            >
              <MaskedInput
                name={'mobilePhone'}
                label={phoneLabel}
                mask={'mobilePhonePrefixed'}
                required={true}
                shouldValidateOnChange={true}
              />
              {/*{showCaptcha && !!validPhone && <CAPTCHA />}*/}
              {!phoneExists && (
                <Button disabled={continueButtonDisabled} loading={isLoading} onClick={checkPhone}>
                  Продолжить
                </Button>
              )}
              {phoneExists && (
                <ChallengeApproval
                  formik={formik}
                  idField={'challengeId'}
                  approveField={'auth'}
                  otpChallengeType={OtpChallengeType.AUTH}
                  challengeButtonSize={'middle'}
                  proofButtonSize={'large'}
                  proofButtonType={'primary'}
                  proofButtonDisabled={proofButtonDisabled}
                  onApprove={onApprove}
                  mobilePhone={formik.values?.mobilePhone}
                  phoneValidator={mobilePhonePrefixed.maskValidator}
                />
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
