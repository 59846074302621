import * as React from 'react';
import { IProcessStepProps } from '@brainysoft/lk-components';
import { Button, Col, Row } from 'antd';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { DatePicker } from '../../../packages/Formik/Components/DatePicker';
import { Select } from '../../../packages/Formik/Components/Select';
import { getCodeBySexTypeId, getSexTypeId, SexType } from '@brainysoft/lk-components';
import { Icon } from '../../../components/Icon';
import { FormikProps } from 'formik';
import { ProfileFormFioStatic } from '../../default/profile/ProfileFormFioStatic';
import { DadataInputAntd } from '../../../packages/Formik/Components/DadataInputAntd';
import { get } from 'lodash';
import { EmailApproval } from '../../../packages/Formik/Components/EmailApproval';
import { disabledInvalidAgeDate, validateAgeDate } from '../../../utils/datePickerUtils';

interface IProps extends IProcessStepProps {
  formik: FormikProps<any>;
  onUpdateRef?: any;
  onSubmitRef?: any;
}

export const ProfileFormFio: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { initialValues, onUpdateRef } = props;

  const [requestGender, setRequestGender] = React.useState(
    initialValues?.sexId ? getCodeBySexTypeId(initialValues?.sexId) : 'UNKNOWN'
  );
  const [isEditable, setIsEditable] = React.useState(true);

  onUpdateRef.current = async (formik, changed) => {
    const changedNames = Object.keys(changed ?? {});

    if (
      changedNames.includes('lastName') ||
      changedNames.includes('firstName') ||
      changedNames.includes('patronymic')
    ) {
      let changedFieldName;
      if (changedNames.includes('lastName')) changedFieldName = 'lastName';
      if (changedNames.includes('firstName')) changedFieldName = 'firstName';
      if (changedNames.includes('patronymic')) changedFieldName = 'patronymic';

      const lastName = formik.values?.lastName;
      const firstName = formik.values?.firstName;
      const patronymic = formik.values?.patronymic;
      const sexId = formik.values?.sexId;

      if (lastName || firstName || patronymic) {
        if (!sexId) {
          const newGender = get(formik, `values._suggestion_${changedFieldName}.data.gender`);
          const newSexId = newGender && newGender !== 'UNKNOWN' ? getSexTypeId(newGender) : null;
          formik.setFieldValue('sexId', newSexId);
          setRequestGender(newGender ? newGender : 'UNKNOWN');
        }
      } else {
        formik.setFieldValue('sexId', null);
        setRequestGender('UNKNOWN');
      }
    }

    if (changedNames.includes('sexId')) {
      const sexId = formik.values?.sexId;
      setRequestGender(sexId ? getCodeBySexTypeId(sexId) : 'UNKNOWN');
    }
  };

  return (
    <React.Fragment>
      {!isEditable && <ProfileFormFioStatic client={initialValues} />}

      <div style={{ display: isEditable ? 'block' : 'none' }}>
        <Row gutter={16}>
          <Col xs={24} sm={24}>
            <div className='fields'>
              {/*<Input name="lastName" label="Фамилия" placeholder="Ваша фамилия" required={true} />*/}
              {/*<MaskedInput name="lastName" label="Фамилия" placeholder="Ваша фамилия" mask="cyrillic" required={true} />*/}
              <DadataInputAntd
                name='lastName'
                label='Фамилия'
                placeholder='Ваша фамилия'
                type='fio'
                payloadModifier={{ parts: 'surname', gender: requestGender }}
                required={true}
                mask={'cyrillic'}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <div className='field'>
              {/*<Input name="firstName" label="Имя" placeholder="Ваше имя" required={true} />*/}
              {/*<MaskedInput name="firstName" label="Имя" placeholder="Ваше имя" mask="cyrillic" required={true} />*/}
              <DadataInputAntd
                name='firstName'
                label='Имя'
                placeholder='Ваше имя'
                type='fio'
                payloadModifier={{ parts: 'name', gender: requestGender }}
                required={true}
                mask={'cyrillic'}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='field'>
              {/*<Input name="patronymic" label="Отчество" placeholder="Ваше отчество" required={true} />*/}
              {/*<MaskedInput name="patronymic" label="Отчество" placeholder="Ваше отчество" mask="cyrillic" required={true} />*/}
              <DadataInputAntd
                name='patronymic'
                label='Отчество'
                placeholder='Ваше отчество'
                type='fio'
                payloadModifier={{ parts: 'patronymic', gender: requestGender }}
                mask={'cyrillic'}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={6}>
            <div className='field'>
              <DatePicker
                name='birthDate'
                label='Дата рождения'
                placeholder='ДД.ММ.ГГГГ'
                outgoingFormat='YYYY-MM-DD'
                disabledDate={disabledInvalidAgeDate}
                validate={validateAgeDate}
                required={true}
              />
            </div>
          </Col>
          <Col xs={24} sm={6}>
            <div className='field'>
              <Select
                name='sexId'
                label='Пол'
                required={true}
                options={[
                  { value: SexType.MALE, option: 'Мужчина' },
                  { value: SexType.FEMALE, option: 'Женщина' },
                ]}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='field'>
              <EmailApproval>
                {(validateStatus, isChecking) => (
                  <MaskedInput
                    name='email'
                    label='E-mail'
                    placeholder='Ваш адрес электронной почты'
                    mask='email'
                    required={true}
                    readonly={isChecking}
                    validateStatus={validateStatus}
                    // disabled={initialValues.email}
                  />
                )}
              </EmailApproval>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <div className='field'>
              <MaskedInput
                name='mobilePhone'
                label='Ваш мобильный телефон'
                mask='mobilePhonePrefixed'
                required={true}
                disabled={true}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='field'>
              <MaskedInput
                name='additionalPhone'
                label='Ваш дополнительный телефон (при наличии)'
                mask='mobilePhonePrefixed'
              />
            </div>
          </Col>
        </Row>
      </div>

      {!isEditable && (
        <Button
          type={'link'}
          onClick={() => {
            setIsEditable(true);
          }}
          className={classes.changeButton}
        >
          <Icon type={'edit'} /> Изменить
        </Button>
      )}
    </React.Fragment>
  );
};
