import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';

// eslint-disable-next-line no-unused-vars
import { ClientT } from '@brainysoft/lk-components';
import { StaticRow } from '../../../utils/StaticRow';

interface IProps {
  client: ClientT;
}

export const ProfileFormAddressStatic: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { client } = props;

  return (
    <div className={classes.static}>
      <StaticRow label={'Адрес регистрации'} value={client.registrationAddressData?.fullAddressText} />
      <StaticRow label={'Адрес проживания'} value={client.addressData?.fullAddressText} />

      {/*{client.sameRealAddress && (*/}
      {/*    <StaticRow*/}
      {/*        label={'Адрес проживания совпадает с адресом регистрации'}*/}
      {/*        value={*/}
      {/*            <React.Fragment>*/}
      {/*                <CheckSquareOutlined /> Да*/}
      {/*            </React.Fragment>*/}
      {/*        }*/}
      {/*    />*/}
      {/*)}*/}

      {/*{!client.sameRealAddress && <StaticRow label={'Адрес проживания'} value={client.address?.fullAddressText} />}*/}
    </div>
  );
};
