import React from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { DatePicker as AntdDatePicker } from 'antd';
import { FormItem } from '../FormItem';
import { Field } from 'formik';
import dayjs, { Dayjs } from 'dayjs';

interface IDatePicker {
  format?: string | string[];
  className?: string;
  name: string;
  size?: 'small' | 'large';
  label?: string;
  onChange?: any;
  onBlur?: any;
  placeholder?: string;
  required?: boolean;
  outgoingFormat?: string;
  disabledDate?: any;
  disabled?: boolean;
  validate?: (value) => string | void;
  defaultPickerValue?: Dayjs;
}

// eslint-disable-next-line react/display-name
export const DatePicker = (props: IDatePicker) => {
  const classes = useThemeStyles('Form/DatePicker');
  const [isOpen, setIsOpen] = React.useState<any>(false);

  const { outgoingFormat, size = 'large' } = props;

  const validate = (value) => {
    if (props.required && (value === undefined || value === null || value === '')) return 'required';
    if (typeof props.validate === 'function') return props.validate(value);
  };

  return (
    <Field name={props.name} validate={validate}>
      {({ form, field, meta }) => {
        //setting an initial value
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          const formattedInitialValue = meta.initialValue
            ? outgoingFormat
              ? dayjs(meta.initialValue).format(outgoingFormat)
              : dayjs(meta.initialValue)
            : null;
          form.setFieldValue(field.name, formattedInitialValue);
        }, []);

        return (
          <FormItem {...props} meta={meta} isPanelOpen={isOpen}>
            {!props.disabled && (
              <AntdDatePicker
                id={field.name}
                name={field.name}
                format={props.format ? props.format : ['DD.MM.YYYY', 'DDMMYYYY', 'DD.MM.YYYY', 'DD-MM-YYYY']}
                size={size}
                className={classes.input}
                placeholder={props.placeholder}
                disabledDate={props.disabledDate}
                value={field.value ? dayjs(field.value) : null}
                disabled={props.disabled}
                defaultPickerValue={props.defaultPickerValue}
                onChange={async (value) => {
                  const formattedValue = value
                    ? outgoingFormat
                      ? value.format(outgoingFormat)
                      : value.valueOf()
                    : null;
                  await form.setFieldValue(field.name, formattedValue);
                  await form.setFieldTouched(field.name, true);
                }}
                onBlur={async () => {
                  await form.setFieldTouched(field.name, true);
                }}
                onOpenChange={(open) => {
                  open ? setIsOpen(open) : setTimeout(() => setIsOpen(open), 300);
                }}
              />
            )}
            {!!props.disabled && (
              <div className={`ant-input ant-input-lg disabled static ${classes.input}`}>
                {field.value ? dayjs(field.value).format('DD.MM.YYYY') : ''}
              </div>
            )}
          </FormItem>
        );
      }}
    </Field>
  );
};
