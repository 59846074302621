import * as React from 'react';
import { get } from 'lodash';
import { IProcessStepProps } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { DadataInputAntd } from '../../../packages/Formik/Components/DadataInputAntd';
import { Input } from '../../../packages/Formik/Components/Input';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { Collapse } from '../../../components/Collapse';
import { Panel } from '../../../components/Collapse';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { MIN_FIAS_LVL } from '../../../config';
import { Col, Row, Divider } from 'antd';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { setParsedValues } from '../../../utils/dadataAddressParser';
import { useTranslation } from '../../../utils/useTranslation';

export const RegistrationFormAddress: React.FC<IProcessStepProps> = (props) => {
  const classes = useThemeStyles('RegistrationPage');
  const { t } = useTranslation();

  React.useEffect(() => {
    props.setForwardButtonText(t('buttons.forward'));
    props.setStepsVisible(true);
    props.setSubmitDisabled(false);
  }, []);

  const [realAddressVisible, setRealAddressVisible] = React.useState(true);
  const [registrationAddressDetailsVisible, setRegistrationAddressDetailsVisible] = React.useState(false);
  const [realAddressDetailsVisible, setRealAddressDetailsVisible] = React.useState(false);

  const onUpdate = async (formik, changed) => {
    const { values } = formik;
    const sameRealAddress = values?.sameRealAddress;
    const changedNames = Object.keys(changed ?? {});

    if (sameRealAddress == null) {
      setRealAddressVisible(true);
      formik.setFieldValue('sameRealAddress', true);
    } else {
      setRealAddressVisible(!sameRealAddress);
    }

    if (changedNames.includes('_suggestion_registrationAddress') || changedNames.includes('_suggestion_address')) {
      const registrationAddressSuggestion = values['_suggestion_registrationAddress']?.fullAddressText?.data;
      const addressSuggestion = values['_suggestion_address']?.fullAddressText?.data;

      if (registrationAddressSuggestion) {
        setParsedValues(formik, 'registrationAddress', registrationAddressSuggestion);
        if (sameRealAddress) {
          formik.setFieldValue('address.fullAddressText', values.registrationAddress.fullAddressText);
          setParsedValues(formik, 'address', registrationAddressSuggestion);
        }
      }
      if (sameRealAddress === false && addressSuggestion) {
        setParsedValues(formik, 'address', addressSuggestion);
      }
    }
  };

  const onSubmit = async ({ values, actions, submit }) => {
    if (values?.sameRealAddress) {
      values.address = values.registrationAddress;
      values.addressFiasLvl = values.registrationAddressFiasLvl;
    }
    const registrationAddressFiasLvl = values?.registrationAddressFiasLvl;
    const addressFiasLvl = values?.addressFiasLvl;

    if (!registrationAddressFiasLvl || registrationAddressFiasLvl < MIN_FIAS_LVL) {
      actions.setFieldError('registrationAddress.fullAddressText', 'address_is_too_short');
    }

    if (!addressFiasLvl || addressFiasLvl < MIN_FIAS_LVL) {
      actions.setFieldError('address.fullAddressText', 'address_is_too_short');
    }

    if (
      !registrationAddressFiasLvl ||
      registrationAddressFiasLvl < MIN_FIAS_LVL ||
      !addressFiasLvl ||
      addressFiasLvl < MIN_FIAS_LVL
    )
      return;

    await submit(values);
  };

  const initialValues = {
    ...props.initialValues,
    registrationAddress: {
      ...props.initialValues?.registrationAddress,
      fullAddressText: props.initialValues?.registrationAddress?.fullAddressText || undefined,
    },
    address: {
      ...props.initialValues?.address,
      fullAddressText: props.initialValues?.address?.fullAddressText || undefined,
    },
  };

  return (
    <ProcessStepForm
      {...props}
      initialValues={initialValues}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      render={(formik) => {
        return (
          <React.Fragment>
            <Input name='registrationAddress.fiasId' hidden={true} />
            <Input name='registrationAddressFiasLvl' hidden={true} />
            <Input name='address.fiasId' hidden={true} />
            <Input name='addressFiasLvl' hidden={true} />

            <Row gutter={16}>
              <Col xs={24} sm={24}>
                <div className='field'>
                  <DadataInputAntd
                    type='address'
                    name='registrationAddress.fullAddressText'
                    label='Адрес регистрации, с указанием дома и квартиры, как в паспорте'
                    placeholder='Введите город, улицу, дом, корпус, квартиру'
                    required={true}
                    initialValue={get(props, 'initialValues.registrationAddress.fullAddressText')}
                    forceSuggestion={true}
                    onInit={async (value, suggestions) => {
                      const initialFiasId = get(props, 'initialValues.registrationAddress.fiasId');
                      const filtered = suggestions?.filter((suggestion) => {
                        return suggestion?.data?.fias_id === initialFiasId;
                      });

                      if (filtered.length) {
                        formik.setFieldValue('registrationAddressFiasLvl', filtered[0].data.fias_level);
                      }
                    }}
                    onBlur={() => {
                      setRegistrationAddressDetailsVisible(true);
                    }}
                  />
                </div>
              </Col>
            </Row>

            {registrationAddressDetailsVisible && (
              <React.Fragment>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <div className='field'>
                      <MaskedInput
                        name='registrationAddress.postalCode'
                        label='Почтовый индекс'
                        // placeholder="Введите почтовый индекс"
                        pattern='000000'
                        mask='pattern'
                        required={true}
                        requiredErrorText={
                          'При правильном заполнении адреса индекс заполняется автоматически. Проверьте указанный адрес'
                        }
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <div className='field'>
                      <MaskedInput
                        name='registrationAddress.regionName'
                        label='Регион'
                        // placeholder="Введите регион"
                        mask='cyrillic'
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className='field'>
                      <MaskedInput
                        name='registrationAddress.localityName'
                        label='Населенный пункт'
                        // placeholder="Введите населенный пункт"
                        mask='cyrillic'
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className='field'>
                      <MaskedInput
                        name='registrationAddress.streetName'
                        label='Улица'
                        // placeholder="Введите улицу"
                        mask='cyrillic'
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12} sm={6}>
                    <div className='field'>
                      <Input name='registrationAddress.houseNo' label='Дом' disabled={true} />
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className='field'>
                      <Input name='registrationAddress.blockNo' label='Корпус' disabled={true} />
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className='field'>
                      <Input name='registrationAddress.buildingNo' label='Строение' disabled={true} />
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className='field'>
                      <Input name='registrationAddress.apartmentNo' label='Квартира' disabled={true} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}

            <div className='fields'>
              <Checkbox
                className='simple headerless paddingless mt2'
                name='sameRealAddress'
                label='Адрес проживания совпадает с адресом регистрации'
              />
            </div>
            {realAddressVisible && (
              <Collapse
                className={`simple headerless paddingless ${classes.collapse}`}
                bordered={false}
                activeKey={realAddressVisible ? 1 : undefined}
              >
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Panel showArrow={false} header='' forceRender={true} key='1'>
                  <Divider />
                  <Row gutter={16}>
                    <Col xs={24} sm={24}>
                      <div className='field'>
                        <DadataInputAntd
                          type='address'
                          name='address.fullAddressText'
                          label='Фактический адрес проживания, с указанием дома и квартиры'
                          placeholder='Введите адрес проживания'
                          initialValue={get(props, 'initialValues.address.fullAddressText')}
                          required={!!realAddressVisible}
                          forceSuggestion={true}
                          onBlur={() => {
                            setRealAddressDetailsVisible(true);
                          }}
                          onInit={async (value, suggestions) => {
                            const initialFiasId = get(props, 'initialValues.address.fiasId');
                            const filtered = suggestions?.filter((suggestion) => {
                              return suggestion?.data?.fias_id === initialFiasId;
                            });

                            if (filtered.length) {
                              formik.setFieldValue('addressFiasLvl', filtered[0].data.fias_level);
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  {realAddressDetailsVisible && (
                    <React.Fragment>
                      <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <div className='field'>
                            <MaskedInput
                              name='address.postalCode'
                              label='Почтовый индекс'
                              pattern='000000'
                              mask='pattern'
                              required={true}
                              disabled={true}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <div className='field'>
                            <MaskedInput name='address.regionName' label='Регион' mask='cyrillic' disabled={true} />
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <div className='field'>
                            <MaskedInput
                              name='address.localityName'
                              label='Населенный пункт'
                              mask='cyrillic'
                              disabled={true}
                            />
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <div className='field'>
                            <MaskedInput name='address.streetName' label='Улица' mask='cyrillic' disabled={true} />
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={12} sm={6}>
                          <div className='field'>
                            <Input name='address.houseNo' label='Дом' disabled={true} />
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className='field'>
                            <Input name='address.blockNo' label='Корпус' disabled={true} />
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className='field'>
                            <Input name='address.buildingNo' label='Строение' disabled={true} />
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className='field'>
                            <Input name='address.apartmentNo' label='Квартира' disabled={true} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Panel>
              </Collapse>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};
