import * as React from 'react';
import { FormItem } from '../FormItem';
import { Field } from 'formik';
import { InputProps } from 'antd/lib/input';
import { Button, Upload as AntdUpload } from 'antd';
import { notify } from '../../../../utils/notify';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { MAX_DOWNLOAD_FILE_SIZE } from '../../../../config';
import { Icon } from '../../../../components/Icon';
import cls from 'classnames';
// import exifr from 'exifr';

interface IDraggerProps extends InputProps {
  className?: string;
  label?: string;
  placeholderImgUrl?: string;
  file?: File;
  compact?: boolean;
}

// eslint-disable-next-line react/display-name
export const Dragger = (props: IDraggerProps) => {
  const classes = useThemeStyles('Form/Dragger');

  const [inputValue, setInputValue] = React.useState<string | undefined>(undefined);
  const [imageUrl, setImageUrl] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [formik, setFormik] = React.useState<any>(undefined);

  const { file, placeholder = undefined, name } = props;

  const onLoadCallback = async (imageUrl, file) => {
    setLoading(false);
    await formik.setFieldValue(`__file_${name}`, file);
    await formik.setFieldValue(name, imageUrl);
    setImageUrl(imageUrl);
    setTimeout(() => {
      formik.setFieldTouched(name, true);
    }, 100);
  };

  React.useEffect(() => {
    if (file && formik) {
      getBase64({ originFileObj: file }, onLoadCallback);
    }
  }, [file, formik]);

  const validateRequired = (value) => {
    return !value ? 'required' : undefined;
  };

  const uploadButton = (
    <div>
      {props.placeholderImgUrl && (
        <img className={cls(classes.imagePlaceholder, props.compact ? 'compact' : '')} src={props.placeholderImgUrl} />
      )}
      <div className='ant-upload-text'>
        <Button shape='circle'>{loading ? <Icon type={'loading'} /> : <Icon type={'plus'} />}</Button> {placeholder}
      </div>
    </div>
  );

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, file));
    reader.readAsDataURL(file.originFileObj);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notify.error('Принимаются файлы в формате JPG/PNG!');
    }
    const lessThenSizeLimit = file.size / 1024 / 1024 < MAX_DOWNLOAD_FILE_SIZE;
    if (!lessThenSizeLimit) {
      notify.error(`Изображение не должно быть больше ${MAX_DOWNLOAD_FILE_SIZE}MB!`);
    }
    return isJpgOrPng && lessThenSizeLimit;
  };

  const deleteImage = (e, form) => {
    e.stopPropagation();
    setImageUrl('');
    form.setFieldValue(`__file_${props.name}`, undefined);
    form.setFieldValue(props.name, undefined);
  };

  return (
    <Field name={props.name} validate={props.required ? validateRequired : null}>
      {({ form, field, meta }) => {
        //setting an initial value
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          setInputValue(meta.initialValue);
          setFormik(form);
        }, []);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          if (field.value !== inputValue) {
            setInputValue(field.value);
          }
        }, [field.value]);
        return (
          <FormItem {...props} meta={meta} className={classes.uploadWrap} showIcon={false}>
            <AntdUpload.Dragger
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  (onSuccess as any)('ok');
                }, 0);
              }}
              id={field.name}
              name={field.name}
              listType='picture-card'
              className={cls(classes.upload, props.compact ? 'compact' : '')}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={async (info) => {
                setLoading(false);
                if (info.file.status === 'uploading') {
                  setLoading(true);
                  return;
                }
                if (info.file.status === 'done') {
                  getBase64(info.file, onLoadCallback);
                }
              }}
            >
              {imageUrl ? (
                <div className={classes.imageWrapper}>
                  <img src={imageUrl} style={{ maxWidth: '100%' }} />
                  <Button
                    shape='circle'
                    icon={<Icon type={'delete'} />}
                    className='delete-image-button'
                    onClick={(e) => deleteImage(e, form)}
                  />
                </div>
              ) : (
                uploadButton
              )}
            </AntdUpload.Dragger>
          </FormItem>
        );
      }}
    </Field>
  );
};
