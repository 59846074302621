import { ProcessFormsType } from '@brainysoft/lk-components';
import registration from './registration';
import loan_app from './loanApp';
import profile from './profile';
import signOffer from './signOffer';
import authForm from './auth';
import uploadPassport from './uploadPassport';
import uploadCardPhoto from './uploadCardPhoto';

export const Processes = {
  [ProcessFormsType.REGISTRATION]: registration,
  [ProcessFormsType.LOAN_APP]: loan_app,
  [ProcessFormsType.PROFILE]: profile,
  [ProcessFormsType.AUTH]: authForm,
  [ProcessFormsType.SIGN_OFFER]: signOffer,
  [ProcessFormsType.UPLOAD_PASSPORT]: uploadPassport,
  [ProcessFormsType.UPLOAD_CARD_PHOTO]: uploadCardPhoto,
};
