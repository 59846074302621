import * as React from 'react';
import { IProcessStepProps, PrintFormContextType } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { get } from 'lodash';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { PrintFormDocument } from '../../../components/PrintFormDocument/PrintFormDocument';
import { PrintFormType } from '@brainysoft/lk-components';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useTranslation } from '../../../utils/useTranslation';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { OtpChallengeType } from '@brainysoft/lk-components';
import { ProcessType } from '@brainysoft/lk-components';
import { Col, Row } from 'antd';
import { getCustomComponents } from '../../../utils/getCustomComponents';
import { useGoogleAnalytics } from 'apps/lk-next/src/integrations/useGoogleAnalytics';
import { useYandexMetrika } from 'apps/lk-next/src/integrations/useYandexMetrika';
import { Collapse, Panel } from '../../../components/Collapse';
import RightOutlined from '@ant-design/icons/RightOutlined';

export const RegisterFormLicenses: React.FC<IProcessStepProps> = (props) => {
  const classes = useThemeStyles('RegistrationPage');
  const consentsClasses = useThemeStyles('Consents');

  const { t } = useTranslation();
  const ChallengeApproval = getCustomComponents('ChallengeApproval');
  const { initialValues, ...rest } = props;

  delete initialValues?.documentsSignId;

  const [mobilePhone, setMobilePhone] = React.useState(initialValues.mobilePhone);
  const [isCollapseVisible, setIsCollapseVisible] = React.useState(false);

  React.useEffect(() => {
    props.setSubmitDisabled(true);
  }, []);

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const onUpdate = async (formik, changed) => {
    if (changed === undefined) {
      formik.setFieldValue('client_extraFields.assignmentConsent', true);
      formik.setFieldValue('client_extraFields.autoChargeOff', true);
      formik.setFieldValue('client_extraFields.ddoConsent', true);
    }
    if (typeof changed?.ddoConsentApproved === 'boolean')
      formik.setFieldValue('consentApproved', changed.ddoConsentApproved);
    const dataProcessingApproved = get(formik, 'values.dataProcessingApproved');
    const consentApproved = get(formik, 'values.consentApproved');
    const ddoConsentApproved = get(formik, 'values.ddoConsentApproved');
    const documentsSignId = get(formik, 'values.documentsSignId');
    const phoneCode = get(formik, 'values.phoneCode');
    props.setSubmitDisabled(!(dataProcessingApproved && consentApproved && ddoConsentApproved && !!documentsSignId));

    const mobilePhoneField = formik.values?.mobilePhone;
    if (mobilePhoneField && mobilePhoneField.length >= 11) {
      setMobilePhone(mobilePhoneField);
    }
    if (dataProcessingApproved && consentApproved && ddoConsentApproved && !!phoneCode && !!documentsSignId) {
      const values = formik.values;
      const submit = formik.submitForm;
      onSubmit({ values, submit });
    }
  };

  const onSubmit = async ({ values, submit }) => {
    ga.send('consent_sign_new');
    ym.send('consent_sign_new');
    await submit(values);
  };

  if (
    get(initialValues, 'dataProcessingApproved') === undefined ||
    get(initialValues, 'dataProcessingApproved') === null
  )
    initialValues['dataProcessingApproved'] = true;
  if (get(initialValues, 'consentApproved') === undefined || get(initialValues, 'consentApproved') === null)
    initialValues['consentApproved'] = true;

  if (get(initialValues, 'ddoConsentApproved') === undefined || get(initialValues, 'ddoConsentApproved') === null)
    initialValues['ddoConsentApproved'] = true;
  return (
    <ProcessStepForm
      {...rest}
      initialValues={initialValues}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      render={(formik) => {
        return (
          <>
            <h3>Подпишите документы и подтвердите мобильный телефон</h3>
            <p className={classes.textTemplate1}>
              Введя код вы подтверждаете, что ознакомились и предоставляете следующие согласия
            </p>
            <div className={classes.checkboxWrapper}>
              <Checkbox name='dataProcessingApproved' />
              <div className={classes.checkboxLabel}>
                <span>
                  {
                    <PrintFormDocument
                      contextType={PrintFormContextType.PROCESS}
                      printFormType={PrintFormType.DATA_PROCESSING}
                      name={t(`printForms:name.${PrintFormType.DATA_PROCESSING}`)}
                      type={'link'}
                      params={{
                        processType: ProcessType.REGISTRATION,
                        uuid: props.processUuid,
                        session: props.processSession,
                        variables: {
                          mobilePhone: mobilePhone,
                        },
                      }}
                    />
                  }
                </span>
              </div>
            </div>
            <div className={classes.checkboxWrapper}>
              <Checkbox name='ddoConsentApproved' />
              <div className={classes.checkboxLabel}>
                <span>
                  <PrintFormDocument
                    contextType={PrintFormContextType.PROCESS}
                    printFormType={PrintFormType.CONSENT_DDO}
                    name={t(`printForms:name.${PrintFormType.CONSENT_DDO}`)}
                    type={'link'}
                    params={{
                      processType: ProcessType.REGISTRATION,
                      uuid: props.processUuid,
                      session: props.processSession,
                      variables: {
                        mobilePhone: mobilePhone,
                      },
                    }}
                  />
                  &nbsp;и&nbsp;
                  <PrintFormDocument
                    contextType={PrintFormContextType.PROCESS}
                    printFormType={PrintFormType.CONSENT}
                    name={t(`printForms:name.${PrintFormType.CONSENT}`)}
                    type={'link'}
                    params={{
                      processType: ProcessType.REGISTRATION,
                      uuid: props.processUuid,
                      session: props.processSession,
                      variables: {
                        mobilePhone: mobilePhone,
                      },
                    }}
                  />
                </span>
              </div>
            </div>

            <Row className={'mt2'} align='middle'>
              <Col md={12} lg={6} className={classes.textTemplate1}>
                Вам поступит код на&nbsp;номер:
              </Col>
              <Col md={12} lg={6}>
                <MaskedInput
                  name='mobilePhone'
                  mask='mobilePhonePrefixed'
                  required={true}
                  shouldValidateOnChange={true}
                />
              </Col>
            </Row>
            <Row className={'mb2'}>
              <Col md={24} lg={12} className={classes.textTemplate1}>
                Этот номер будет являться вашим логином для входа в личный кабинет. Если указан неверный номер телефона,
                просто измените его.
              </Col>
            </Row>

            <div className={classes.codeApprovalWrapper}>
              <ChallengeApproval
                formik={formik}
                idField={'documentsSignId'}
                approveField={'documentsApproved'}
                otpChallengeType={OtpChallengeType.DOCUMENTS_SIGN}
                mobilePhone={formik.values?.mobilePhone}
                horizontal={false}
                challengeButtonSize={'normal'}
              />
            </div>

            <div className={classes.textTemplate1}>{t('processes:registration.pfDisclaimer')}</div>
            {!(
              get(props.initialValues, 'client_extraFields.assignmentConsent') &&
              get(props.initialValues, 'client_extraFields.ddoConsent') &&
              get(props.initialValues, 'client_extraFields.autoChargeOff')
            ) && (
              <>
                <div className={classes.acceptAllWrapper}>
                  <div className={classes.checkboxWrapper}>
                    <div className={classes.textTemplate1} onClick={() => setIsCollapseVisible(!isCollapseVisible)}>
                      Документы для регистрации:
                    </div>
                  </div>
                  <span
                    className={`${classes.acceptAllArrow} ${isCollapseVisible ? 'active' : ''}`}
                    onClick={() => setIsCollapseVisible(!isCollapseVisible)}
                  >
                    <RightOutlined />
                  </span>
                </div>

                <Collapse
                  className={`simple borderless paddingless ${classes.generalApproveWrapper} horizontal`}
                  bordered={false}
                  activeKey={isCollapseVisible ? 1 : undefined}
                >
                  <Panel showArrow={false} header='' forceRender={true} key='1'>
                    <div className={consentsClasses.consentsWrapper}>
                      <div className={classes.checkboxWrapper}>
                        <Checkbox name='client_extraFields.assignmentConsent' />
                        <div className={classes.checkboxLabel}>
                          <span>
                            Предоставляю заранее данный акцепт на списание денежных средств по Договору потребительского
                            займа
                          </span>
                        </div>
                      </div>

                      <div className={classes.checkboxWrapper}>
                        <Checkbox name='client_extraFields.autoChargeOff' />
                        <div className={classes.checkboxLabel}>
                          <span>
                            Предоставляю согласие на уступку кредитором третьим лицам прав (требований) по договору
                            займа
                          </span>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </>
            )}
          </>
        );
      }}
    />
  );
};
