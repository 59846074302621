import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Button } from '../../../components/Button';
import { ResultIcon } from '../../../components/ResultIcon';
import { ResultType } from '@brainysoft/lk-components';
import { Divider } from 'antd';
import { useRouter } from 'next/router';
// eslint-disable-next-line no-unused-vars
import { CreditT } from '@brainysoft/lk-components';
import { useQueryClient } from 'react-query';
import { refreshActiveCredit } from '../../../queries/useActiveCredit';

interface IProps {
  credit: CreditT;
  dispatch: (action) => void;
}

export const UploadPassportResult: React.FC<IProps> = () => {
  const classes = useThemeStyles('ActiveCreditPage');
  const router = useRouter();
  const queryClient = useQueryClient();

  const iconSize = 80;
  const wrapper = `${classes.wrapper}`;

  const onClick = async () => {
    refreshActiveCredit(queryClient);
    router.push('/');
  };

  return (
    <React.Fragment>
      <div className={wrapper}>
        <h2 className={classes.header}>Ваши фотографии загружены!</h2>
        <ResultIcon result={ResultType.SUCCESS} size={iconSize} />
        <div className={classes.descriptor}>
          <p>Ожидайте рассмотрения специалистом</p>
        </div>
      </div>
      <Divider />

      <div className={classes.activeCreditButtonWrapper}>
        <Button size={'large'} type={'primary'} onClick={onClick}>
          На главную
        </Button>
      </div>
    </React.Fragment>
  );
};
