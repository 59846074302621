import * as React from 'react';
import { IProcessStepProps } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { Col, Row } from 'antd';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { DatePicker } from '../../../packages/Formik/Components/DatePicker';
import { useUnemployed } from '../../../utils/employmentUtils';
import dayjs from 'dayjs';

export const RegisterFormIncome: React.FC<IProcessStepProps> = (props) => {
  const { initialValues } = props;

  const isUnemployed = useUnemployed();
  const employmentFieldValue = initialValues?.workPlaceData?.employmentTypeId;

  const unemployed = isUnemployed(employmentFieldValue);

  React.useEffect(() => {
    props.setSubmitDisabled(false);
  }, []);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  }

  const onSubmit = async ({ values, submit }) => {
    props.setSubmitDisabled(true);
    await submit(values);
  };

  if (initialValues.loanPaymentExpense === undefined || initialValues.loanPaymentExpense === null)
    initialValues.loanPaymentExpense = 0;

  return (
    <ProcessStepForm
      {...props}
      initialValues={{
        ...initialValues,
        // чтобы работала валидация formik, нужно принудительно проставить ключ meanIncome даже если значения нет в данных процесса
        meanIncome: initialValues?.meanIncome,
      }}
      onSubmit={onSubmit}
      render={() => {
        return (
          <React.Fragment>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <div className='field'>
                  <MaskedInput
                    name='meanIncome'
                    mask={unemployed ? 'income' : 'incomeRequired'}
                    label='Доход на руки'
                    placeholder='Введите доход на руки'
                    required={!unemployed}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className='field'>
                  <DatePicker
                    disabledDate={disabledDate}
                    name='workPlaceData.nextPayDay'
                    label='Дата следующей зарплаты'
                    placeholder='Дата следующей зарплаты в формате ДД.ММ.ГГГГ'
                    outgoingFormat='YYYY-MM-DD'
                    required={!unemployed}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className='field'>
                  <MaskedInput
                    name='loanPaymentExpense'
                    mask='income5chars'
                    label='Ежемесячные оплаты по кредитам и займам'
                    placeholder='Ежемесячные оплаты по Вашим кредитам и займам'
                    required={true}
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        );
      }}
    />
  );
};
