import dayjs from 'dayjs';

export const passportIssueDateValidator = (value: string, birthDate) => {
  const before14 = dayjs(birthDate).add(14, 'years').add(1, 'day').isAfter(value);
  const inFuture = dayjs().isBefore(value);
  if (inFuture || before14) return 'passport_issue_wrong_date_error';

  if (dayjs(birthDate).add(20, 'years').add(90, 'days').isSameOrAfter(dayjs())) {
    //возраст меньше 20 лет + 90 дней включительно -> дата выдачи паспорта >= дата рождения + 14 лет + 1 день
    return dayjs(birthDate).add(14, 'years').add(1, 'day').isAfter(value) ? 'passport_issue_date_error' : null;
  }

  if (
    dayjs(birthDate).add(20, 'years').add(90, 'days').isBefore(dayjs()) &&
    dayjs(birthDate).add(45, 'years').add(90, 'days').isSameOrAfter(dayjs())
  ) {
    //возраст больше 20 лет + 90 дней и меньше 45 лет + 90 дней включительно
    // -> дата выдачи паспорта >= дата рождения + 20 лет + 1 день
    return dayjs(birthDate).add(20, 'years').add(1, 'day').isAfter(value) ? 'passport_issue_date_error' : null;
  }

  if (dayjs(birthDate).add(45, 'years').add(90, 'days').isBefore(dayjs())) {
    //возраст больше 45 лет + 90 дней включительно -> дата выдачи паспорта >= дата рождения + 45 лет + 1 день
    return dayjs(birthDate).add(45, 'years').add(1, 'day').isAfter(value) ? 'passport_issue_date_error' : null;
  }
};

export const passportIssueDateValidatorSimple = (value: string, birthDate) => {
  const before14 = dayjs(birthDate).add(14, 'years').add(1, 'day').isAfter(value);
  const inFuture = dayjs().isBefore(value);
  if (inFuture || before14) return 'passport_issue_wrong_date_error';
};

export const passportIssueDisabledDates = (current, birthDate) => {
  const now = dayjs();
  const _birthDate = dayjs(birthDate);
  const before14 = _birthDate.add(14, 'years').isAfter(current);
  const inFuture = now.isBefore(current);

  return inFuture || before14;
};
