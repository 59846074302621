import * as React from 'react';
import { authService, ExternalAuthProviders } from '@brainysoft/lk-components';
import { Dimmer } from '../Dimmer';
import { LoadingSpinner } from '../LoadingSpinner';
import { GosuslugiLogo } from './GosuslugiLogo';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useTranslation } from '../../utils/useTranslation';
import { useExternalAuthProvider } from '../../utils/useExternalAuthProvider';

interface ExternalAuthGosuslugiProps {
  returnUrl?: string;
  preprocess?: (...args: unknown[]) => Promise<unknown>;
  className?: string;
  setLoading?: (value?: boolean) => void;
  isShowLabel?: boolean;
}

export const ExternalAuthGosuslugi: React.FC<ExternalAuthGosuslugiProps> = (props) => {
  const classes = useThemeStyles('ExternalAuth');
  const { t, keyExists } = useTranslation();

  const { returnUrl, preprocess = async () => {}, className, setLoading = () => {}, isShowLabel = true } = props;
  const provider = useExternalAuthProvider();

  const _returnUrl = returnUrl || window.location.origin + '/auth/external-auth-result';

  const [isExternalAuthLoading, setIsExternalAuthLoading] = React.useState(false);
  if (!provider) return <></>;
  if (!_returnUrl) return <></>;

  const initExternalAuth = async () => {
    setIsExternalAuthLoading(true);
    setLoading(true);

    if (typeof preprocess === 'function') await preprocess();

    const res = await authService.initExternalAuth({
      provider: provider as ExternalAuthProviders,
      returnUrl: _returnUrl,
    });

    if (res?.redirectUrl) {
      window.location.href = res.redirectUrl;
    } else {
      setIsExternalAuthLoading(false);
      setLoading(false);
    }
  };

  return (
    <>
      {isShowLabel && <div className={classes.gosuslugiLabel}>{t('externalAuth:label')}</div>}
      <div
        className={`${classes.externalAuthWrapper} ${className ?? ''}`}
        onClick={async () => {
          if (!isExternalAuthLoading) await initExternalAuth();
        }}
      >
        {isExternalAuthLoading && (
          <Dimmer active={true}>
            <LoadingSpinner size={8} margin={4} />
          </Dimmer>
        )}
        <div className={classes.gosuslugiContent}>
          <span className={classes.gosuslugiLogo}>
            <GosuslugiLogo />
          </span>
          <div className={classes.gosuslugiTitle}>
            {t('externalAuth:description')}
            {keyExists('externalAuth:descriptionBold') === true && <strong>{t('externalAuth:descriptionBold')}</strong>}
          </div>
        </div>
      </div>
    </>
  );
};
