import * as React from 'react';
import { get } from 'lodash';
import { IProcessStepProps } from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { Input } from '../../../packages/Formik/Components/Input';
import { Col, Row } from 'antd';
import { DictionaryType } from '@brainysoft/lk-components';
import { Select } from '../../../packages/Formik/Components/Select';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { MatrialStatusTypeToOptions } from '@brainysoft/lk-components';
import { useDictionary } from '../../../queries/useDictionary';
import { useUnemployed } from '../../../utils/employmentUtils';

export const RegisterFormWorkplace: React.FC<IProcessStepProps> = (props) => {
  const isUnemployed = useUnemployed();
  const employmentTypeId = props.initialValues?.workPlaceData?.employmentTypeId;
  const [hidden, setHidden] = React.useState(isUnemployed(employmentTypeId));

  const maritalStatus = MatrialStatusTypeToOptions();

  const educationQuery = useDictionary(DictionaryType.EDUCATION);
  const employmentTypeQuery = useDictionary(DictionaryType.EMPLOYMENT_TYPE);

  const education = educationQuery.data ?? [];
  const employmentType = employmentTypeQuery.data ?? [];

  React.useEffect(() => {
    props.setSubmitDisabled(false);
  }, []);

  const onUpdate = async (formik) => {
    const employmentTypeId = get(formik, 'values.workPlaceData.employmentTypeId');
    const unemployed = isUnemployed(employmentTypeId);
    setHidden(unemployed);
    if (unemployed) formik.setFieldValue('workPlaceData.workPlace', undefined);
  };

  // const onSubmit = async ({ values, submit }) => {
  //     await submit(values);
  // };

  return (
    <ProcessStepForm
      {...props}
      onUpdate={onUpdate}
      // onSubmit={onSubmit}
      render={() => {
        return (
          <React.Fragment>
            <Input name='businessAddressDataFiasLvl' hidden={true} />
            <Input name='businessAddressData.fiasId' hidden={true} />

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <div className='field'>
                  <Select
                    name='educationId'
                    label='Образование'
                    placeholder='Ваше образование'
                    options={education.map((item) => {
                      return {
                        value: item.id,
                        option: item.name,
                      };
                    })}
                    required={true}
                  />
                </div>
              </Col>
              <Select
                name='workPlaceData.employmentTypeId'
                label='Тип занятости'
                placeholder='Тип Вашей занятости'
                options={employmentType.map((item) => {
                  return {
                    value: item.id,
                    option: item.name,
                  };
                })}
                required={true}
                wrapInColumn={true}
                columnProps={{ xs: 24, sm: 12 }}
              />
              <Input
                name='workPlaceData.workPlace'
                label='Название места работы'
                placeholder='Введите название места работы'
                hidden={hidden}
                wrapInColumn={true}
                columnProps={{ xs: 24, sm: 12 }}
                required={!hidden}
              />
              <Col xs={24} sm={12}>
                <div className='field'>
                  <Select
                    name='maritalStatusId'
                    label='Семейное положение'
                    placeholder='Ваше семейное положение'
                    options={maritalStatus.map((item) => {
                      return {
                        value: item.id,
                        option: item.name,
                      };
                    })}
                    required={true}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className='field'>
                  <MaskedInput
                    name='dependents'
                    mask='children'
                    label='Количество детей на содержании'
                    placeholder='Введите количество детей на содержании'
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        );
      }}
    />
  );
};
