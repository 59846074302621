import * as React from 'react';
import { Col, Row } from 'antd';
import { useThemeStyles } from './useThemeStyles';
// eslint-disable-next-line no-unused-vars
import { ReactNode } from 'react';

interface IStaticRow {
  label: string;
  value?: string | number | ReactNode;
}

export const StaticRow: React.FC<IStaticRow> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { label, value } = props;

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8}>
        <div className={classes.staticLabel}>{label}:</div>
      </Col>
      <Col xs={24} sm={24} md={16}>
        <div className={classes.staticValue}>
          {(typeof value === 'string' && value !== '') || typeof value === 'number' || value ? value : '-'}
        </div>
      </Col>
    </Row>
  );
};
