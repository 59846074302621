import React, { FC, useCallback } from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { FormItem } from '../FormItem';
import { Field } from 'formik';
import { SelectProps } from 'antd/lib/select';
import { DADATA_TOKEN } from '../../../../config';
import { DatataSuggestionType, ReactDatataComponent } from './dadata';
import { FieldInputProps, FieldMetaProps, FormikProps } from 'formik/dist/types';
import { getInputMask, Masks } from '../MaskedInput/masks';

interface IDadataInputProps extends SelectProps<any> {
  className?: string;
  name: string;
  size?: 'small' | 'large';
  label?: string;
  options?: Array<any>;
  required?: boolean;
  onChange?: any;
  onBlur?: any;
  onInit?: any;
  hidden?: boolean;
  type: DatataSuggestionType;
  initialValue?: string;
  placeholder?: string;
  mapDataField?: string;
  payloadModifier?: any;
  forceSuggestion?: boolean;
  mask?: Masks | ((value: string) => string);
  validateStatus?: '' | 'success' | 'warning' | 'error' | 'validating';
}

export const DadataInputAntd = (props: IDadataInputProps) => {
  const validateRequired = (value) => {
    //
    // if (props.forceRequiredParts && props.forceRequiredParts.length && value) {
    //   const valid = props.forceRequiredParts.reduce((sum, fieldName) => {
    //     return sum && !!suggestion?.data[fieldName];
    //   }, true);
    //   console.log('validation', props.forceRequiredParts, suggestion, value, valid)
    //   return !valid ? props.forceRequiredPartsError ?? 'required' : undefined;
    // }

    // if (typeof props.mask === 'string' && typeof maskValidator === 'function') {
    if (typeof props.mask === 'string') {
      const { maskValidator }: any = getInputMask(props.mask);
      if (typeof maskValidator === 'function') {
        const res = maskValidator(value);
        if (res) return res;
      }
    }
    return !value ? 'required' : undefined;
  };

  return (
    <>
      <Field name={props.name} validate={props.required ? validateRequired : null}>
        {({ form, field, meta }) => {
          return <_DadataField form={form} field={field} meta={meta} {...props} />;
        }}
      </Field>
    </>
  );
};

interface IFieldProps extends IDadataInputProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  meta: FieldMetaProps<any>;
}

const _DadataField: FC<IFieldProps> = (props) => {
  const classes = useThemeStyles('Form/DadataInputAntd');

  const { form, field, meta, mask, ...fieldProps } = props;

  const onInit = useCallback(async (value, suggestions) => {
    if (typeof fieldProps.onInit === 'function') fieldProps.onInit(value, suggestions);
  }, []);

  const onChange = useCallback(async (value, suggestion) => {
    // const value = props.mapDataField ? suggestion?.data[props.mapDataField] : suggestion.value;
    if (suggestion) {
      await form.setFieldValue(field.name, value);
      await form.setFieldValue(`_suggestion_${field.name}`, suggestion);
      if (typeof fieldProps.onChange === 'function') fieldProps.onChange(suggestion);
    }
  }, []);

  const onBlur = useCallback(async (value, suggestion) => {
    // если включен принудительный выбор подсказки -
    // обновляем значение поля в форме значением из подсказки (только если она есть)
    // если подсказка не выбрана - сбрасываем значение
    if (fieldProps.forceSuggestion) {
      if (suggestion) {
        await form.setFieldValue(field.name, suggestion?.value);
      } else {
        await form.setFieldValue(field.name, undefined);
      }
      await form.setFieldTouched(field.name, true);
    }
    // если не включен принудительный выбор подсказки -
    // обновляем значение поля в форме введенной строкой даже если подсказка не выбрана
    if (!fieldProps.forceSuggestion) {
      await form.setFieldValue(field.name, value);
      await form.setFieldTouched(field.name, true);
    }
    if (suggestion) {
      await form.setFieldValue(`_suggestion_${field.name}`, suggestion);
      if (typeof props.onBlur === 'function') props.onBlur(suggestion);
    }
  }, []);

  return (
    <FormItem {...fieldProps} meta={meta}>
      <ReactDatataComponent
        id={field.name}
        meta={meta}
        name={field.name}
        validateStatus={props.validateStatus}
        token={DADATA_TOKEN}
        showNote={false}
        type={props.type}
        className={classes.input}
        placeholder={props.placeholder}
        initialValue={props.initialValue}
        mapDataField={props.mapDataField}
        payloadModifier={props.payloadModifier}
        forceSuggestion={props.forceSuggestion}
        disabled={props.disabled}
        mask={props.mask}
        query={field.value}
        onChange={onChange}
        onBlur={onBlur}
        onInit={onInit}
      />
    </FormItem>
  );
};
