import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { Modal as AntdModal } from 'antd';
// eslint-disable-next-line no-unused-vars
import { ModalProps } from 'antd/es/modal';

export const Modal: React.FC<ModalProps> = (props) => {
  const classes = useThemeStyles('Modal');

  return (
    <AntdModal
      {...props}
      title={props.title ? <h4 className={'title'}>{props.title}</h4> : null}
      centered
      open={props.open}
      onOk={props.onOk}
      onCancel={props.onCancel}
      className={classes.antdModal}
      footer={props.footer ? props.footer : null}
    >
      {props.children}
    </AntdModal>
  );
};
