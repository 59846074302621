import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { messagesService, UpdateSubscriptions } from '@brainysoft/lk-components';

export const useSubscriptions = () => {
  const [subscriptions, setSubscriptions] = React.useState({
    marketing: {
      sms: false,
      email: false,
    },
    operational: {
      sms: false,
      email: false,
    },
  });
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchSubscriptions = async (canceled) => {
    const res = await messagesService.getSubscriptions();
    if (!canceled && Array.isArray(res)) {
      const subscriptions = parseResult(res);
      setSubscriptions(subscriptions);
      setIsLoading(false);
    }
    return;
  };

  const updateSubscriptions = async (data: UpdateSubscriptions) => {
    setIsLoading(true);
    const subscriptionsDTO = {
      'marketing.sms': data.marketing?.sms,
      'marketing.email': data.marketing?.email,
      'operational.sms': data.operational?.sms,
      'operational.email': data.operational?.email,
    };
    await messagesService.updateSubscriptions(subscriptionsDTO);
    const res = await messagesService.getSubscriptions();
    const subscriptions = parseResult(res);
    setSubscriptions(subscriptions);
    setIsLoading(false);
    return;
  };

  React.useEffect(() => {
    let canceled = false;
    setIsLoading(true);
    fetchSubscriptions(canceled);
    return () => {
      canceled = true;
    };
  }, []);

  return {
    isLoading,
    subscriptions,
    updateSubscriptions,
  };
};

const parseResult = (res) => {
  return {
    marketing: {
      sms: res.find((item) => item.code === 'marketing.sms')?.value ?? false,
      email: res.find((item) => item.code === 'marketing.email')?.value ?? false,
    },
    operational: {
      sms: res.find((item) => item.code === 'operational.sms')?.value ?? false,
      email: res.find((item) => item.code === 'operational.email')?.value ?? false,
    },
  };
};
