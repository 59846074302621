import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';

// eslint-disable-next-line no-unused-vars
import { ClientT } from '@brainysoft/lk-components';
import { DictionaryType } from '@brainysoft/lk-components';
import { MatrialStatusTypeToOptions } from '@brainysoft/lk-components';
import { StaticRow } from '../../../utils/StaticRow';
import { get } from 'lodash';
import { Divider } from 'antd';
import { useDictionary } from '../../../queries/useDictionary';

interface IProps {
  client: ClientT;
}

export const ProfileFormAdditionalStatic: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { client } = props;

  const educationQuery = useDictionary(DictionaryType.EDUCATION);
  const employmentTypeQuery = useDictionary(DictionaryType.EMPLOYMENT_TYPE);
  const activityTypeQuery = useDictionary(DictionaryType.ACTIVITY_TYPE);
  const relativeTypeQuery = useDictionary(DictionaryType.RELATIVE_TYPE);

  const education = educationQuery.data ?? [];
  const employmentType = employmentTypeQuery.data ?? [];
  const activityType = activityTypeQuery.data ?? [];
  const relativeType = relativeTypeQuery.data ?? [];

  const maritalStatus = MatrialStatusTypeToOptions();

  const getDictValue = (value, dictionary: any[], attributeName = 'id') => {
    const found = dictionary?.find((item) => item[attributeName] == value);
    return found ? found.name : undefined;
  };

  return (
    <div className={classes.static}>
      <StaticRow label={'Образование'} value={getDictValue(client.educationId, education)} />
      <StaticRow
        label={'Тип занятости'}
        value={getDictValue(get(client, 'workPlaceData.employmentTypeId'), employmentType)}
      />
      <StaticRow
        label={'Сфера занятости'}
        value={getDictValue(get(client, 'workPlaceData.activityTypeId'), activityType)}
      />
      <StaticRow label={'Название места работы'} value={get(client, 'workPlaceData.workPlace')} />
      <StaticRow label={'Семейное положение'} value={getDictValue(client.maritalStatusId, maritalStatus)} />
      <StaticRow label={'Количество детей на содержании'} value={client.dependents ? String(client.dependents) : 0} />
      <Divider />
      <StaticRow label={'Расходы на жилье в месяц'} value={client.averageMonthlyCost ?? 0} />
      <StaticRow label={'Ежемесячные оплаты по кредитам и займам'} value={client.loanPaymentExpense ?? 0} />
      <Divider />
      {(client.personRelatives ?? []).map((relative, index) => {
        return (
          <div key={index}>
            <StaticRow
              label={'Дополнительное контактное лицо'}
              value={getDictValue(get(client, `personRelatives.${index}.collateralRelId`), relativeType)}
            />
            <StaticRow
              label={'Телефон дополнительного контактного лица'}
              value={client.personRelatives[index]?.telephone ? String(client.personRelatives[index]?.telephone) : 0}
            />
          </div>
        );
      })}
    </div>
  );
};
