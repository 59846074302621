import { CardT, paymentsService } from '@brainysoft/lk-components';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { QueryClient } from 'react-query/core';

// export const CARD_CACHE_KEY = 'card:useCache';

export const useCard: () => UseQueryResult<CardT> = () => {
  return useQuery(
    ['cards'],
    () => {
      return paymentsService.getCard();
    },
    {
      staleTime: 3000,
    }
  );
};

export const refreshCard = (queryClient: QueryClient) => {
  queryClient.invalidateQueries('cards');
};
