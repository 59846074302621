import { get } from 'lodash';
import { creditService, PhotoType, StatefulProcessType } from '@brainysoft/lk-components';
import { notify } from './notify';
import { FileSettingsT } from '../processForms/monedo/registration/RegisterFormPhotoUploader';
import { useState } from 'react';

type UseUploadInteractionFilesProps<T = Record<string, any>> = (props: {
  creditUuid: string;
  processType: StatefulProcessType;
  filesSettings: FileSettingsT[];
}) => {
  isUploading: boolean;
  uploadFormFiles: (
    values: T,
    callbacks: {
      onSuccess?: (res: Record<PhotoType, boolean>) => Promise<void>;
      onError?: (res: Record<PhotoType, boolean>) => Promise<void>;
    }
  ) => Promise<Record<PhotoType, boolean>>;
};

export const useUploadInteractionFiles: UseUploadInteractionFilesProps = ({
  creditUuid,
  processType,
  filesSettings,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const uploadFormFiles = async (values, { onSuccess, onError }) => {
    setIsUploading(true);

    const files: any[] = [];
    const uploadedFiles = {} as Record<PhotoType, boolean>;
    let allRequiredFilesAttached = true;
    let allFilesUploaded = true;

    // для каждого файла создаем FormData для отправки
    for (const item of filesSettings) {
      const file = new FormData();
      const fileValue = get(values, `__file_${item.name}`);
      file.append('file', fileValue?.originFileObj);
      file.append('code', item.code);
      files.push(file);
      if (item?.required) {
        allRequiredFilesAttached = allRequiredFilesAttached && !!fileValue;
      }
    }

    // все ли обязательные файлы прикреплены?
    if (!allRequiredFilesAttached) {
      console.error('No valid file for required field');
      if (typeof onError === 'function') await onError(uploadedFiles);
      return;
    }

    // загружаем файлы параллельно
    const promises = [] as Promise<any>[];
    for (const file of files) {
      promises.push(creditService.uploadProcessFile(creditUuid, processType, file));
    }
    const responses = await Promise.all(promises);

    // разбираем ответ
    files.forEach((file, index) => {
      const code = file.get('code');
      const response = responses[index];
      const isFileUploaded = response && Array.isArray(response);
      uploadedFiles[code] = isFileUploaded;
      allFilesUploaded = allFilesUploaded && !!isFileUploaded;
    });

    if (allFilesUploaded) {
      if (typeof onSuccess === 'function') await onSuccess(uploadedFiles);
    } else {
      console.error(
        'File(s) upload failed: ',
        Object.keys(uploadedFiles)
          .filter((code) => !uploadedFiles[code])
          .join(', ')
      );
      notify.error('Не удалось загрузить файлы');

      if (typeof onError === 'function') await onError(uploadedFiles);
    }

    setIsUploading(false);
    return uploadedFiles;
  };

  return { isUploading, uploadFormFiles };
};
