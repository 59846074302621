import { UploadPassportIntro } from '../../default/uploadPassport/UploadPassportIntro';
import { UploadCardPhotoProcess } from './UploadCardPhotoProcess';
import { UploadPassportResult } from '../../default/uploadPassport/UploadPassportResult';

const RENDERER_MAP = {
  intro: UploadPassportIntro,
  uploadCardPhoto: UploadCardPhotoProcess,
  end: UploadPassportResult,
};

export default { RENDERER_MAP };
