import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useRouter } from 'next/router';
// eslint-disable-next-line no-unused-vars
import { IProcessStepProps, ProcessType, ResultType } from '@brainysoft/lk-components';
import { useQueryClient } from 'react-query';
import { resetCredits } from '../../../queries/useCredits';
import { resetActiveCredit } from '../../../queries/useActiveCredit';
import { ResultIcon } from '../../../components/ResultIcon';
import { Button } from '../../../components/Button';
import { ProcessStorageService } from '../../../processes/ProcessStorage';

export const LoanAppComplete: React.FC<IProcessStepProps> = () => {
  const classes = useThemeStyles('LoanAppCreatePage');
  const router = useRouter();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    resetCredits(queryClient);
    resetActiveCredit(queryClient);
    ProcessStorageService.resetAll(ProcessType.LOAN_APP);
  }, []);

  const handleClick = () => {
    router.push('/');
  };

  return (
    <div className={`${classes.success} ${classes.formWrapper}`}>
      <h2 className={`${classes.header} mb2`}>Заявка создана!</h2>
      <div className={classes.completedTextWrapper}>
        <p>
          Благодарим Вас за предоставленные сведения и терпение при заполнении заявки. Уверены, что это поможет одобрить
          Вашу заявку значительно быстрее.
        </p>
        <p>
          Мы будем держать Вас в курсе процесса рассмотрения! Статус одобрения заявки можно посмотреть на главной
          странице
        </p>
      </div>
      <ResultIcon result={ResultType.SUCCESS} size={80} />
      <div className={'flex-center'}>
        <Button className='green mt2' size={'large'} shape={'round'} type={'primary'} onClick={handleClick}>
          Посмотреть статус
        </Button>
      </div>
    </div>
  );
};
