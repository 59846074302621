import * as React from 'react';
import { NODE_ENV_PRODUCTION, TENANT_DOMAIN, INTEGRATIONS } from '../config';
import { ProcessSaveDTO } from '@brainysoft/lk-components';

export const AdSpire: React.FC = (props) => {
  const token = INTEGRATIONS['adspire'] ?? null;

  if (!token) return <></>;
  if (!NODE_ENV_PRODUCTION) return <></>;

  console.log('AdSpire initialised');

  return <script type='text/javascript' src={`//track.adspire.io/code/${token}`} defer />;
};

export const adSpireCallback = (saveData: ProcessSaveDTO) => {
  const token = INTEGRATIONS['adspire'] ?? null;

  if (!token) return;
  if (!NODE_ENV_PRODUCTION) return;

  (window as any).adspire_track = (window as any).adspire_track || [];
  (window as any).adspire_track.push({
    TypeOfPage: 'event',
    Event: {
      type: 'lead',
      id: saveData.data?.leadId,
    },
  });
};
