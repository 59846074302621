import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { SurveyT } from '@brainysoft/lk-components';
import { useSurveyCustomComponent } from '../../utils/useSurveyCustomComponent';
import { SurveyFormProps } from './SurveyForm';
import { useSlotSurveys } from '../../utils/useSlotSurveys';

interface ProcessSurveysProps {
  slot: string;
}

export const ProcessSurveys: React.FC<ProcessSurveysProps> = (props) => {
  const classes = useThemeStyles('Survey');

  const { slot } = props;

  const surveys = useSlotSurveys(slot);

  if (!surveys.length) return <></>;

  return (
    <div className={classes.processSurveysWrapper}>
      {surveys.map((survey, index) => {
        return <_SurveyForm survey={survey} slot={slot} key={index} />;
      })}
    </div>
  );
};

const _SurveyForm: React.FC<SurveyFormProps> = (props) => {
  const { survey } = props;
  const SurveyForm = useSurveyCustomComponent<SurveyFormProps>(survey.name);
  return <SurveyForm {...props} />;
};
