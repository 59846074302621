import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { LoadingSpinner } from '../LoadingSpinner';

export const CardPending: React.FC = () => {
  const classes = useThemeStyles('Card');

  return (
    <React.Fragment>
      <div className={`${classes.card} 'pending'}`}>
        <div className='loadingWrapper'>
          <LoadingSpinner size={8} margin={4} className={'grey'} />
          <div className={classes.pendingWrapper}>Ожидаем ответ от платежной системы</div>
        </div>
      </div>
    </React.Fragment>
  );
};
