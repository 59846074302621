import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { ResultType } from '@brainysoft/lk-components';
import { kycService } from '@brainysoft/lk-components';
import { get } from 'lodash';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { ResultIcon } from '../../../components/ResultIcon';

export const SmevIdentificationResult: React.FC<any> = (props) => {
  const classes = useThemeStyles('LoanAppCreatePage');

  const [smevInit, setSmevInit] = React.useState<ResultType>(ResultType.WAITING);

  const leadId = get(props, 'initialValues.leadId');
  const smevPhoneValidated = get(props, 'initialValues.smevPhoneValidated');

  React.useEffect(() => {
    props.setSubmitDisabled(true);
    props.setIsFormLoading(true);
    const getResult = async () => {
      if (smevPhoneValidated) {
        setSmevInit(ResultType.SUCCESS);
        props.setSubmitDisabled(false);
      } else {
        try {
          const res = await kycService.smevInit(leadId);
          if (res) {
            setSmevInit(ResultType.SUCCESS);
            props.setSubmitDisabled(false);
          } else {
            setSmevInit(ResultType.FAIL);
            console.log('error', res);
          }
        } catch (error) {
          setSmevInit(ResultType.FAIL);
          console.log('error', error);
        }
      }
    };
    getResult();
    props.setIsFormLoading(false);
  }, []);

  return (
    <ProcessStepForm
      {...props}
      render={() => {
        return (
          <React.Fragment>
            {smevInit == ResultType.SUCCESS && (
              <div className={classes.formWrapper}>
                <div className={classes.smevResultWrapper}>
                  <h3 className={`${classes.header} headerSuccess`}>Запрос на верификацию отправлен!</h3>
                  <ResultIcon result={smevInit} size={80} />
                  <p className={classes.smevResultInfo}>Можно продолжить процесс подачи заявки</p>
                </div>
              </div>
            )}
            {smevInit == ResultType.WAITING && (
              <div className={classes.formWrapper}>
                <div className={classes.smevResultWrapper}>
                  <h3 className={`${classes.header}`}>Подождите, отправляется запрос на верификацию</h3>
                  <ResultIcon result={smevInit} size={80} />
                  <p className={classes.smevResultInfo}>
                    После получения результатов верификации можно будет продолжить процесс подачи заявки
                  </p>
                </div>
              </div>
            )}
            {smevInit == ResultType.FAIL && (
              <div className={classes.formWrapper}>
                <div className={classes.smevResultWrapper}>
                  <h3 className={`${classes.header}`}>Верификация не пройдена</h3>
                  <ResultIcon result={smevInit} size={80} />
                  <p className={classes.smevResultInfo}>Возможно вы ошибочно ввели СНИЛС при регистрации</p>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};
