import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { ClientT, DictionaryType } from '@brainysoft/lk-components';
import { StaticRow } from '../../../utils/StaticRow';
import { useDictionary } from '../../../queries/useDictionary';
import { getStaticDictValue } from '../../../utils/getStaticDictValue';
import dayjs from 'dayjs';

interface IProps {
  client: ClientT;
}

export const ProfileFormPassportStatic: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { client } = props;

  const countriesQuery = useDictionary(DictionaryType.COUNTRIES);
  const countries = countriesQuery.data ?? [];

  return (
    <div className={classes.static}>
      <StaticRow label={'Серия'} value={client.passport?.seria} />
      <StaticRow label={'Номер'} value={client.passport?.no} />
      <StaticRow label={'Дата выдачи'} value={dayjs(client.passport?.issueDate).format('DD.MM.YYYY г.')} />
      <StaticRow label={'Код подразделения'} value={client.passport?.subdivisionCode} />
      <StaticRow label={'Кем выдан'} value={client.passport?.manager} />
      <StaticRow label={'Место рождения'} value={client.birthPlace} />
      <StaticRow label={'Страна рождения'} value={getStaticDictValue(client.birthCountryId, countries)} />
      <StaticRow label={'СНИЛС'} value={client.snils} />
      <StaticRow label={'ИНН'} value={client.rnn} />
    </div>
  );
};
