import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Button } from '../../../components/Button';
import { Row, Col, Divider, Checkbox as AntdCheckbox } from 'antd';
import { get } from 'lodash';
import { useTranslation } from '../../../utils/useTranslation';
import { PrintFormDocument } from '../../../components/PrintFormDocument/PrintFormDocument';
import {
  PrintFormType,
  OtpChallengeType,
  PrintFormContextType,
  SettingsType,
  AppContext,
  DisplayModeType,
} from '@brainysoft/lk-components';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { ChallengeApproval } from '../../../packages/Formik/Components/ChallengeApproval';
import { useClient } from '../../../queries/useClient';
import { useExtraField } from '../../../queries/useExtraField';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { Modal } from '../../../components/Modal';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import Decimal from 'decimal.js-light';
import { StatefulProcessActions } from '../../../components/StatefulProcess/StatefulProcessActions';
import { useGoogleAnalytics } from '../../../integrations/useGoogleAnalytics';
import { useYandexMetrika } from '../../../integrations/useYandexMetrika';
import { useSetting } from '../../../utils/useSetting';
import { Collapse, Panel } from '../../../components/Collapse';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { useHistory } from '../../../components/HistoryProvider';

export const SignOfferSign: React.FC<IStatefulProcessRenderer> = (props) => {
  const { credit, state, goForward, goBack, isMutating } = props;
  const { state: stateContainer } = AppContext.useContainer();
  const mode = stateContainer.displayMode;

  const getMarginInsurance = () => {
    if (mode !== DisplayModeType.MOBILE) return 0;
    return window.innerHeight - 300;
  };

  const [insuranceCbMailing, setInsuranceCbMailing] = React.useState(true);
  const [isCollapseVisible, setIsCollapseVisible] = React.useState(false);
  const [isCollapseInsuranceVisible, setIsCollapseInsuranceVisible] = React.useState(false);
  const [insuranceOffset, setInsuranceOffset] = React.useState<number>(-1);
  const extraFieldQuery = useExtraField(credit.entities.leadId, 'bank_of_russia');
  const isBankOfRussia = extraFieldQuery.isSuccess ? !!extraFieldQuery.data : undefined;

  const { t } = useTranslation();
  const regClasses = useThemeStyles('RegistrationPage');
  const classes = useThemeStyles('ActiveCreditPage');
  const consentsClasses = useThemeStyles('Consents');
  const registrationClasses = useThemeStyles('RegistrationPage');
  const client = useClient();

  const form = React.useRef();

  const insuranceAmount = state.data?.newOffer?.amount_of_insurance || null;
  const insuranceCoverage = state.data?.newOffer?.coverage_amount || null;

  const ofertaInsuranceAcceptedDefault = useSetting(SettingsType.OFFERTA_INSURANCE_ACCEPTED_DEFAULT);
  const insuranceIsCheckbox = useSetting(SettingsType.OFFERTA_INSURANCE_IS_CHECKBOX);

  const [ofertaInsuranceAccepted, setOfertaInsuranceAccepted] = React.useState(
    ofertaInsuranceAcceptedDefault !== false
  );

  const [isApproved, setIsApproved] = React.useState(false);

  const [isInsuranceDetailsModalVisible, setIsInsuranceDetailsModalVisible] = React.useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const { history } = useHistory();

  React.useEffect(() => {
    if (!history.length) goBack();
    setInsuranceOffset(getMarginInsurance());
  }, []);

  React.useEffect(() => {
    if (typeof isBankOfRussia === 'boolean') {
      //если банк России сбрасываем галочки
      if (isBankOfRussia) {
        setOfertaInsuranceAccepted(false);
        setInsuranceCbMailing(false);
        setInsuranceOffset(0);
      }
    }
  }, [isBankOfRussia]);

  const autoChargeOff = credit.data?.autoChargeOff;

  const onClickForward = () => {
    (form.current as any).submitForm();
  };

  const onClickBack = async () => {
    await goBack();
  };

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();
  const onUpdate = async (formik, changed) => {
    if (changed === undefined) {
      formik.setFieldValue('lead_extraFields.contract.assignmentConsent', true);
      formik.setFieldValue('lead_extraFields.contract.autoChargeOff', true);
    }
    const documentsSignId = get(formik, 'values.challengeId');
    const phoneCode = get(formik, 'values.phoneCode');

    if (documentsSignId && phoneCode) {
      onSubmit(formik.values);
    }
  };
  const onSubmit = async (values) => {
    ga.send('agreement_sign');
    ym.send('agreement_sign');
    setIsLoading(true);
    await goForward({
      ...state.data,
      challengeId: values.challengeId,
      ofertaInsuranceAccepted: ofertaInsuranceAccepted && insuranceAmount !== null,
      autoChargeOff,
      insuranceAmount: insuranceAmount ? new Decimal(insuranceAmount).toDecimalPlaces(2).toNumber() : undefined,
    });
    setIsLoading(false);
  };

  // не дожидаемся загрузки принт-форм
  const isApproveEnabled = true;
  const isSignEnabled = isApproved;

  const onApprove = async (value) => {
    setIsApproved(true);
  };

  const onClickSelectAll = () => {
    const checked = insuranceCbMailing && ofertaInsuranceAccepted;
    setInsuranceCbMailing(!checked);
    setOfertaInsuranceAccepted(!checked);
    const formik = form.current as any;
    formik.setFieldValue('acceptPersonalData', checked);
    formik.setFieldValue('phoneApproved', checked);
  };

  const needCheckboxs = () => {
    const extraFields = client?.data?.extraFields ?? [];
    type TFlags = { assignmentConsent: boolean; autoChargeOff: boolean };
    const flags: TFlags = extraFields.reduce(
      (acc: TFlags, el) => {
        if (el.key === 'assignmentConsent' || el.key === 'autoChargeOff') {
          acc[el.key] = el.value === 'true';
        }
        return acc;
      },
      { assignmentConsent: false, autoChargeOff: false }
    );
    return !flags?.assignmentConsent || !flags?.autoChargeOff;
  };

  return (
    <div className={classes.uploadSignWrapper}>
      <h2 className={classes.header}>{t('processes:interactions.signOffer.sign.header')}</h2>
      <div>
        Введя код, вы подтверждаете, что ознакомились и согласились с условиями
        <PrintFormDocument
          name={'договора потребительского займа'}
          type={'link'}
          params={{
            uuid: credit.uuid,
            variables: {},
          }}
          hideLoading={true}
          icon={'file-pdf'}
          contextType={PrintFormContextType.CREDIT}
          printFormType={PrintFormType.OFERTA}
          className={'mb0'}
        />
        <div className={registrationClasses.acceptAllWrapper}>
          {needCheckboxs() && (
            <>
              <span onClick={() => setIsCollapseVisible(!isCollapseVisible)}>и предоставили согласия</span>
              <span
                className={`${registrationClasses.acceptAllArrow} ${isCollapseVisible ? 'active' : ''}`}
                onClick={() => setIsCollapseVisible(!isCollapseVisible)}
              >
                <RightOutlined />
              </span>
            </>
          )}
        </div>
      </div>

      <Formik innerRef={form as any} initialValues={{ code: undefined }} onSubmit={() => {}}>
        {(formik: FormikProps<any>) => {
          return (
            <FormikForm formik={formik} onUpdate={onUpdate} onSubmit={onSubmit}>
              {needCheckboxs() && (
                <Collapse
                  className={`simple borderless paddingless ${classes.generalApproveWrapper} horizontal`}
                  bordered={false}
                  activeKey={isCollapseVisible ? 1 : undefined}
                >
                  <Panel showArrow={false} header='' forceRender={true} key='1'>
                    <div className={consentsClasses.consentsWrapper}>
                      <div className={registrationClasses.checkboxWrapper}>
                        <Checkbox name='lead_extraFields.contract.assignmentConsent' />
                        <div className={registrationClasses.checkboxLabel}>
                          <span>
                            Предоставляю заранее данный акцепт на списание денежных средств по Договору потребительского
                            займа
                          </span>
                        </div>
                      </div>

                      <div className={registrationClasses.checkboxWrapper}>
                        <Checkbox name='lead_extraFields.contract.autoChargeOff' />
                        <div className={registrationClasses.checkboxLabel}>
                          <span>
                            Предоставляю согласие на уступку кредитором третьим лицам прав (требований) по договору
                            займа
                          </span>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              )}
              <div className={'mt2'}>
                {isApproveEnabled && (
                  <div className={registrationClasses.codeApprovalWrapper}>
                    <ChallengeApproval
                      formik={formik}
                      idField={'challengeId'}
                      approveField={'signOferta'}
                      otpChallengeType={OtpChallengeType.OFERTA}
                      challengeButtonSize={'middle'}
                      onApprove={onApprove}
                      horizontal={true}
                      mobilePhone={client.data?.mobilePhone ?? 'undefined'}
                      challengePayload={{
                        uuid: credit.uuid,
                        ofertaInsuranceAccepted,
                        insuranceAmount,
                      }}
                    />
                  </div>
                )}
              </div>
            </FormikForm>
          );
        }}
      </Formik>

      <Divider />

      <StatefulProcessActions
        showForward={state.transitions?.forward}
        showBack={state.transitions?.back}
        onClickForward={onClickForward}
        onClickBack={onClickBack}
        textForward={t('signOffer:signDocuments')}
        textBack={t('signOffer:back')}
        isForwardDisabled={!isSignEnabled}
        isForwardLoading={isLoading}
        isMutating={isMutating}
      />

      {insuranceAmount !== null && (
        <>
          <Divider />
          <div
            className={'insurance-container'}
            style={{ display: insuranceOffset < 0 ? 'none' : 'inherit', marginTop: `${insuranceOffset}px` }}
          >
            {insuranceIsCheckbox == true && (
              <div className={regClasses.grayStyleCheckbox}>
                <div className={regClasses.acceptAllWrapper}>
                  <div className={regClasses.checkboxWrapper}>
                    <AntdCheckbox
                      checked={insuranceCbMailing && ofertaInsuranceAccepted}
                      onClick={(e) => onClickSelectAll()}
                    />
                    <div
                      style={{ marginLeft: '1rem' }}
                      className={regClasses.checkboxLabel}
                      onClick={() => onClickSelectAll()}
                    >
                      Я ознакомлен(а) и согласен(на) со следующим:
                    </div>
                  </div>
                  <span
                    className={`${regClasses.acceptAllArrow} ${isCollapseInsuranceVisible ? 'active' : ''}`}
                    onClick={() => setIsCollapseInsuranceVisible(!isCollapseInsuranceVisible)}
                  >
                    <RightOutlined />
                  </span>
                </div>

                <Collapse
                  className={`simple borderless paddingless ${classes.generalApproveWrapper} horizontal`}
                  bordered={false}
                  activeKey={isCollapseInsuranceVisible ? 1 : undefined}
                >
                  <Panel showArrow={false} header='' forceRender={true} key='1'>
                    <div className={consentsClasses.consentsWrapper}>
                      <div className={regClasses.checkboxWrapper}>
                        <AntdCheckbox
                          checked={insuranceCbMailing}
                          onClick={(e) => setInsuranceCbMailing(!insuranceCbMailing)}
                        />
                        <div style={{ marginLeft: '1rem' }} className={regClasses.checkboxLabel}>
                          <span>
                            Даю свое согласие на{' '}
                            <a
                              href={
                                'https://kredito24.ru/netcat_files/docs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B8%20%D0%BE%D0%B1%20%D0%B0%D0%BA%D1%86%D0%B8%D1%8F%D1%85.pdf'
                              }
                              target={'_blank'}
                              rel='noreferrer noopener'
                            >
                              информирование о специальных акциях компании
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className={regClasses.checkboxWrapper}>
                        <AntdCheckbox
                          checked={ofertaInsuranceAccepted}
                          onClick={(e) => setOfertaInsuranceAccepted(!ofertaInsuranceAccepted)}
                        />
                        <div style={{ marginLeft: '1rem' }} className={regClasses.checkboxLabel}>
                          <span>
                            Даю свое согласие на подключение защиты от несчастных случаев (
                            <PrintFormDocument
                              name={t('printForms:download.ofertaInsuranceCeckbox')}
                              type={'link'}
                              params={{
                                uuid: credit.uuid,
                                variables: {
                                  'lead.extraFields.contract.insuranceAmount': insuranceAmount,
                                  'lead.extraFields.contract.insuranceCoverage': insuranceCoverage,
                                },
                              }}
                              contextType={PrintFormContextType.CREDIT}
                              printFormType={PrintFormType.OFERTA_INSURANCE}
                              className={classes.insuranceDocument}
                            />
                            ) {insuranceAmount} руб.&nbsp;
                          </span>
                          <span
                            className={classes.insuranceInfoButton}
                            onClick={(e) => {
                              e.preventDefault();
                              setIsInsuranceDetailsModalVisible(true);
                            }}
                          >
                            <InfoCircleOutlined />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                <Modal
                  centered
                  open={isInsuranceDetailsModalVisible}
                  onCancel={() => setIsInsuranceDetailsModalVisible(false)}
                >
                  <InsuranceDescription />
                </Modal>
              </div>
            )}
            {insuranceIsCheckbox != true && (
              <>
                <Row gutter={24} justify={'center'}>
                  <Col xs={24} md={16}>
                    <div className={classes.insuranceWrapper}>
                      <div className={classes.insuranceContent}>
                        <h4 className={`${classes.insuranceHeader} ${ofertaInsuranceAccepted ? 'accepted' : ''}`}>
                          {ofertaInsuranceAccepted
                            ? 'Защита от несчастных случаев включена!'
                            : 'Защита от несчастных случаев отключена!'}
                        </h4>
                        {ofertaInsuranceAccepted && (
                          <div className={classes.insurancePrintFormWrapper}>
                            <PrintFormDocument
                              name={t('printForms:download.ofertaInsurance')}
                              type={'link'}
                              params={{
                                uuid: credit.uuid,
                                variables: {
                                  'lead.extraFields.contract.insuranceAmount': insuranceAmount,
                                  'lead.extraFields.contract.insuranceCoverage': insuranceCoverage,
                                },
                              }}
                              contextType={PrintFormContextType.CREDIT}
                              printFormType={PrintFormType.OFERTA_INSURANCE}
                              className={'insuranceDocument'}
                            />
                            <span
                              className={classes.insuranceInfoButton}
                              onClick={(e) => {
                                e.preventDefault();
                                setIsInsuranceDetailsModalVisible(true);
                              }}
                            >
                              <InfoCircleOutlined />
                            </span>
                          </div>
                        )}
                        <Button
                          type={'link'}
                          className={`${classes.insuranceButton} ${ofertaInsuranceAccepted ? 'accepted' : ''}`}
                          onClick={(e) => {
                            e.preventDefault();
                            ofertaInsuranceAccepted ? setIsCancelModalVisible(true) : setOfertaInsuranceAccepted(true);
                          }}
                        >
                          {ofertaInsuranceAccepted ? 'Узнать больше информации' : 'Нажмите чтобы включить защиту'}
                        </Button>
                      </div>
                    </div>
                    <Modal
                      centered
                      open={isInsuranceDetailsModalVisible}
                      onCancel={() => setIsInsuranceDetailsModalVisible(false)}
                    >
                      <InsuranceDescription />
                    </Modal>
                    <Modal centered open={isCancelModalVisible} onCancel={() => setIsCancelModalVisible(false)}>
                      <div className={classes.cancelModalWrapper}>
                        <h3 className={classes.cancelModalHeader}>
                          При оформлении страховки Ваш займ будет оплачен в случае возникновения{' '}
                          <span style={{ whiteSpace: 'nowrap' }}>несчастного случая!</span>
                        </h3>
                        <div>Срок действия страховки - 2 месяца</div>

                        <Divider />

                        <div className={classes.cancelActionsWrapper}>
                          <Button
                            onClick={() => {
                              setOfertaInsuranceAccepted(false);
                              setIsCancelModalVisible(false);
                            }}
                          >
                            Отменить защиту
                          </Button>
                          <Button type={'primary'} onClick={() => setIsCancelModalVisible(false)}>
                            Вернуться
                          </Button>
                        </div>
                      </div>
                    </Modal>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const InsuranceDescription: React.FC = () => {
  const classes = useThemeStyles('ActiveCreditPage');
  return (
    <React.Fragment>
      <h3 className={classes.modalHeader}>
        Порядок оформления и предоставления услуги страхования от несчастного случая:
      </h3>
      <ul className={classes.modalContent}>
        <li>Оформление не требует никаких медицинских справок для подключения.</li>
        <li>
          В Полис-оферту входят следующие страховые риски: Смерть и Инвалидность Застрахованного в результате
          несчастного случая. В случае наступления страховых рисков в период действия Полиса-оферты, страховой компанией
          производится выплата денежных средств в размере 100% от страховой суммы.
        </li>
        <li>
          Подключение услуги страхования производится путём автосписания денежных средств со счета Застрахованного
          следом за выдачей суммы займа. Согласие с условиями страхования считается уплата страховой суммы
          Полиса-оферты.
        </li>
        <li>
          Срок страхования: 2 месяца со следующего дня после оплаты с возможностью отмены в течение 30 дней с момента
          оформления.
        </li>
        <li>
          Стоимость страхования указана рядом с окном для принятия решения. Рекомендуем учитывать стоимость при принятии
          решения о приобретении дополнительной услуги.
        </li>
        <li>Подключение услуги страхования не является обязательным условием для одобрения займа.</li>
      </ul>
    </React.Fragment>
  );
};
