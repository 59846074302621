import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { IProcessStepProps } from '@brainysoft/lk-components';
import { PhotoType } from '@brainysoft/lk-components';
import { RegisterFormPhotoUploader } from './RegisterFormPhotoUploader';

export const RegisterFormPhotoSelfie: React.FC<IProcessStepProps> = (props) => {
  const fileSettings = [
    {
      name: 'selfie',
      label: 'Фото селфи с паспортом',
      placeholder: 'Загрузите фото селфи с паспортом',
      code: PhotoType.SELFIE,
      placeholderImgUrl: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/selfie_square.jpg',
      required: true,
    },
  ];

  return (
    <RegisterFormPhotoUploader
      filesSettings={fileSettings}
      title={'Загрузите фото вашего паспорта'}
      layout={'row'}
      descriptor={
        <React.Fragment>
          <p>Чтобы убедиться, что вы - это вы, нам нужно ваше фото с раскрытым паспортом, как на примерах ниже.</p>

          <p>ОБЯЗАТЕЛЬНЫЕ ТРЕБОВАНИЯ К ФОТО ПАСПОРТА:</p>
          <ul className={'mb2'}>
            <li>страницы должны помещаться на фото целиком, без обрезов</li>
            <li>фото должны быть цветными</li>
            <li>вся информация должна читаться, на тексте не должно быть бликов</li>
            <li>не закрывайте текст пальцами или иными предметами</li>
            <li>
              на селфи с паспортом должно быть видно ваше лицо и паспорт в открытом виде. Текст паспорта должен
              читаться.
            </li>
            <li>фото должны быть размером менее 5 мб в формате .jpg или .png.</li>
          </ul>
        </React.Fragment>
      }
      {...props}
    />
  );
};
