import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { ResultIcon } from '../../../components/ResultIcon';
import { ResultType } from '@brainysoft/lk-components';
import { Divider } from 'antd';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import { refreshActiveCredit } from '../../../queries/useActiveCredit';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import { StatefulProcessActions } from '../../../components/StatefulProcess/StatefulProcessActions';

export const SignOfferResult: React.FC<IStatefulProcessRenderer> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');
  const router = useRouter();
  const queryClient = useQueryClient();

  const iconSize = 80;
  const wrapper = `${classes.wrapper}`;

  // React.useEffect(() => {
  //   goForward(state.data);
  // }, []);

  const onClick = async () => {
    refreshActiveCredit(queryClient);
    await router.push('/');
  };

  return (
    <React.Fragment>
      <div className={wrapper}>
        <h2 className={classes.header}>Поздравляем, договор подписан!</h2>
        <ResultIcon result={ResultType.SUCCESS} size={iconSize} />
        <div className={classes.descriptor}>
          <p>
            Вы получите sms сообщение о перечислении денежных средств. <br />
            Если это ваш первый заем, то поступление средств будет произведено сразу после проверки паспорта. <br />{' '}
            Наши сотрудники работают ежедневно с 8:00 до 20:00. <br /> Телефон службы поддержки:{' '}
            <a href='tel:74952259063'>+7 495 225-90-63</a>, e-mail:{' '}
            <a href='mailto:info@kredito24.ru'>info@kredito24.ru</a>
          </p>
        </div>
      </div>
      <Divider />
      <StatefulProcessActions
        showForward={true}
        showBack={false}
        onClickForward={onClick}
        textForward={'На главную'}
        isMutating={false}
      />
    </React.Fragment>
  );
};
