import * as React from 'react';
import { PhotoType } from '@brainysoft/lk-components';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import { FileSettingsT } from '@brainysoft/lk-components';
import { InteractionFilesUploader } from '../../default/uploaders/InteractionFilesUploader';

export const UploadCardPhotoProcess: React.FC<IStatefulProcessRenderer> = (props) => {
  const fileSettings: FileSettingsT[] = [
    {
      name: PhotoType.RUS_PASSPORT_PERSONAL_DATA_PLUS_CARD,
      label: 'Фото разворота с ФИО и банковской карты',
      placeholder: 'Загрузите фото разворота с ФИО и банковской карты',
      code: PhotoType.RUS_PASSPORT_PERSONAL_DATA_PLUS_CARD,
      placeholderImgUrl:
        'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/passport_plus_card_placeholder.png',
      required: true,
    },
    {
      name: PhotoType.RUS_PASSPORT_REGISTRATION,
      label: 'Фото разворота с актуальной регистрацией',
      placeholder: 'Загрузите фото разворота с актуальной регистрацией',
      code: PhotoType.RUS_PASSPORT_REGISTRATION,
      placeholderImgUrl:
        'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/passport_placeholder_2.png',
      required: true,
    },
  ];

  return (
    <InteractionFilesUploader
      filesSettings={fileSettings}
      title={'Загрузите фото вашего паспорта и банковской карты'}
      descriptor={
        <React.Fragment>
          <p>
            Чтобы убедиться, что вы – это вы, нам нужно 2 фото:
            <ul>
              <li>фото разворота паспорта с ФИО и банковской карты рядом с паспортом</li>
              <li>фото разворота с актуальной регистрацией</li>
            </ul>
          </p>

          <p>ОБЯЗАТЕЛЬНЫЕ ТРЕБОВАНИЯ К ФОТО ПАСПОРТА:</p>
          <ul className={'mb1'}>
            <li>страницы должны помещаться на фото целиком, без обрезов</li>
            <li>банковская карта на фото не должна перекрывать паспорт</li>
            <li>банковская карта на фото – та, которую вы указали при оформлении заявки</li>
            <li>фото должны быть цветными</li>
            <li>вся информация должна читаться, на тексте не должно быть бликов</li>
            <li>не закрывайте текст и банковскую карту пальцами или иными предметами</li>
          </ul>
        </React.Fragment>
      }
      {...props}
    />
  );
};
