import * as React from 'react';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { get } from 'lodash';
import {
  cacheService as Storage,
  checkService,
  IProcessStepProps,
  processService,
  ProcessType,
} from '@brainysoft/lk-components';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { Button, Col, Row, Checkbox as AntdCheckbox } from 'antd';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { DatePicker } from '../../../packages/Formik/Components/DatePicker';
import { Select } from '../../../packages/Formik/Components/Select';
import { getCodeBySexTypeId, getSexTypeId, SexType } from '@brainysoft/lk-components';
import { DadataInputAntd } from '../../../packages/Formik/Components/DadataInputAntd';
import { Collapse, Panel } from '../../../components/Collapse';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { Message } from '../../../components/Message';
import { ExternalAuthGosuslugi } from '../../../components/ExternalAuth/ExternalAuthGosuslugi';
import { useTranslation } from '../../../utils/useTranslation';
import { EmailApproval } from '../../../packages/Formik/Components/EmailApproval';
import { disabledInvalidAgeDate, validateAgeDate } from '../../../utils/datePickerUtils';
import dayjs from 'dayjs';

const options = [
  { value: SexType.MALE, option: 'Мужчина' },
  { value: SexType.FEMALE, option: 'Женщина' },
];

export const _RegisterFormFio: React.FC<IProcessStepProps> = (props) => {
  const classes = useThemeStyles('RegistrationPage');
  const consentsClasses = useThemeStyles('Consents');

  const { t } = useTranslation();

  React.useEffect(() => {
    props.setForwardButtonText(t('buttons.forward'));
    props.setStepsVisible(true);
    props.setForceIsForwardVisible(true);
  }, []);

  const { initialValues, processUuid } = props;

  //предустановка галочек
  if (get(initialValues, 'phoneApproved') === undefined || get(initialValues, 'phoneApproved') === null) {
    initialValues['phoneApproved'] = false;
  }
  if (get(initialValues, 'acceptPersonalData') === undefined || get(initialValues, 'acceptPersonalData') === null) {
    initialValues['acceptPersonalData'] = false;
  }
  if (
    get(initialValues, 'client_extraFields.acceptRules') === undefined ||
    get(initialValues, 'client_extraFields.acceptRules') === null
  ) {
    initialValues['client_extraFields'] = {
      ...initialValues['client_extraFields'],
      acceptRules: false,
    };
  }
  if (
    get(initialValues, 'client_extraFields.confidentialPolicy') === undefined ||
    get(initialValues, 'client_extraFields.confidentialPolicy') === null
  ) {
    initialValues['client_extraFields'] = {
      ...initialValues['client_extraFields'],
      confidentialPolicy: false,
    };
  }
  if (
    get(initialValues, 'client_extraFields.assignmentConsent') === undefined ||
    get(initialValues, 'client_extraFields.assignmentConsent') === null
  ) {
    initialValues['client_extraFields'] = {
      ...initialValues['client_extraFields'],
      assignmentConsent: false,
    };
  }
  if (
    get(initialValues, 'client_extraFields.autoChargeOff') === undefined ||
    get(initialValues, 'client_extraFields.autoChargeOff') === null
  ) {
    initialValues['client_extraFields'] = {
      ...initialValues['client_extraFields'],
      autoChargeOff: false,
    };
  }

  const [allChecked, setAllChecked] = React.useState(false);
  const [isCollapseVisible, setIsCollapseVisible] = React.useState(false);
  const [requestGender, setRequestGender] = React.useState(
    initialValues?.sexId ? getCodeBySexTypeId(initialValues?.sexId) : 'UNKNOWN'
  );
  const [showFio, setShowFio] = React.useState<boolean>(true);
  const [showAdditionalPhone, setShowAdditionalPhone] = React.useState<boolean>(false);

  const hasExternalAuth = !!initialValues?.externalAuthProcessUuid;

  React.useEffect(() => {
    props.setSubmitDisabled(false);
  }, []);

  React.useEffect(() => {
    if (initialValues?.mobilePhone) setShowAdditionalPhone(true);
  }, [initialValues?.mobilePhone]);

  const onUpdate = async (formik, changed) => {
    const changedNames = Object.keys(changed ?? {});

    if (changedNames.includes('fio') || changedNames.includes('_suggestion_fio')) {
      const suggestion = formik.values?._suggestion_fio?.data;

      formik.setFieldValue('lastName', suggestion?.surname);
      formik.setFieldValue('firstName', suggestion?.name);
      if (!formik.values.noPatronymic) formik.setFieldValue('patronymic', suggestion?.patronymic);

      const newGender = get(formik, 'values._suggestion_fio.data.gender');
      const newSexId = newGender && newGender !== 'UNKNOWN' ? getSexTypeId(newGender) : null;
      formik.setFieldValue('sexId', newSexId);
    }

    if (changedNames.includes('sexId')) {
      const sexId = formik.values?.sexId;
      setRequestGender(sexId ? getCodeBySexTypeId(sexId) : 'UNKNOWN');
    }

    if (changedNames.includes('mobilePhone')) {
      const mobilePhone = get(formik, 'values.mobilePhone');
      const mobilePhoneError = get(formik, 'errors.mobilePhone');
      if (mobilePhone && !mobilePhoneError) setShowAdditionalPhone(true);
    }

    const acceptPersonalData = get(formik, 'values.acceptPersonalData');
    // const phoneApproved = get(formik, 'values.phoneApproved');
    const assignmentConsent = get(formik, 'values.client_extraFields.assignmentConsent');
    const acceptRules = get(formik, 'values.client_extraFields.acceptRules');
    const confidentialPolicy = get(formik, 'values.client_extraFields.confidentialPolicy');
    const autoChargeOff = get(formik, 'values.client_extraFields.autoChargeOff');

    setAllChecked(!!(acceptPersonalData && assignmentConsent && autoChargeOff && acceptRules && confidentialPolicy));

    props.setSubmitDisabled(!(acceptPersonalData && acceptRules && confidentialPolicy));
  };

  let initialFio;
  if (initialValues?.lastName || initialValues?.patronymic || initialValues?.firstName) {
    initialFio = `${initialValues?.lastName ?? ''} ${initialValues?.firstName ?? ''} ${
      initialValues?.patronymic ?? ''
    }`;
  }

  const onSubmit = async ({ values, actions, submit }) => {
    if (!values.noPatronymic && !values.patronymic) {
      await actions.setFieldError('fio', 'Введите вашу фамилию, имя и отчество');
      return;
    }

    const res = await checkService.checkEmail(values.email);
    if (!res?.isUnique) {
      await actions.setFieldError('email', 'email_is_not_unique');
      return;
    }

    if (values.additionalPhone) {
      if (values.additionalPhone === values.mobilePhone || values.additionalPhone.length !== 11)
        values.additionalPhone = undefined;
    }

    await submit(values);
  };

  const onClickSelectAll = (formik) => {
    const checked = !allChecked;
    setAllChecked(checked);
    formik.setFieldValue('acceptPersonalData', checked);
    formik.setFieldValue('phoneApproved', checked);
    formik.setFieldValue('client_extraFields.assignmentConsent', checked);
    formik.setFieldValue('client_extraFields.acceptRules', checked);
    formik.setFieldValue('client_extraFields.confidentialPolicy', checked);
    formik.setFieldValue('client_extraFields.autoChargeOff', checked);
  };

  const initialBirthDate = dayjs().subtract(18, 'years');

  const returnUrl = window.location.origin + '/registration/external-auth-result';

  const preprocess = (formik) => {
    return async () => {
      const uuid = (await Storage.getItem(`process:${ProcessType.REGISTRATION}:uuid`)) as string | undefined;
      const session = (await Storage.getItem(`process:${ProcessType.REGISTRATION}:session`)) as string | undefined;

      return await processService.update(ProcessType.REGISTRATION, uuid, session, formik.values);
    };
  };

  if (!props.initialValues.email) props.initialValues.email = null;

  return (
    <ProcessStepForm
      {...props}
      initialValues={{ ...initialValues, fio: initialFio }}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      render={(formik) => {
        return (
          <React.Fragment>
            {/*<h4 className={classes.warning}>Для получения займа понадобится паспорт, СНИЛС и банковская карта, выпущенная на ваше имя</h4>*/}
            {!hasExternalAuth && (
              <ExternalAuthGosuslugi returnUrl={returnUrl} preprocess={preprocess(formik)} className={'mb1'} />
            )}

            <Message type={'info'}>
              Для получения займа понадобится паспорт и ваша банковская карта. Заполните данные без опечаток, иначе мы
              не сможем одобрить вам займ.
            </Message>

            {showFio && (
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <div className='fields'>
                    <DadataInputAntd
                      name='fio'
                      label={`Фамилия, имя${!formik.values.noPatronymic ? ', отчество' : ''}`}
                      placeholder={`Введите вашу фамилию, имя${!formik.values.noPatronymic ? ' и отчество' : ''}`}
                      type='fio'
                      required={true}
                      disabled={hasExternalAuth}
                      forceSuggestion={true}
                      payloadModifier={{ gender: requestGender }}
                      mask={'cyrillic'}
                    />
                  </div>
                  {!!get(formik, 'values.fio') && (
                    <div className={classes.fioDescriptor}>
                      <div className={classes.fioDescriptorText}>
                        <span className={`fioData ${formik.values?.lastName ? 'success' : 'error'}`}>
                          Фамилия: {formik.values?.lastName}
                        </span>
                        {', '}
                        <span className={`fioData ${formik.values?.firstName ? 'success' : 'error'}`}>
                          Имя: {formik.values?.firstName}
                        </span>
                        {!formik.values?.noPatronymic && (
                          <React.Fragment>
                            {', '}
                            <span className={`fioData ${formik.values?.patronymic ? 'success' : 'error'}`}>
                              Отчество: {formik.values?.patronymic}
                            </span>
                          </React.Fragment>
                        )}
                        <br />
                        Если Ваши фамилия, имя{!formik.values.noPatronymic ? ' и отчество' : ''} распознаны неверно -
                        нажмите на кнопку &quot;Ввести Фио вручную&quot;
                      </div>
                      <div>
                        <Button
                          size={'small'}
                          onClick={() => {
                            setShowFio(false);
                          }}
                        >
                          Ввести Фио вручную
                        </Button>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            )}
            {!showFio && (
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <div className='fields'>
                    <DadataInputAntd
                      name='lastName'
                      label='Фамилия'
                      placeholder='Ваша фамилия'
                      type='fio'
                      payloadModifier={{ parts: 'surname', gender: requestGender }}
                      required={true}
                      disabled={hasExternalAuth}
                      mask={'cyrillic'}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div className='field'>
                    <DadataInputAntd
                      name='firstName'
                      label='Имя'
                      placeholder='Ваше имя'
                      type='fio'
                      payloadModifier={{ parts: 'name', gender: requestGender }}
                      required={true}
                      disabled={hasExternalAuth}
                      mask={'cyrillic'}
                    />
                  </div>
                </Col>
                {!formik.values?.noPatronymic && (
                  <Col xs={24} sm={8}>
                    <div className='field'>
                      <DadataInputAntd
                        name='patronymic'
                        label='Отчество'
                        placeholder='Ваше отчество'
                        type='fio'
                        payloadModifier={{ parts: 'patronymic', gender: requestGender }}
                        required={true}
                        disabled={hasExternalAuth}
                        mask={'cyrillic'}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            )}

            <Row gutter={16}>
              <Col xs={24}>
                <Checkbox name='noPatronymic' label={'Отчества нет'} />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={6}>
                <div className='field'>
                  <DatePicker
                    name='birthDate'
                    label='Дата рождения'
                    placeholder='ДД.ММ.ГГГГ'
                    outgoingFormat='YYYY-MM-DD'
                    required={true}
                    disabledDate={disabledInvalidAgeDate}
                    validate={validateAgeDate}
                    defaultPickerValue={initialBirthDate}
                  />
                </div>
              </Col>
              <Col xs={24} sm={6}>
                <div className='field'>
                  <Select name='sexId' label='Пол' required={true} options={options} />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className='field'>
                  {/*<MaskedInput name="email" label="E-mail" placeholder="Ваш адрес электронной почты" mask="email" required={true} />*/}
                  <EmailApproval processUuid={processUuid}>
                    {(validateStatus) => (
                      <DadataInputAntd
                        name='email'
                        label='E-mail'
                        placeholder='Ваш адрес электронной почты'
                        type='email'
                        required={true}
                        mask='email'
                        validateStatus={validateStatus}
                      />
                    )}
                  </EmailApproval>
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <div className='field'>
                  <MaskedInput
                    name='mobilePhone'
                    label='Ваш мобильный телефон'
                    mask='mobilePhonePrefixed'
                    required={true}
                    shouldValidateOnChange={true}
                  />
                </div>
              </Col>
              {showAdditionalPhone && (
                <Col xs={24} sm={12}>
                  <div className='field'>
                    <MaskedInput
                      name='additionalPhone'
                      label='Ваш дополнительный телефон (при наличии)'
                      mask='mobilePhonePrefixed'
                    />
                  </div>
                </Col>
              )}
            </Row>

            <br />
            <div className={classes.acceptAllWrapper}>
              <div className={classes.checkboxWrapper}>
                {/*<Checkbox name="acceptAll" initialValue={get(props, 'initialValues.acceptPersonalData')} />*/}
                <AntdCheckbox
                  checked={allChecked}
                  onClick={(e) => onClickSelectAll(formik)}
                  style={{ marginRight: '1rem' }}
                />
                <div className={classes.checkboxLabel} onClick={() => onClickSelectAll(formik)}>
                  Я ознакомлен(а) и согласен(на) со следующим:
                </div>
              </div>
              <span
                className={`${classes.acceptAllArrow} ${isCollapseVisible ? 'active' : ''}`}
                onClick={() => setIsCollapseVisible(!isCollapseVisible)}
              >
                <RightOutlined />
              </span>
            </div>

            <Collapse
              className={`simple borderless paddingless ${classes.generalApproveWrapper} horizontal`}
              bordered={false}
              activeKey={isCollapseVisible ? 1 : undefined}
            >
              <Panel showArrow={false} header='' forceRender={true} key='1'>
                <div className={consentsClasses.consentsWrapper}>
                  <div className={classes.checkboxWrapper}>
                    <Checkbox name='acceptPersonalData' />
                    <div className={classes.checkboxLabel}>
                      <span>
                        Я принимаю{' '}
                        <a
                          href={
                            'https://kredito24.ru/netcat_files/docs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5%20%D1%80%D0%B0%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B8.pdf'
                          }
                          target={'_blank'}
                          rel='noreferrer noopener'
                        >
                          условия передачи информации
                        </a>
                      </span>
                    </div>
                  </div>

                  <div className={classes.checkboxWrapper}>
                    <Checkbox name='client_extraFields.assignmentConsent' />
                    <div className={classes.checkboxLabel}>
                      <span>
                        Предоставляю согласие на уступку кредитором третьим лицам прав (требований) по договору займа
                      </span>
                    </div>
                  </div>
                  <div className={classes.checkboxWrapper}>
                    <Checkbox name='client_extraFields.acceptRules' />
                    <div className={classes.checkboxLabel}>
                      <span>
                        Я ознакомился с{' '}
                        <a
                          href={'https://kredito24.ru/pravila-predostavleniya-mikrozaymov/'}
                          target={'_blank'}
                          rel='noreferrer noopener'
                        >
                          Правилами предоставления займов
                        </a>{' '}
                        и{' '}
                        <a
                          href={'https://kredito24.ru/usloviya-predostavleniya-ispolzovaniya-i-vozvrata-zayma/'}
                          target={'_blank'}
                          rel='noreferrer noopener'
                        >
                          Общими условиями договора займа
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className={classes.checkboxWrapper}>
                    <Checkbox name='client_extraFields.autoChargeOff' />
                    <div className={classes.checkboxLabel}>
                      <span>
                        Предоставляю заранее данный акцепт на списание денежных средств по Договору потребительского
                        займа
                      </span>
                    </div>
                  </div>
                  <div className={classes.checkboxWrapper}>
                    <Checkbox name='client_extraFields.confidentialPolicy' />
                    <div className={classes.checkboxLabel}>
                      <span>
                        Я соглашаюсь с условиями{' '}
                        <a
                          href={'https://kredito24.ru/politika-konfidentsialnosti/'}
                          target={'_blank'}
                          rel='noreferrer noopener'
                        >
                          Политики конфиденциальности
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </React.Fragment>
        );
      }}
    />
  );
};

export const RegisterFormFio = React.memo(_RegisterFormFio);
