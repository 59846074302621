import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';

interface ICard {
  name: string;
  id?: string;
  className?: string;
  onClick?: any;
}

export const MoneyMethod: React.FC<ICard> = (props) => {
  const classes = useThemeStyles('MoneyMethod');
  const onClick = props.onClick && typeof props.onClick === 'function' ? props.onClick : () => {};

  return (
    <React.Fragment>
      <div className={`${classes.moneyMethod} ${props.className ? props.className : ''}`} onClick={onClick}>
        <div className={classes.name}>{props.name}</div>
      </div>
    </React.Fragment>
  );
};
