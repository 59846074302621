import * as React from 'react';
import { PhotoType } from '@brainysoft/lk-components';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import { FileSettingsT } from '@brainysoft/lk-components';
import { InteractionFilesUploader } from '../../default/uploaders/InteractionFilesUploader';

export const UploadPassportProcess: React.FC<IStatefulProcessRenderer> = (props) => {
  const fileSettings: FileSettingsT[] = [
    {
      name: PhotoType.RUS_PASSPORT_PERSONAL_DATA,
      label: 'Фото разворота с фамилией, именем и отчеством',
      placeholder: 'Загрузите разворот с фамилией, именем и отчеством',
      code: PhotoType.RUS_PASSPORT_PERSONAL_DATA,
      placeholderImgUrl:
        'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/passport_placeholder_1.png',
      required: true,
    },
    {
      name: PhotoType.RUS_PASSPORT_REGISTRATION,
      label: 'Фото разворота страниц 4-5',
      placeholder: 'Загрузите фото разворота страниц 4-5',
      code: PhotoType.RUS_PASSPORT_REGISTRATION,
      placeholderImgUrl:
        'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/passport_placeholder_2.png',
      required: true,
    },
  ];

  return (
    <InteractionFilesUploader
      filesSettings={fileSettings}
      title={'Загрузите фото вашего паспорта с фамилией, именем и отчеством'}
      descriptor={
        <React.Fragment>
          <p>
            Чтобы убедиться, что вы - это вы, нам нужны фото двух разворотов страниц вашего паспорта, как на примерах
            ниже.
          </p>

          <p>ОБЯЗАТЕЛЬНЫЕ ТРЕБОВАНИЯ К ФОТО ПАСПОРТА:</p>
          <ul className={'mb1'}>
            <li>страницы должны помещаться на фото целиком, без обрезов</li>
            <li>фото должны быть цветными</li>
            <li>вся информация должна читаться, на тексте не должно быть бликов</li>
            <li>не закрывайте текст пальцами или иными предметами</li>
          </ul>
        </React.Fragment>
      }
      {...props}
    />
  );
};
