import { useSetting } from './useSetting';
import { SettingsType } from '@brainysoft/lk-components';

export const useUnemployed: () => (employmentTypeId: number | undefined | null) => boolean = () => {
  const unemployedSettings = useSetting(SettingsType.UNEMPLOYED);

  const unemployedDictValues = Array.isArray(unemployedSettings) ? unemployedSettings : [unemployedSettings];

  return (employmentTypeId) => unemployedDictValues.includes(employmentTypeId);
};

export const useSelfemployed: () => (employmentTypeId: number | undefined | null) => boolean = () => {
  const selfemployedSettings = useSetting(SettingsType.SELFEMPLOYED);

  const selfemployedDictValues = Array.isArray(selfemployedSettings) ? selfemployedSettings : [selfemployedSettings];

  return (employmentTypeId) => selfemployedDictValues.includes(employmentTypeId);
};
