import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { Icon } from '../Icon';
import { cacheService as Storage } from '@brainysoft/lk-components';
import { ProcessType } from '@brainysoft/lk-components';
import { paymentsService } from '@brainysoft/lk-components';
import { LoadingSpinner } from '../LoadingSpinner';
import { useTranslation } from '../../utils/useTranslation';
import { throttle } from 'lodash';
import { useGoogleAnalytics } from '../../integrations/useGoogleAnalytics';
import { useYandexMetrika } from '../../integrations/useYandexMetrika';

interface CardReplaceProps {
  className?: string;
  isNew?: boolean;
  cardInitHandler?: () => void | Promise<void>;
}

export const CardInit: React.FC<CardReplaceProps> = (props) => {
  const classes = useThemeStyles('Card');
  const { t } = useTranslation();

  const [disabled, setDisabled] = React.useState(false);

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const processCardInitHandler = async () => {
    const uuid = await Storage.getItem(`process:${ProcessType.LOAN_APP}:uuid`);
    const session = await Storage.getItem(`process:${ProcessType.LOAN_APP}:session`);
    if (uuid && session && uuid !== 'undefined' && session !== ' undefined') {
      const res = await paymentsService.initCard({
        backLink: `${location.origin}/loan-app/card?uuid=${uuid}&session=${session}`,
      });
      if (res?.url) {
        //for debug
        // const token = res?.url.split('/').pop();
        // await Storage.setItem(`cardBinding:process:statusid`, token);
        ga.send('attach_card');
        ym.send('attach_card');
        window.location.href = res.url;
      }
    }
  };

  const changeCardHandler = async () => {
    setDisabled(true);
    if (!disabled) {
      if (props.cardInitHandler && typeof props.cardInitHandler === 'function') {
        await props.cardInitHandler();
      } else {
        await processCardInitHandler();
      }
    }
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 2000);
    });
    await setDisabled(false);
  };

  const onCardInit = throttle(changeCardHandler, 3000, { leading: true, trailing: false });

  return (
    <div
      className={`${classes.card} placeholder ${props.className ? props.className : ''} ${disabled ? 'disabled' : ''}`}
      onClick={onCardInit}
    >
      <div className='addCardWrapper'>
        <div className={'addCardPlaceholder'}>
          {disabled && <LoadingSpinner size={8} margin={4} className={'grey'} />}
          {!disabled && <Icon type={'credit-card'} />}
        </div>
        <span>{`${props.isNew ? t('widgets:card.add') : t('widgets:card.takeAnother')} `}</span>
      </div>
    </div>
  );
};
