import * as React from 'react';
import { CardTokenStatusType, IProcessStepProps } from '@brainysoft/lk-components';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { ProcessStepForm } from '../../../components/Process/ProcessStepForm';
import { Input } from '../../../packages/Formik/Components/Input';
import { refreshCard, useCard } from '../../../queries/useCard';
import { get } from 'lodash';
import { notify } from '../../../utils/notify';
import { useTranslation } from '../../../utils/useTranslation';
import { useRouter } from 'next/router';
import { Message } from '../../../components/Message';
import { useCardTokenStatus } from '../../../queries/useCardTokenStatus';
import { useQueryClient } from 'react-query';
import { CardPending } from '../../../components/Card/CardPending';
import { CardInit } from '../../../components/Card/CardInit';
import { CardLoading } from '../../../components/Card/CardLoading';
import { CardActive } from '../../../components/Card/CardActive';

export const LoanAppCard: React.FC<IProcessStepProps> = (props) => {
  const { t, keyExists } = useTranslation();
  const classes = useThemeStyles('LoanAppCreatePage');
  const queryClient = useQueryClient();

  const { data: card, isLoading, isFetching } = useCard();
  const router = useRouter();
  const statusId = (router.query?.statusid as string) ?? props.initialValues?.cardBindingTransactionId;

  const { data: cardTokenStatusData, isLoading: isCardTokenStatusLoading } = useCardTokenStatus(statusId);

  const isCardStatusPending =
    statusId &&
    (!cardTokenStatusData?.status ||
      cardTokenStatusData?.status === CardTokenStatusType.PENDING ||
      isCardTokenStatusLoading);

  React.useEffect(() => {
    props.setSubmitDisabled(true);
  }, []);

  React.useEffect(() => {
    props.setSubmitDisabled(!card?.id || isLoading || isCardStatusPending);
  }, [card?.id, isLoading, cardTokenStatusData?.status, isCardTokenStatusLoading]);

  React.useEffect(() => {
    if (cardTokenStatusData?.status === CardTokenStatusType.SUCCESS) {
      refreshCard(queryClient);
    }
  }, [cardTokenStatusData?.status]);

  const onUpdate = async (formik) => {
    if (get(formik, 'values.card')) props.setSubmitDisabled(false);
  };

  const onSubmit = async ({ values, submit }) => {
    // сохраняем флаг bindNewCard в данных процесса чтобы процесс перешел на шаг привязки новой карты
    // если установлен флаг bindNewCard = не проверяем что карта выбрана (ее может еще и не быть)
    if (!card?.id && !values.bindNewCard) {
      notify.error(t('errors:ERROR__LINK_CARD'));
      return;
    }

    // сбрасываем statusid в query
    const pathname = router.pathname;
    const query = { step: props.step.name };
    await router.replace({ pathname, query }, undefined, { shallow: true });

    await submit({
      ...values,
      card,
    });
  };

  const cardInitHandler = (formik) => {
    formik.setFieldValue('bindNewCard', true);
    formik.submitForm();
  };

  const errorCode = cardTokenStatusData?.error_details?.errorCode;
  const cardBindingError =
    errorCode && keyExists(`cardBindingError:code.${errorCode}`)
      ? t(`cardBindingError:code.${errorCode}`)
      : t(`cardBindingError:noCode`);

  return (
    <ProcessStepForm
      {...props}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      render={(formik) => {
        return (
          <div className={classes.formWrapper}>
            <Input name={'bindNewCard'} initialValue={false} hidden />
            <h2 className={classes.header}>Выбранная карта</h2>
            <Message type={'neutral'}>
              Подходят только именные банковские карты. Виртуальные и неименные карты не принимаются, также не подойдут
              карты QIWI, МТС и Банка Раунд. Карта должна действовать в течение следующих двух месяцев. <br />
              Для привязки карты, на счету карты должно быть хотя бы 11 рублей, которые мы спишем и тут же вернем.
            </Message>
            {cardTokenStatusData?.status === CardTokenStatusType.ERROR && (
              <Message type={'error'}>{cardBindingError}</Message>
            )}
            <br />
            {(isLoading || isFetching || isCardTokenStatusLoading) && (
              <div className={classes.cardsWrapper}>
                <CardLoading />
              </div>
            )}
            {!isLoading && !isFetching && !isCardTokenStatusLoading && (
              <div className={classes.cardsWrapper}>
                <Input hidden name={'card'} value={card?.id} />
                {!isCardStatusPending && !!card?.id && <CardActive {...card} className={'selected'} />}
                {isCardStatusPending && <CardPending />}
                <CardInit isNew={!card?.id} cardInitHandler={() => cardInitHandler(formik)} />
              </div>
            )}
            {formik.values?.isNewClient !== true && (
              <div className={classes.warning}>
                Проверьте актуальность Вашей банковской&nbsp;карты! <br /> При необходимости, прикрепите&nbsp;новую!
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
