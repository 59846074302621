import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { ProcessStorageService } from '../../../processes/ProcessStorage';
import { ProcessType } from '@./shared';

export const RegistrationFormComplete: React.FC = () => {
  const classes = useThemeStyles('RegistrationPage');
  const router = useRouter();

  useEffect(() => {
    const redirect = async () => {
      await ProcessStorageService.setItem(ProcessType.LOAN_APP, 'isNewClient', true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await router.push({ pathname: '/loan-app/create' });
    };
    redirect();
  }, []);

  return (
    <div className={classes.success}>
      <h2 className='mb2'>Вы успешно зарегистрированы!</h2>
      <p>Подождите немного, обрабатываем ваши данные.</p>
      <div>
        <LoadingSpinner size={8} margin={4} />
      </div>
    </div>
  );
};
