import * as React from 'react';
import { IProcessStepProps } from '@brainysoft/lk-components';
import { Button, Col, Divider, Row } from 'antd';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Select } from '../../../packages/Formik/Components/Select';
import { Icon } from '../../../components/Icon';
import { FormikProps } from 'formik';
import { DictionaryType } from '@brainysoft/lk-components';
import { MatrialStatusTypeToOptions } from '@brainysoft/lk-components';
import { get } from 'lodash';
import { Input } from '../../../packages/Formik/Components/Input';
import { ProfileFormAdditionalStatic } from '../../default/profile/ProfileFormAdditionalStatic';
import { DatePicker } from '../../../packages/Formik/Components/DatePicker';
import { useDictionary } from '../../../queries/useDictionary';
import { useUnemployed } from '../../../utils/employmentUtils';
import dayjs from 'dayjs';

interface IProps extends IProcessStepProps {
  formik: FormikProps<any>;
  onUpdateRef?: any;
  onSubmitRef?: any;
}

export const ProfileFormAdditional: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ClientDataUpdatePage');

  const { initialValues, onUpdateRef } = props;

  const isUnemployed = useUnemployed();

  const [isEditable, setIsEditable] = React.useState(true);
  const [hidden, setHidden] = React.useState(true);

  const maritalStatus = MatrialStatusTypeToOptions();

  const educationQuery = useDictionary(DictionaryType.EDUCATION);
  const employmentTypeQuery = useDictionary(DictionaryType.EMPLOYMENT_TYPE);

  const education = educationQuery.data ?? [];
  const employmentType = employmentTypeQuery.data ?? [];

  React.useEffect(() => {
    const employmentTypeId = get(initialValues, 'workPlaceData.employmentTypeId');
    const unemployed = isUnemployed(employmentTypeId);
    setHidden(unemployed);
  }, []);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  }

  onUpdateRef.current = async (formik) => {
    const employmentTypeId = get(formik.values, 'workPlaceData.employmentTypeId');
    const unemployed = isUnemployed(employmentTypeId);
    setHidden(unemployed);
    if (unemployed) formik.setFieldValue('workPlaceData.workPlace', undefined);
  };

  if (initialValues.loanPaymentExpense === undefined || initialValues.loanPaymentExpense === null)
    initialValues.loanPaymentExpense = 0;

  return (
    <React.Fragment>
      {!isEditable && <ProfileFormAdditionalStatic client={initialValues} />}

      <div style={{ display: isEditable ? 'block' : 'none' }}>
        <Input name='businessAddressDataFiasLvl' hidden={true} />
        <Input name='businessAddressData.fiasId' hidden={true} />

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <div className='field'>
              <Select
                name='educationId'
                label='Образование'
                placeholder='Ваше образование'
                options={education.map((item) => {
                  return {
                    value: item.id,
                    option: item.name,
                  };
                })}
                required={true}
              />
            </div>
          </Col>
          <Select
            name='workPlaceData.employmentTypeId'
            label='Тип занятости'
            placeholder='Тип Вашей занятости'
            options={employmentType.map((item) => {
              return {
                value: item.id,
                option: item.name,
              };
            })}
            required={true}
            wrapInColumn={true}
            columnProps={{ xs: 24, sm: 12 }}
          />
          <Input
            name='workPlaceData.workPlace'
            label='Название места работы'
            placeholder='Введите название места работы'
            hidden={hidden}
            wrapInColumn={true}
            columnProps={{ xs: 24, sm: 12 }}
            required={!hidden}
          />
          <Col xs={24} sm={12}>
            <div className='field'>
              <Select
                name='maritalStatusId'
                label='Семейное положение'
                placeholder='Ваше семейное положение'
                options={maritalStatus.map((item) => {
                  return {
                    value: item.id,
                    option: item.name,
                  };
                })}
                required={true}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='field'>
              <MaskedInput
                name='dependents'
                mask='children'
                label='Количество детей на содержании'
                placeholder='Введите количество детей на содержании'
              />
            </div>
          </Col>
        </Row>

        <Divider />

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <div className='field'>
              <MaskedInput
                name='meanIncome'
                mask={hidden ? 'incomeRequired' : 'income'}
                label='Доход на руки'
                placeholder='Введите доход на руки'
                required={!hidden}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='field'>
              <DatePicker
                disabledDate={disabledDate}
                name='workPlaceData.nextPayDay'
                label='Дата следующей зарплаты'
                placeholder='Дата следующей зарплаты в формате ДД.ММ.ГГГГ'
                outgoingFormat='YYYY-MM-DD'
                required={hidden}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='field'>
              <MaskedInput
                name='loanPaymentExpense'
                mask='income5chars'
                label='Ежемесячные оплаты по кредитам и займам'
                placeholder='Ежемесячные оплаты по Вашим кредитам и займам'
                required={true}
              />
            </div>
          </Col>
        </Row>
      </div>

      {!isEditable && (
        <Button
          type={'link'}
          onClick={() => {
            setIsEditable(true);
          }}
          className={classes.changeButton}
        >
          <Icon type={'edit'} /> Изменить
        </Button>
      )}
    </React.Fragment>
  );
};
