import { useState } from 'react';
import { CreditT } from '@brainysoft/lk-components';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { leadService } from '@brainysoft/lk-components';

type SpecialOffersT = {
  offers: any[];
};

export const useIndividualOffers: (leadId, loanApplicationId, isRequired?: boolean) => UseQueryResult<any> = (
  leadId,
  loanApplicationId,
  isRequired = false
) => {
  const [_refetchInterval, setRefetchInterval] = useState<number | false>(isRequired ? 10000 : false);

  return useQuery(
    ['individualOffers'],
    () => {
      return leadService.getIndividualOffers(leadId, loanApplicationId) as Promise<SpecialOffersT>;
    },
    {
      refetchInterval: _refetchInterval,
      onSuccess(data) {
        if (isRequired && data?.offers?.length) setRefetchInterval(false);
      },
    }
  );
};
