import * as React from 'react';

export const useWindowFocusListeners = (onfocus?: (e: FocusEvent) => void, onblur?: (e: FocusEvent) => void): void => {
  const onfocusRef = React.useRef(onfocus);
  const onblurRef = React.useRef(onblur);

  React.useEffect(() => {
    onfocusRef.current = onfocus;
    onblurRef.current = onblur;
  }, [onfocus, onblur]);

  React.useEffect(() => {
    function focusListener(e) {
      if (typeof onfocusRef.current === 'function') onfocusRef.current(e);
    }
    function blurListener(e) {
      if (typeof onblurRef.current === 'function') onblurRef.current(e);
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('focus', focusListener);
      window.addEventListener('blur', blurListener);
    }
    return () => {
      window.removeEventListener('focus', focusListener);
      window.removeEventListener('blur', blurListener);
    };
  }, []);
};
