export const addressFields = [
  'fiasId',
  'postalCode',
  'localityName',
  'regionName',
  'streetName',
  'houseNo',
  'blockNo',
  'buildingNo',
  'apartmentNo',
];

export const parseSuggestion = (suggestion) => {
  const houseExtra = blockParser(suggestion);

  return {
    fiasId: suggestion?.fias_id,
    postalCode: suggestion?.postal_code,
    localityName: suggestion?.city_with_type ? suggestion?.city_with_type : suggestion?.settlement_with_type,
    regionName: suggestion?.region_with_type + ' ' + suggestion?.area_with_type ? suggestion?.area_with_type : '',
    streetName: suggestion?.street_with_type,
    houseNo: suggestion?.house,
    blockNo: houseExtra.block,
    buildingNo: houseExtra.building,
    apartmentNo: suggestion?.flat,
  };
};

export const setParsedValues = (formik, prefix, suggestion) => {
  const values = parseSuggestion(suggestion);

  addressFields.forEach((field) => {
    formik.setFieldValue(`${prefix}.${field}`, values[field]);
  });

  formik.setFieldValue(`${prefix}FiasLvl`, suggestion?.fias_level);
};

const blockParser = (suggestionData) => {
  let block = '',
    building = '';

  if (suggestionData?.block_type == 'к' || suggestionData?.block_type == 'к' + '.') {
    // корпус
    block = suggestionData.block;
    const regex = new RegExp(`(\\d+)\\s(стр[\\.]*)\\s*(\\d+)*`);
    const match = block.match(regex);
    // если есть еще и строение - парсим
    if (match && Array.isArray(match)) {
      block = match[1] ? match[1] : '';
      building = match[3] ? match[3] : '';
    }
  } else {
    // строение
    building = suggestionData?.block;
  }

  return {
    block: block,
    building: building,
  };
};
