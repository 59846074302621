import { UploadPassportIntro } from '../../default/uploadPassport/UploadPassportIntro';
import { UploadPassportProcess } from './UploadPassportProcess';
import { UploadPassportResult } from '../../default/uploadPassport/UploadPassportResult';

const RENDERER_MAP = {
  intro: UploadPassportIntro,
  uploadPassport: UploadPassportProcess,
  end: UploadPassportResult,
};

export default { RENDERER_MAP };
