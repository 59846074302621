import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Col, Divider, Row } from 'antd';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { Dragger } from '../../../packages/Formik/Components/Dragger';
import { MAX_DOWNLOAD_FILE_SIZE } from '../../../config';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import { FileSettingsT } from '../../monedo/registration/RegisterFormPhotoUploader';
import { useUploadInteractionFiles } from '../../../utils/useUploadInteractionFiles';
import { get } from 'lodash';
import { StatefulProcessActions } from '../../../components/StatefulProcess/StatefulProcessActions';
import { ReactNode } from 'react';
import { Message } from '../../../components/Message';
import cls from 'classnames';

interface InteractionFilesUploaderProps extends IStatefulProcessRenderer {
  filesSettings: FileSettingsT[];
  title: string;
  descriptor?: string | ReactNode;
}

export const InteractionFilesUploader: React.FC<InteractionFilesUploaderProps> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');

  const form = React.useRef();
  const { credit, state, goForward, goBack, isMutating, filesSettings, title, descriptor } = props;

  const [disabled, setDisabled] = React.useState(true);

  const { uploadFormFiles, isUploading } = useUploadInteractionFiles({
    filesSettings: filesSettings,
    creditUuid: credit.uuid,
    processType: state.type,
  });

  const onUpdate = (formik) => {
    const allFilesAttached = filesSettings.reduce((sum, item) => {
      const file = get(formik.values, item.name);
      return sum && !!file;
    }, true);

    setDisabled(!allFilesAttached);
  };

  const onSubmit = async (values) => {
    await uploadFormFiles(values, {
      onSuccess: async (uploadedFiles) => {
        // for (const item of filesSettings) {
        //   delete values[`__file_${item.name}`];
        //   delete values[item.name];
        // }
        await goForward({
          ...state.data,
          ...uploadedFiles,
        });
      },
    });
  };

  const handleSubmit = () => {
    (form.current as any).submitForm();
  };

  const descriptorRender = React.isValidElement(descriptor) ? descriptor : <p>{descriptor}</p>;

  return (
    <React.Fragment>
      <div className={cls(classes.wrapper, 'uploadPhoto')}>
        <h2 className={classes.header}>{title}</h2>
        <Formik innerRef={form as any} initialValues={{ photo: undefined }} onSubmit={onSubmit}>
          {(formik: FormikProps<any>) => {
            return (
              <FormikForm formik={formik} onUpdate={onUpdate} onSubmit={formik.handleSubmit}>
                <Row gutter={24} className={classes.registrationFormPassport}>
                  <Col xs={{ span: 24 }} className={classes.photoInfo}>
                    {descriptorRender}
                  </Col>

                  <Col xs={{ span: 24 }}>
                    <Row gutter={24}>
                      {filesSettings.map((item, index) => {
                        return (
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} key={index} className={classes.uploaderColumn}>
                            <Dragger
                              name={item?.name}
                              label={item?.label}
                              placeholder={item?.placeholder}
                              placeholderImgUrl={item?.placeholderImgUrl}
                              required={item?.required}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>

                  <Col xs={{ span: 24 }}>
                    <Message type={'info'}>
                      Если фото не загружается, проверьте, что оно размером{' '}
                      <span style={{ whiteSpace: 'nowrap' }}>менее {MAX_DOWNLOAD_FILE_SIZE} МВ</span> и в формате .jpg
                      или .png
                    </Message>
                  </Col>
                </Row>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
      <Divider />
      <StatefulProcessActions
        showForward={state.transitions?.forward}
        showBack={state.transitions?.back}
        onClickForward={handleSubmit}
        onClickBack={goBack}
        textForward={'Загрузить фото'}
        isForwardDisabled={disabled || isUploading}
        isForwardLoading={isUploading}
        isBackDisabled={isUploading}
        isMutating={isMutating}
      />
    </React.Fragment>
  );
};
