import * as React from 'react';
import {
  cacheService as Storage,
  IProcessStepProps,
  paymentsService,
  ProcessType,
  SettingsType,
} from '@brainysoft/lk-components';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { IframeContainer } from '../../../components/IframeContainer/IframeContainer';
import { useGoogleAnalytics } from '../../../integrations/useGoogleAnalytics';
import { useYandexMetrika } from '../../../integrations/useYandexMetrika';
import { useRouter } from 'next/router';
import { useSetting } from '../../../utils/useSetting';

export const LoanAppBindNewCard: React.FC<IProcessStepProps> = (props) => {
  const router = useRouter();
  const classes = useThemeStyles('LoanAppCreatePage');

  const statusId = router.query?.statusid;

  const wrapInIframe = useSetting(SettingsType.WRAP_CARD_BINDING_IN_IFRAME);

  const [isLoading, setIsLoading] = React.useState(false);
  const [cardInitUrl, setCardInitUrl] = React.useState<string | undefined>();
  const [isReturnFromPaymentSystem, setIsReturnFromPaymentSystem] = React.useState(false);

  const resultUrl = `${window.location.origin}/loan-app/bindNewCard`;
  const callbackUrl = wrapInIframe ? `${window.location.origin}/iframe/redirect-target` : resultUrl;

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const getCardInitUrl = async () => {
    setIsLoading(true);
    const uuid = await Storage.getItem(`process:${ProcessType.LOAN_APP}:uuid`);
    const session = await Storage.getItem(`process:${ProcessType.LOAN_APP}:session`);
    if (uuid && session && uuid !== 'undefined' && session !== ' undefined') {
      const res = await paymentsService.initCard({
        backLink: callbackUrl,
      });
      if (res?.url) {
        ga.send('attach_card');
        ym.send('attach_card');
        setCardInitUrl(res?.url);
      }
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!wrapInIframe && cardInitUrl && !isLoading && !statusId) {
      window.location.href = cardInitUrl;
    }
  }, [wrapInIframe, cardInitUrl, isLoading, statusId]);

  const goNextStep = async () => {
    // const pathname = router.pathname;
    // const query = { step: props.step.name }
    // if(statusId) query['statusid'] = statusId;
    // await router.replace({ pathname, query }, undefined, { shallow: true });

    // запрос на совершение платежа отправлен, завершаем процесс
    props.goBack();
  };

  React.useEffect(() => {
    if (statusId) {
      setIsReturnFromPaymentSystem(true);
      goNextStep();
    } else {
      if (!isReturnFromPaymentSystem) getCardInitUrl();
    }
  }, [statusId]);

  return (
    <>
      <div className={classes.iframeWrapper}>
        {statusId && <LoadingSpinner size={8} margin={4} />}
        {!statusId && isLoading && <LoadingSpinner size={8} margin={4} />}
        {!statusId && !isLoading && !wrapInIframe && <LoadingSpinner size={8} margin={4} />}
        {!statusId && !isLoading && wrapInIframe && cardInitUrl && (
          <IframeContainer iframeSrc={cardInitUrl} resultPageUrl={resultUrl} />
        )}
      </div>
    </>
  );
};
