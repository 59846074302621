import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import { get, set, cloneDeep, isEmpty } from 'lodash';
import { ProcessStepModel } from '@brainysoft/lk-components';
import { FormikForm } from '../../packages/Formik/Components/FormikForm';
import { FormikHelpers } from 'formik/dist/types';
import { ScrollToFieldError } from '../../packages/Formik/Components/ScrollToError';
import { ProcessSurveys } from '../Surveys/ProcessSurveys';
import { hasValue } from '../../utils/formUtils';

interface IProps {
  processName: string;
  processUuid: string;
  processSession: string;
  step: ProcessStepModel;
  initialValues?: any;
  submitStep(data): any;
  setSubmitDisabled(value): any;
  createFormHandler(formik, stepId): any;
  setIsFormLoading(value): any;
  onUpdate?(formik, changed?): void;
  onSubmit?({ values, actions, submit }): void;
  render: any;
  validate?: any;
  additionalFields?: any[];
}

export const ProcessStepForm: React.FC<IProps> = (props) => {
  // const [initialValues, setInitialValues] = React.useState({});
  const constraints = get(props, 'step.fields', []);
  const additionalFields = get(props, 'additionalFields', []);
  const validationConstraints = Object.keys(constraints).filter((item) => {
    return constraints[item].indexOf('required') !== -1;
  });

  const fieldConstraints = Object.keys(constraints);

  const valueConstraints = Object.keys(constraints).filter((item) => {
    return constraints[item].reduce((sum, item) => sum || item.indexOf('boolean:') == 0, false);
  });

  // Для того чтобы работала валидация формика, требуется инициализаровать значение поля как undefined,
  // если начального значения нет. Берем поля указанные в данных процесса
  // и прописываем либо значение, либо undefined
  let initialValues = {};
  if (!Array.isArray(props.initialValues)) {
    initialValues = cloneDeep(props.initialValues);
  }

  fieldConstraints.forEach((fieldName) => {
    if (!hasValue(initialValues, fieldName)) {
      //инициалиэация как минимум одного элемента коллекции, если значения нет
      const _fieldName = fieldName.replace('._.', '.0.');
      set(initialValues, _fieldName, undefined);
    }
  });

  additionalFields.forEach((item) => {
    initialValues[item] = undefined;
  });

  const setSubmitDisabled = async (formik) => {
    if (valueConstraints.length) {
      const constraintsMatch = valueConstraints
        .map((item) => {
          //todo make real values constraints
          return formik.values[item] == true;
        })
        .reduce((sum, val) => sum && val, true);
      if (!constraintsMatch) await props.setSubmitDisabled(true);
    }
  };

  const onUpdate = async (formik, changed) => {
    await setSubmitDisabled(formik);
    if (props.onUpdate && typeof props.onUpdate == 'function') props.onUpdate(formik, changed);
  };

  const onSubmit = async (values, actions: FormikHelpers<any>) => {
    props.setIsFormLoading(true);
    if (props.onSubmit && typeof props.onSubmit == 'function') {
      await props.onSubmit({
        values: values,
        actions: actions,
        submit: (formValues) => props.submitStep(cleanValues(formValues, additionalFields)),
      });
    } else {
      await props.submitStep(cleanValues(values, additionalFields));
    }
    props.setIsFormLoading(false);
  };

  const slotName = `${props.processName}.${props.step?.name}`;

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit} validate={props.validate}>
      {(formik: FormikProps<any>) => {
        return (
          <React.Fragment>
            <ScrollToFieldError />
            <FormRenderer
              formik={formik}
              name={props.step.name}
              initialValues={initialValues}
              createFormHandler={props.createFormHandler}
              setSubmitDisabled={setSubmitDisabled}
              onUpdate={onUpdate}
              render={props.render}
            />
            <ProcessSurveys slot={slotName} />
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

const FormRenderer: React.FC<any> = (props) => {
  const { formik, name, initialValues, createFormHandler, setSubmitDisabled, onUpdate, render } = props;

  React.useEffect(() => {
    createFormHandler(formik, name);
  }, []);

  React.useEffect(() => {
    setSubmitDisabled(formik);
  }, [initialValues]);

  return (
    <FormikForm formik={formik} onSubmit={formik.handleSubmit} onUpdate={onUpdate}>
      {render(formik)}
    </FormikForm>
  );
};

const cleanValues = (values, additionalFields) => {
  const newValues = { ...values };

  const toFilter = Object.keys(newValues).reduce((prev: any[], next) => {
    if (next.includes('_suggestion')) {
      prev.push(next);
    }
    if (next.includes('__file')) {
      prev.push(next);
    }
    return prev;
  }, []);

  toFilter.concat(additionalFields).forEach((key) => {
    delete newValues[key];
  });

  return cleanUndefinedValues(newValues);
};

const cleanUndefinedValues = (obj) => {
  const _obj = cloneDeep(obj);

  Object.keys(_obj).forEach((key) => {
    if (_obj[key]) {
      if (typeof _obj[key] === 'object') {
        if (Array.isArray(_obj[key])) {
          //array
          _obj[key] = _obj[key]
            .map((item) => {
              const value = cleanUndefinedValues(item);
              return value && !isEmpty(value) ? value : undefined;
            })
            .filter((item) => item !== undefined);
          if (_obj[key].length === 0) delete _obj[key];
        } else {
          //object
          const value = cleanUndefinedValues(_obj[key]);
          if (isEmpty(value)) delete _obj[key];
        }
      }
    } else {
      if (_obj[key] === undefined) delete _obj[key];
    }
  });

  return _obj;
};

ProcessStepForm.whyDidYouRender = true;
