import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { IProcessStepProps, cacheService as Storage } from '@brainysoft/lk-components';
import { PhotoType, ExternalAuthProviders, processService, ProcessType } from '@brainysoft/lk-components';
import { RegisterFormPhotoUploader } from './RegisterFormPhotoUploader';
import { ExternalAuthGosuslugi } from '../../../components/ExternalAuth/ExternalAuthGosuslugi';
import { Message } from '../../../components/Message';
import { useCheckExternalAuthProvider } from '../../../utils/useExternalAuthProvider';
import { get } from 'lodash';
import { usePlocessFiles } from '../../../queries/useProcessFiles';

//при ошибке в submitStep компонент может уйти в рекурсию
//по этому использую глобальную синглтон переменную
let nextError = false;

export const RegisterFormPhotoPassport: React.FC<IProcessStepProps> = (props) => {
  const esia = useCheckExternalAuthProvider(ExternalAuthProviders.ESIA);
  const formik = React.useRef();

  const fileSettings = [
    {
      name: 'passportPersonalData',
      label: 'Фото разворота с фамилией, именем и отчеством',
      placeholder: 'Загрузите разворот с фамилией, именем и отчеством',
      code: PhotoType.RUS_PASSPORT_PERSONAL_DATA,
      placeholderImgUrl:
        'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/passport_placeholder_1.png',
      required: props?.initialValues?.isMatchesWithExtrenalData !== true,
    },
    {
      name: 'passportPages_4_5',
      label: 'Фото разворота страниц 4-5',
      placeholder: 'Загрузите фото разворота страниц 4-5',
      code: PhotoType.RUS_PASSPORT_REGISTRATION,
      placeholderImgUrl:
        'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/passport_placeholder_2.png',
      required: props?.initialValues?.isMatchesWithExtrenalData !== true,
    },
  ];

  const codes = fileSettings.reduce((acc: string[], e) => {
    acc.push(e.code as string);
    return acc;
  }, []);

  const { data } = usePlocessFiles(codes, props.processName, props.processUuid, props.processSession);

  if (props?.initialValues?.isMatchesWithExtrenalData == true && nextError == false) {
    nextError = true;
    props.submitStep(props.initialValues);
  }

  const returnUrl = window.location.origin + '/loan-app/external-auth-result';

  const preprocess = () => {
    return async () => {
      for (const item of fileSettings) {
        const fileValue = get((formik?.current as any)?.values, `__file_${item.name}`);
        if (fileValue) {
          const file = new FormData();
          file.append('file', fileValue?.originFileObj);
          file.append('code', item.code);
          await processService.saveProcessFile(props.processName, props.processUuid, props.processSession, file);
          //await Storage.setItem(`file:${ProcessType.LOAN_APP}:${item.name}:saved`, 1);
        }
      }
    };
  };

  const setFormik = (f) => {
    formik.current = f;
  };

  return (
    <>
      {!!esia && (
        <>
          <ExternalAuthGosuslugi
            returnUrl={returnUrl}
            preprocess={preprocess()}
            className={'mb1'}
            isShowLabel={false}
          />
          <Message type={'info'}>
            Залогиньтесь в Госуслуги и рассмотрение заявки займет пару минут. Без авторизации в Госуслугах рассмотрение
            заявки может занять более 30 минут.
          </Message>
        </>
      )}
      <RegisterFormPhotoUploader
        filesSettings={fileSettings}
        title={'Загрузите фото вашего паспорта'}
        files={data}
        descriptor={
          <React.Fragment>
            <p>
              Чтобы убедиться, что вы - это вы, нам нужны фото двух разворотов страниц вашего паспорта, как на примерах
              ниже.
            </p>

            <p>ОБЯЗАТЕЛЬНЫЕ ТРЕБОВАНИЯ К ФОТО ПАСПОРТА:</p>
            <ul className={'mb1'}>
              <li>страницы должны помещаться на фото целиком, без обрезов</li>
              <li>фото должны быть цветными</li>
              <li>вся информация должна читаться, на тексте не должно быть бликов</li>
              <li>не закрывайте текст пальцами или иными предметами</li>
            </ul>
          </React.Fragment>
        }
        setFormik={setFormik}
        {...props}
      />
      {!!esia && (
        <>
          <ExternalAuthGosuslugi
            returnUrl={returnUrl}
            preprocess={preprocess()}
            className={'mb1'}
            isShowLabel={false}
          />
          <Message type={'info'}>
            Залогиньтесь в Госуслуги и рассмотрение заявки займет пару минут. Без авторизации в Госуслугах рассмотрение
            заявки может занять более 30 минут.
          </Message>
        </>
      )}
    </>
  );
};
