import * as React from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { useFormikContext } from 'formik';
import { Col, Row } from 'antd';
import { Button } from '../../../../components/Button';
import { getInputMask } from '../MaskedInput/masks';
import { messagesService, VerificationResponseType } from '@brainysoft/lk-components';
import { Input } from '../Input';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useTranslation } from '../../../../utils/useTranslation';
import { useEmailVerificationChallenge } from '../../../../utils/useEmailverificationInit';
import { ReactNode, SyntheticEvent } from 'react';
import { useWindowFocusListeners } from '../../../../utils/useWindowFocusListeners';
import { resetCheckEmail, useCheckEmail } from '../../../../queries/useCheckEmail';
import { useQueryClient } from 'react-query';
import { ValidateStatus } from 'antd/lib/form/FormItem';

const CHECK_INTERVAL = 12000;

interface IEmailApproval {
  uuidFieldName?: string;
  approveFieldName?: string;
  description?: string;
  challengeButtonSize?: SizeType;
  onApprove?: (result) => Promise<void>;
  emailFieldName?: string;
  processUuid?: string;
  children: (status: ValidateStatus, isChecking: boolean) => ReactNode;
}

// подтверждение email в формах регистрации и подачи заявки
// uuid запроса валидации сохраняется в поле uuidFieldName
// при успешной валидации подтверждение сохраняется в поле approveFieldName
// проверка состояния при возвращении со страницы подтверждения - по событию window.focus
// при регистрации пока нет clientId, проверяем статус верификации по processUuid

export const _EmailApproval: React.FC<IEmailApproval> = (props) => {
  const { t } = useTranslation();
  const classes = useThemeStyles('Form/PhoneInputApprove');
  const {
    uuidFieldName = 'emailApprovalUuid',
    approveFieldName = 'emailApproval',
    emailFieldName = 'email',
    challengeButtonSize,
    processUuid,
    children,
  } = props;

  const [checkEnabled, setCheckEnabled] = React.useState(false);
  const [checkInterval, setCheckInterval] = React.useState<number | false>(false);
  const [emailProofStatus, setEmailProofStatus] = React.useState<boolean | undefined>();

  const [validEmail, setValidEmail] = React.useState<string | undefined>();
  const [showRequestButton, setShowRequestButton] = React.useState(false);
  const [isSendingRequests, setIsSendingRequests] = React.useState(false);

  const formik = useFormikContext();
  const queryClient = useQueryClient();

  // по шаблону формика функция валидации возвращает false при валидном значении (или код ошибки при невалидном)
  const { maskValidator } = getInputMask('email');
  const emailValidatorFn: (email: string) => boolean = (email) => {
    return !email || !!maskValidator(email);
  };

  const email = formik.values[emailFieldName];
  const emailApprovalUuid = formik.values[uuidFieldName];

  // в процессе регистрации clientId === undefined
  const clientId = formik.initialValues['clientId'];

  const entity = clientId ? 'client' : 'process';
  const entityId = clientId || processUuid;

  const ifExists = async (email, entity, entityId) => {
    return await messagesService.emailVerificationCheckExists(email, entity, entityId);
  };

  const { getChallenge, isDisabled, isSending, timeout, uuid } = useEmailVerificationChallenge(entity, entityId);

  // если подтвержден email - прописываем uuid в formik
  const onSettled = async (data: VerificationResponseType) => {
    if (data?.verified && email === validEmail) {
      setEmailProofStatus(true);
      setShowRequestButton(false);
      setCheckEnabled(false);
      setCheckInterval(false);
      await formik.setFieldValue(uuidFieldName, data.uuid);
      await formik.setFieldValue(approveFieldName, data.verified);
      return;
    }

    setShowRequestButton(true);
  };
  const checkEmailQuery = useCheckEmail(validEmail, entity, entityId, onSettled, checkEnabled, checkInterval);
  const isChecking = checkEmailQuery.isLoading;

  // обработка возврата со страницы подтверждения
  const onWindowFocus = async (e: FocusEvent) => {
    if (emailApprovalUuid) resetCheckEmail(queryClient, entity, entityId);
  };
  useWindowFocusListeners(onWindowFocus);

  // при изменении email - сброс полей и установка валидного email (который проходит по маске)
  React.useEffect(() => {
    let canceled = false;
    formik.setFieldValue(uuidFieldName, undefined);
    formik.setFieldValue(approveFieldName, undefined);
    setCheckEnabled(false);
    setEmailProofStatus(undefined);
    setShowRequestButton(true);

    const handleValidEmail = async (email) => {
      const res = await ifExists(email, entity, entityId);
      if (!canceled && res?.found) {
        setCheckInterval(CHECK_INTERVAL);
        setCheckEnabled(true);
        setShowRequestButton(false);
        resetCheckEmail(queryClient, entity, entityId);
      }
    };
    if (!emailValidatorFn(email)) {
      setValidEmail(email);
      handleValidEmail(email);
    } else {
      setValidEmail(undefined);
    }
    return () => {
      canceled = true;
    };
  }, [email]);

  // сохранение текущего uuid
  React.useEffect(() => {
    if (uuid) {
      formik.setFieldValue(uuidFieldName, uuid);
    }
  }, [uuid]);

  const getEmailCheckChallenge = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsSendingRequests(true);
    await getChallenge(validEmail);
    setCheckInterval(CHECK_INTERVAL);
    setCheckEnabled(true);
    setIsSendingRequests(false);
  };

  // const timeLeft = parseTimeout(timeout);
  // const showTimer = !!(timeLeft.minutes || timeLeft.seconds);
  //
  // const timeOptions = {
  //   min: timeLeft.minutes
  //     ? timeLeft.minutes < 10
  //       ? `0${timeLeft.minutes}`
  //       : timeLeft.minutes
  //     : '00',
  //   sec: timeLeft.seconds
  //     ? timeLeft.seconds < 10
  //       ? `0${timeLeft.seconds}`
  //       : timeLeft.seconds
  //     : '00',
  // }

  const challengeDisclaimer = t(`otp:email.description`);
  const challengeButtonText = t(`otp:email.button`);

  const status = emailProofStatus === true ? 'success' : emailProofStatus === false ? 'error' : undefined;

  return (
    <React.Fragment>
      {children(status, isChecking)}
      {validEmail && showRequestButton && (
        <React.Fragment>
          <Input name={uuidFieldName} hidden initialValue={''} />

          <Row gutter={24}>
            <Col span={24}>
              <div className={classes.approveWrapper}>
                <div className={classes.disclaimer}>{challengeDisclaimer}</div>
                {/*<div className={classes.disclaimer}>*/}
                {/*  {showTimer && (*/}
                {/*    <span>*/}
                {/*      {t('otp:disclaimers.approval', timeOptions)}*/}
                {/*    </span>*/}
                {/*  )}*/}
                {/*</div>*/}

                <Button
                  className={classes.sendButton}
                  size={challengeButtonSize ?? 'small'}
                  disabled={isSendingRequests || isDisabled || !validEmail}
                  loading={isSending}
                  onClick={getEmailCheckChallenge}
                >
                  {challengeButtonText}
                </Button>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const EmailApproval = React.memo(_EmailApproval);
