import * as React from 'react';
import { useRouter } from 'next/router';
import { LoadingSpinner } from '../LoadingSpinner';
import { Dimmer } from '../Dimmer';

interface PaySystemIframeContainerProps {
  iframeSrc: string;
  resultPageUrl: string;
  iframeOrigin?: string;
}

export const IframeContainer: React.FC<PaySystemIframeContainerProps> = (props) => {
  const router = useRouter();
  const { iframeSrc, resultPageUrl, iframeOrigin } = props;

  const [isIframeOpen, setIsIframeOpen] = React.useState(true);

  const origin = iframeOrigin || window.location.origin;

  const handleEvent = React.useCallback((e: MessageEvent) => {
    if (e.origin !== origin) return;

    if (e.data?.source === 'repayment-redirect-iframe-close') {
      setIsIframeOpen(false);
      router.replace(`${resultPageUrl}?statusid=${e.data?.statusid}`);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  return (
    <>
      {isIframeOpen && <iframe src={iframeSrc} />}
      {!isIframeOpen && (
        <Dimmer active={true}>
          <LoadingSpinner size={8} margin={4} />
        </Dimmer>
      )}
    </>
  );
};
