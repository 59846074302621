import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { LoadingSpinner } from '../LoadingSpinner';

interface CardLoadingProps {
  className?: string;
}

export const CardLoading: React.FC<CardLoadingProps> = (props) => {
  const classes = useThemeStyles('Card');

  return (
    <div className={`${classes.card} ${props.className ? props.className : ''}`}>
      <div className='loadingWrapper'>
        <LoadingSpinner size={8} margin={4} className={'grey'} />
      </div>
    </div>
  );
};
